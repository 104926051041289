/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants } from '../_constants';
import { fullNameUser } from '../Utils';

export const ProfileEmployeeDetails = () => {
  const { employeeDetails } = useOutletContext();

  const [userData, setUserData] = useState(employeeDetails);
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   console.log(name, value);
  // };
  return (
    <div style={{ padding: 16 }}>
      <section>
        <div className='profile-head'>Employee Details</div>
        <div className='profile-description'>Manage your personal details</div>
      </section>
      <div className='common-separation my-3' />
      <section className='pb-3'>
        <div className='row'>
          <div className='col'>
            <label className='mb-1 fz-13px-rem  label_input_gray'>Employee ID</label>
            <div className='input-box' style={{ backgroundColor: 'var(--bg-light-gray)' }}>
              {userData?.employee_code ?? '--'}
            </div>
          </div>
          <div className='col'>
            <div className='mb-1 fz-13px-rem  label_input_gray'>Role</div>
            <div className='input-box' style={{ backgroundColor: 'var(--bg-light-gray)' }}>
              {userData?.designation ?? '--'}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <div className='mb-1 fz-13px-rem  label_input_gray'>Organization</div>
            <div className='input-box' style={{ backgroundColor: 'var(--bg-light-gray)' }}>
              {userData?.organization ?? '--'}
            </div>
          </div>
          <div className='col'>
            <div className='mb-1 fz-13px-rem  label_input_gray'>Department</div>
            <div className='input-box' style={{ backgroundColor: 'var(--bg-light-gray)' }}>
              {userData?.department ?? '--'}
            </div>
          </div>
        </div>

        {userData?.reported_users?.length && (
          <div className='col'>
            <div className='mb-1 fz-13px-rem  label_input_gray'>Reporting Users</div>
            <div className='input-box' style={{ backgroundColor: 'var(--bg-light-gray)' }}>
              {/* {userData?.designation ?? '--'} */}
              {userData?.reported_users?.length &&
                userData?.reportingUsersDetails?.map((user, index) => {
                  const url = user?.compressed_image_url
                    ? user.compressed_image_url
                    : user.image_url;
                  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
                  const isMale = user.gender === 1;
                  const isNeutral = user.gender === 3;
                  const fullName = fullNameUser(user?.first_name, user?.last_name);

                  return (
                    <div
                      key={index}
                      className='d-flex gap-1'
                      style={{
                        border: '1px solid var(--second-text-color)',
                        padding: '1px 4px',
                        borderRadius: '4px',
                        width: 'fit-content',
                        cursor: 'default',
                        backgroundColor: 'var(--bg-primary)',
                      }}
                    >
                      <CircularAvatar
                        size={15}
                        className='fz-none zoom-in employee-img-with-low-border'
                        source={finalUrl}
                        isMale={isMale}
                        isNeutral={isNeutral}
                        // htmlFor='image_upload'
                      />
                      {fullName}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};
