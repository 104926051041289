import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const notificationService = {
  notificationAdd,
  notificationList,
  notificationGet,
  notificationDelete,
  notificationDeactivate,
  updateList,
  getNotificationCount,
  notificationClick,
};

async function notificationClick(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/notification-click', requestOptions).then(handleResponse);
}

async function notificationAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/notification_add', requestOptions).then(handleResponse);
}

async function notificationList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/notification_list', requestOptions).then(handleResponse);
}
async function getNotificationCount(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/get_notification_count', requestOptions).then(
    handleResponse,
  );
}
async function updateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/chat_update_list', requestOptions).then(handleResponse);
}

async function notificationGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_notification?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function notificationDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/notification_delete', requestOptions).then(handleResponse);
}

async function notificationDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/notification_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}
