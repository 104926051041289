// import { useQuery } from '@tanstack/react-query';
import { Search } from 'lucide-react';
import React, { useState } from 'react';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants, USER_PLACEHOLDER } from '../_constants';
import { CHAT_UPDATE_TYPE } from '../_constants/chat.constants';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { fullNameUser } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button } from './CustomButton';
// import { userService } from '../_services';

export const UserGroupSelectionPopup = ({
  userList,
  groupList,
  selectedUsers,
  handleSave = () => {},
  handleClose = () => {},
  setSearchKey,
  searchKey,
  userLoading,
  groupObserverRef,
  userObserverRef,
}) => {
  const [checkedItems, setCheckedItems] = useState(selectedUsers || []);

  const handleCheckBoxChange = (user, type) => {
    if (type === 'user') {
      setCheckedItems((prevState) => {
        const userId = parseInt(user?.id);
        if (prevState.some((item) => parseInt(item.id) === userId)) {
          return prevState.filter((item) => parseInt(item.id) !== userId);
        } else {
          return [...prevState, { ...user, update_type: CHAT_UPDATE_TYPE.PERSONAL }];
        }
      });
    }

    if (type === 'group') {
      setCheckedItems((prevState) => {
        const userProjectId = user?.project_id ? parseInt(user.project_id) : null;
        const userGroupId = user?.group_id ? parseInt(user.group_id) : null;
        const userTeamId = user?.team_id ? parseInt(user.team_id) : null;

        if (
          prevState.some(
            (item) =>
              (userProjectId && parseInt(item.project_id) === userProjectId) ||
              (userGroupId && parseInt(item.group_id) === userGroupId) ||
              (userTeamId && parseInt(item.team_id) === userTeamId),
          )
        ) {
          return prevState.filter(
            (item) =>
              !(userProjectId && parseInt(item.project_id) === userProjectId) &&
              !(userGroupId && parseInt(item.group_id) === userGroupId) &&
              !(userTeamId && parseInt(item.team_id) === userTeamId),
          );
        } else {
          return [...prevState, user];
        }
      });
    }
  };

  const { containerRef } = useOutsideClickHandler({
    onClose: () => handleClose(),
  });

  return (
    <div className='viewed-list ' style={{ minWidth: 250 }} ref={containerRef}>
      <div className='viewed-user-head border-none'>Forward to</div>

      <div className='d-flex align-items-center gap-1'>
        <Search size={15} color='grey' />
        <input
          placeholder='Search'
          className='w-100 unset-btn'
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          style={{ height: 25 }}
        />
      </div>
      <div className='common-separation mb-2' />
      {checkedItems?.length > 0 && (
        <div
          className='mb-2'
          style={{
            border: '1px solid var(--border-color)',
            padding: '3px',
            borderRadius: '4px',
            width: '100%',
          }}
        >
          {checkedItems?.map((user, index) => {
            const url = user?.compressed_image_url ? user.compressed_image_url : user.image_url;
            const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
            const isMale = user.gender === 1;
            const isNeutral = user.gender === 3;
            const fullName = fullNameUser(user?.first_name, user?.last_name);

            return (
              <div
                key={index}
                className='d-flex gap-1'
                style={{
                  border: '1px solid var(--border-color)',
                  padding: '1px 2px',
                  borderRadius: '4px',
                  width: 'fit-content',
                }}
              >
                <CircularAvatar
                  size={15}
                  className='fz-none zoom-in employee-img-with-low-border'
                  source={finalUrl}
                  isMale={isMale}
                  isNeutral={isNeutral}
                  placeholderSrc={
                    CHAT_UPDATE_TYPE.GROUP === user?.type ? USER_PLACEHOLDER.GROUP : null
                  }
                />
                {fullName}
              </div>
            );
          })}
        </div>
      )}

      <div>
        <Button.Container
          type='submit'
          handleOk={(event) => {
            handleSave(event, checkedItems);
            handleClose(event);
          }}
          className={'w-100 default-btn justify-content-center'}
        >
          {/* <Forward width={16} height={16} /> */}
          <Button.Title title={'Forward'} />
          <Button.Loading />
        </Button.Container>
      </div>

      <div className=' pt-1'>
        {userLoading && <LoadingIcon size={20} />}
        {userList?.length === 0 && (
          <div className='text-center' style={{ color: '#878787' }}>
            No Data
          </div>
        )}
        {userList?.length > 0 && (
          <div
            style={{ color: 'var(--second-text-color)', fontWeight: 500, fontSize: 12 }}
            className='my-2'
          >
            MEMBERS
          </div>
        )}
        <div className='list-section-forward'>
          {userList?.length > 0 &&
            userList?.map((user) => {
              const isMale = user?.gender === 1;
              const isNeutral = user?.gender === 3;
              const url = user?.compressed_image_url ? user?.compressed_image_url : user?.image_url;
              const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
              const fullName = fullNameUser(user?.first_name, user?.last_name);
              return (
                <div
                  className='d-flex align-items-center mb-2  pointer'
                  key={user?.id}
                  onClick={() => handleCheckBoxChange(user, 'user')}
                >
                  <input
                    className='project_menu_check_box '
                    type='checkbox'
                    checked={checkedItems?.some((item) => parseInt(item.id) === parseInt(user?.id))}
                    // onChange={() => handleCheckBoxChange(user)}
                  />
                  <span className='flex-grow-1'>
                    <div className='d-flex align-items-center'>
                      <CircularAvatar
                        size={25}
                        className='fz-none zoom-in employee-img-with-low-border'
                        source={finalUrl}
                        isMale={isMale}
                        isNeutral={isNeutral}
                      />
                      <span className='name-overflow'>{fullName}</span>
                    </div>
                  </span>
                </div>
              );
            })}
          <div ref={userObserverRef} style={{ height: 10, width: '100%' }} />
        </div>
      </div>
      <div className=' pt-1'>
        {groupList?.length > 0 && (
          <div
            style={{ color: 'var(--second-text-color)', fontWeight: 500, fontSize: 12 }}
            className='my-2'
          >
            GROUPS
          </div>
        )}
        <div className='list-section-forward'>
          {groupList?.length > 0 &&
            groupList?.map((group) => {
              const isMale = group?.gender === 1;
              const url = group?.compressed_image_url
                ? group?.compressed_image_url
                : group?.image_url;
              const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
              const fullName = fullNameUser(group?.first_name, group?.last_name);
              return (
                <div
                  className='d-flex align-items-center mb-2 pointer'
                  key={group?.id}
                  onClick={() => handleCheckBoxChange(group, 'group')}
                >
                  {/* <input
                    className='project_menu_check_box '
                    type='checkbox'
                    checked={checkedItems?.some(
                      (item) =>
                        parseInt(item.team_id) === parseInt(group?.team_id) ||
                        parseInt(item.group_id) === parseInt(group?.group_id) ||
                        parseInt(item.project_id) === parseInt(group?.project_id),
                    )}
                  /> */}
                  <input
                    className='project_menu_check_box'
                    type='checkbox'
                    checked={checkedItems?.some(
                      (item) =>
                        (group?.team_id && parseInt(item.team_id) === parseInt(group.team_id)) ||
                        (group?.group_id && parseInt(item.group_id) === parseInt(group.group_id)) ||
                        (group?.project_id &&
                          parseInt(item.project_id) === parseInt(group.project_id)),
                    )}
                    // onChange={() => handleCheckBoxChange(group, 'group')}
                  />

                  <span className='flex-grow-1'>
                    <div className='d-flex align-items-center'>
                      <CircularAvatar
                        size={25}
                        className='fz-none zoom-in employee-img-with-low-border'
                        source={finalUrl}
                        isMale={isMale}
                        placeholderSrc={USER_PLACEHOLDER.GROUP}
                      />
                      <span className='name-overflow'>{fullName}</span>
                    </div>
                  </span>
                </div>
              );
            })}
          <div ref={groupObserverRef} style={{ height: 10, width: '100%' }} />
        </div>
      </div>
    </div>
  );
};
