/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import 'cropperjs/dist/cropper.css';
import { Edit, SquareCheck } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { Modal } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ImageCrop } from '../_components/ImageCrop';
import { apiConstants } from '../_constants';
import { userService } from '../_services';
import EditableField from '../Task/TaskComponents/EditableField';
import { fullNameUser, GlobalContext } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import ChangePasswordModal from './ChangePasswordModal';

const ProfileDetails = () => {
  let User = JSON.parse(localStorage.getItem('user'));
  const { makeAlert } = useContext(GlobalContext);
  const imageRef = useRef(null);
  const [changePassword, setChangePassword] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  // const [isUploading, setIsUploading] = useState(false);

  const query = {
    select: [
      'id',
      'first_name',
      'last_name',
      'address',
      'phone_no',
      'email_id',
      'gender',
      'department_id',
      'designation_id',
      'employee_code',
      'role',
      'reported_users',
      'image_url',
      'username',
      'password',
    ],

    id: User?.id,
  };
  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => userService.userGet(query),
    select: (data) => data?.data,
    placeholderData: keepPreviousData,
  });

  const { data: userLoginData, refetch: userLoginRefetch } = useQuery({
    queryKey: ['user-login'],
    queryFn: () => userService.userLoginGet({ id: User.user_login_id }),
    select: (data) => data?.data,
  });

  const isMale = userData?.gender === 1;
  const isNeutral = userData?.gender === 3;
  const url = userData?.compressed_image_url ? userData?.compressed_image_url : userData?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const Name = fullNameUser(userData?.first_name, userData?.last_name);
  const isMainAdmin = User?.role == 1 && !User?.is_sub_admin;

  const queryClient = useQueryClient();

  //function to edit admin data
  const handleEditAdmin = async (name, value) => {
    let payload = {
      ...userData,
      id: User?.id,
      [name]: value,
    };
    if (name === 'fullName') {
      const trimmedValue = value.trim();
      const lastSpaceIndex = trimmedValue.lastIndexOf(' ');

      const first_name =
        lastSpaceIndex !== -1 ? trimmedValue.slice(0, lastSpaceIndex) : trimmedValue;
      const last_name = lastSpaceIndex !== -1 ? trimmedValue.slice(lastSpaceIndex + 1) : '';

      payload = {
        ...userData,
        id: User?.id,
        last_name,
        first_name,
      };
    }
    try {
      const res = await userService.userAdd(payload);
      if (res.error_code == 0) {
        refetch();
        makeAlert('Success');
      }
    } catch (error) {
      makeAlert(error?.message);
    }
  };

  //function to edit admin data
  const handleUsernameChange = async (name, value) => {
    const payload = {
      // ...userData,
      id: User?.user_login_id,
      [name]: value,
    };
    try {
      const res = await userService.updateLoginDetails(payload);
      if (res.error_code == 0) {
        userLoginRefetch();
        makeAlert('Success');
      }
    } catch (error) {
      makeAlert(error?.message);
    }
  };
  ///function to change image
  const changePicture = (event) => {
    const { name, files, value } = event.target;
    setFile(URL.createObjectURL(event.target.files[0]));
    setImage((prev) => ({
      ...prev,
      imageFile: files[0],
    }));
    setIsPreviewOpen(true);
  };
  //use to crop image choose
  const onCrop = () => {
    const imageElement = imageRef?.current;
    const cropper = imageElement?.cropper;
    setImage((prev) => ({
      ...prev,
      imageUrl: cropper.getCroppedCanvas().toDataURL(),
    }));
  };
  //function to save image
  const editProfileImage = async (img, compressedData) => {
    const payload = {
      ...userData,
      id: User?.id,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed,
    };
    const updatedUser = {
      ...User,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed,
    };
    try {
      const res = await userService.userAdd(payload);
      if (res.error_code == 0) {
        refetch();
        makeAlert('Success');
        setIsPreviewOpen(false);
        queryClient.invalidateQueries('whoami');
        localStorage.setItem('user', JSON.stringify(updatedUser));
        // setIsUploading(false);
      }
    } catch (error) {
      makeAlert(error?.message);
      // setIsUploading(false);
    }
  };

  //function to change url to file
  // function dataUrlToFile(dataUrl, filename) {
  //   const arr = dataUrl.split(',');
  //   if (arr.length < 2) {
  //     return undefined;
  //   }
  //   const mimeArr = arr[0].match(/:(.*?);/);
  //   if (!mimeArr || mimeArr.length < 2) {
  //     return undefined;
  //   }
  //   const mime = mimeArr[1];
  //   const buff = Buffer.from(arr[1], 'base64');
  //   return new File([buff], filename, { type: mime });
  // }
  // const onFileSelect = async (fileData) => {
  //   let cloneSelectedFiles = [];
  //   cloneSelectedFiles.push({
  //     name: fileData.name.replace(/[^\w.-]|[\s&]/g, ''),
  //     sanitizeName: sanitizeFileName(fileData.name),
  //     fileType: getFileType(fileData.name.split('.').pop()),
  //     size: fileData.size,
  //     extension: fileData.name.split('.').pop(),
  //     file: fileData,
  //   });
  //   const response = await handleFileUpload(cloneSelectedFiles);
  //   return response;
  // };
  // const handleFileUpload = async (data) => {
  //   if (!data.length) {
  //     return;
  //   }
  //   const uploadedData = [];
  //   const compressedData = { compressedImageBlobUrl: '', fileNameCompressed: '' };

  //   for (let fileObj of data) {
  //     try {
  //       if (fileObj.fileType === 'image') {
  //         let { compressedFile, compressedImageBlobUrl } = await compressImageOnly(fileObj.file);

  //         let signedDataCompressed = await miscService.createSignedUploadUrl({
  //           type: fileObj.fileType, //--video,image,audio
  //           ext: fileObj.extension, //--jpg or mp4
  //           name: `${fileObj.sanitizeName}_compressed`,
  //         });

  //         if (signedDataCompressed?.data?.signedUrl) {
  //           let signedUrlCompressed = signedDataCompressed.data.signedUrl;
  //           let fileNameCompressed = signedDataCompressed.data.filename;

  //           // Upload the compressed file
  //           await fetch(signedUrlCompressed, {
  //             method: 'PUT',
  //             headers: { 'Content-Type': 'multipart/form-data' },
  //             body: compressedFile,
  //           }).then((response) => response.text());

  //           compressedData.fileNameCompressed = fileNameCompressed;
  //           compressedData.compressedImageBlobUrl = compressedImageBlobUrl;

  //           // compressedBlobData.push(compressedImageBlobUrl);
  //         }
  //       }

  //       let signedData = await miscService.createSignedUploadUrl({
  //         type: fileObj.fileType, //--video,image,audio
  //         ext: fileObj.extension, //--jpg or mp4
  //         name: fileObj.sanitizeName,
  //       });

  //       if (signedData?.data?.signedUrl) {
  //         let signedUrl = signedData.data.signedUrl;
  //         let fileName = signedData.data.filename;

  //         await fetch(signedUrl, {
  //           method: 'PUT',
  //           headers: { 'Content-Type': 'multipart/form-data' },
  //           body: fileObj.file,
  //         }).then((response) => response.text());

  //         uploadedData.push(fileName);
  //       }
  //     } catch (error) {
  //       console.log({ error });
  //     }
  //   }
  //   return { uploadedData, compressedData };
  // };

  if (isLoading) {
    return (
      <div className='loading-wrapper'>
        <LoadingIcon color={'var(--Base-Color)'} size={50} />
      </div>
    );
  }
  return (
    <div className='mt-4'>
      {/* <LoadingIcon /> */}
      <div className=' d-flex align-items-center ms-1 gap-3'>
        <div>
          <label className='details-heading cursor-pointer' htmlFor='image_upload'>
            <CircularAvatar
              size={45}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
              htmlFor='image_upload'
            />
            Change
          </label>
          <input
            type='file'
            name='image_url'
            id='image_upload'
            hidden
            onChange={changePicture}
            accept='image/*'
          />
        </div>
        <div>
          <EditableField
            onSave={(val) => handleEditAdmin('fullName', val.name)}
            key={Name}
            name='name'
            type='input'
            placeholder='Type Name'
            isEditable={isMainAdmin}
            data={{ name: Name }}
            inputClassName='taskname-input w-100 form-control'
          >
            <div className='user-name font-15' style={{ fontSize: 20 }}>
              {Name}
            </div>
          </EditableField>
          <div className='font-14'>{userData?.designation?.name}</div>
        </div>
      </div>
      <div className='row mt-4 gap-2'>
        <div className='col-md-4'>
          <div className='bb-solid'>
            <div className='d-flex gap-1'>
              <div>
                <SquareCheck color='#44546F' size={15} />
              </div>
              <div className='details-heading my-1'>Organization</div>
            </div>
            <div className='mb-2 details-value'>{userData?.Company?.name}</div>
          </div>
        </div>
        {!isMainAdmin && (
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Department</div>
              </div>
              <div className='mb-2 details-value'>{userData?.department?.name}</div>
            </div>
          </div>
        )}
      </div>
      {!isMainAdmin && (
        <div className='row mt-4 gap-2'>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Address</div>
              </div>
              <div className='mb-2 details-value'>{userData?.address}</div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Employee ID</div>
              </div>
              <div className='mb-2 details-value'>{userData?.employee_code}</div>
            </div>
          </div>
        </div>
      )}
      {!isMainAdmin && (
        <div className='row mt-4 gap-2'>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Email ID</div>
              </div>
              <div className='mb-2 details-value'>{userData?.email_id}</div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Phone Number</div>
              </div>
              <div className='mb-2 details-value'>{userData?.phone_no}</div>
            </div>
          </div>
        </div>
      )}
      {!isMainAdmin && (
        <div className='row mt-4 gap-2'>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Gender</div>
              </div>
              <div className='mb-2 details-value'>{userData?.gender == 1 ? 'Male' : 'Female'}</div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading my-1'>Reported To</div>
              </div>
              <div className='mb-2 details-value'>
                {' '}
                {userData?.reported_users?.map((item, index) => {
                  return (
                    <>
                      <span>{item.name}</span>

                      {index != userData?.data?.reported_users.length - 1 && <span>,</span>}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mt-4 gap-2'>
        <div className='col-md-4'>
          <div className='bb-solid'>
            <div className='d-flex gap-1'>
              <div>
                <SquareCheck color='#44546F' size={15} />
              </div>
              <div className='details-heading my-1'>Username</div>
            </div>
            <EditableField
              onSave={(val) => handleUsernameChange('username', val.name)}
              key={userLoginData?.username}
              name='name'
              type='input'
              placeholder='Type username'
              isEditable={isMainAdmin}
              data={{ name: userLoginData?.username }}
              inputClassName='taskname-input w-100 form-control'
            >
              <div className='mb-2 details-value'>{userLoginData?.username}</div>
            </EditableField>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='bb-solid'>
            <div className='d-flex gap-1'>
              <div>
                <SquareCheck color='#44546F' size={15} />
              </div>
              <div className='details-heading my-1 '>Password</div>
            </div>
            <div className='d-flex justify-content-between me-2'>
              <div className='mb-2 details-value fz-14px'>* * * *</div>
              <Edit size={15} onClick={() => setChangePassword(true)} />
            </div>
          </div>
        </div>
      </div>

      {changePassword && (
        <Modal.Container handleClose={() => setChangePassword(false)}>
          <Modal.View className={'modal_view_change_password'}>
            <Modal.Head
              Heading={'Change Password'}
              handleClose={() => {
                setChangePassword(false);
              }}
            />
            <ChangePasswordModal
              handleClose={() => {
                setChangePassword(false);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {isPreviewOpen && (
        <ImageCrop
          handleClose={() => {
            setIsPreviewOpen(false);
          }}
          file={file}
          uploadCroppedImage={editProfileImage}
          setImage={setImage}
          image={image}
          setIsPreviewOpen={setIsPreviewOpen}
        />
      )}
    </div>
  );
};

export default ProfileDetails;
