import React from 'react';
import { NavLink } from 'react-router-dom';
import { RT } from '../_constants';

export const ProfileTabs = ({ setActiveTab }) => {
  const PROFILE_TABS = [
    {
      name: 'Profile',
      id: 'profile',
      path: RT.PROFILE_DETAILS,
      //   disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
    },
    {
      name: 'Employee Details',
      id: 'employee-details',
      path: RT.EMPLOYEE_DETAILS,
      //   disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
    },
    {
      name: `Security`,
      id: 'security',
      path: RT.SECURITY,
      //   disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
    },
    {
      name: `Notification`,
      id: 'notification',
      path: RT.PROFILE_NOTIFICATION,
      //   disableCondition: roleBasedPermission?.ACTION_ORG_MANAGE,
    },
  ];
  console.log(PROFILE_TABS);

  return (
    <div
      style={{ backgroundColor: 'var(--bg-primary)', padding: 16 }}
      className='d-flex gap-2 flex-column'
    >
      {PROFILE_TABS?.map((tab, index) => (
        <NavLink
          key={index}
          className={({ isActive }) =>
            `profile_tab ${isActive ? 'profile_tab_active' : 'profile_tab_inactive'}  `
          }
          to={`/${RT.PROFILE_HOME}/${tab.path}`}
          onClick={() => {
            setActiveTab({
              name: tab?.name,
            });
          }}
        >
          {tab.name}
        </NavLink>
      ))}
    </div>
  );
};
