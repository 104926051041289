/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Bell, PanelTop } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { Button } from '../_components';
import { notificationItems } from '../_constants';
import { miscService } from '../_services';
import { GlobalContext } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const ProfileNotification = () => {
  const [editActive, setEditActive] = useState(false);
  const { makeAlert } = useContext(GlobalContext);

  const [notifications, setNotifications] = React.useState(notificationItems);

  // --react query for mutation function
  const {
    data: notificationCountData,
    refetch,
    isError,
    error,
    isPending: isNotificationPending,
  } = useQuery({
    queryKey: ['notification-count-data'],
    queryFn: () => getNotificationSettings(),
  });

  const handleCheckboxChange = (index, type) => {
    setEditActive(true);
    setNotifications((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [type]: !item[type] } : item)),
    );
  };

  const handleUpdateSettings = async () => {
    const query = {
      label: 'notification-settings',
      custom_data: notifications,
      id: notificationCountData?.id || null,
    };
    await miscService.notificationSettingsAdd(query);

    setEditActive(false);
    refetch();
    makeAlert('Updated');
  };

  const getNotificationSettings = async (data) => {
    let notificationSettingsData = await miscService.getNotificationSettings({
      label: 'notification-settings',
    });

    if (notificationSettingsData?.data?.custom_data.length > 0)
      setNotifications(notificationSettingsData?.data?.custom_data);

    return notificationSettingsData.data;
  };

  // --react query for mutation function
  const {
    mutate: handleMutate,
    isPending,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleUpdateSettings,
    mutationKey: ['notification-settings-update'],
  });

  return (
    <div style={{ padding: 16 }}>
      {/* header section  */}
      <section>
        <div className='profile-head'>Notification Settings</div>
        <div className='profile-description'>
          Manage your alerts and stay informed about important updates.
        </div>
      </section>

      <section className='d-flex justify-content-between align-items-center mb-4 mt-4'>
        <div className='notify-head'>
          <Bell size={16} />
          <div style={{ fontWeight: 500, fontSize: 14 }}>Notify me about</div>
        </div>

        <div style={{ display: 'flex', gap: 30 }}>
          <div className='notify-head'>
            <PanelTop size={16} />
            <div className='font-app'>Web</div>
          </div>
          <div className='notify-head'>
            <PanelTop size={16} />
            <div className='font-app'>App</div>
          </div>
        </div>
      </section>

      <div className='common-separation my-3' />

      {isNotificationPending && <div>Loading...</div>}
      {isError && <div>{error}</div>}

      {notifications?.map((item, index) => {
        return (
          <>
            <section className='d-flex justify-content-between align-items-center'>
              <div>
                <div style={{ fontWeight: 500, fontSize: 14 }}>{item?.heading}</div>
                <div className='profile-description'>{item.description}</div>
              </div>

              <div style={{ display: 'flex', gap: 80 }}>
                <input
                  className='menu_check_box'
                  type='checkbox'
                  checked={item.web}
                  onChange={() => handleCheckboxChange(index, 'web')}
                />
                <input
                  className='menu_check_box'
                  type='checkbox'
                  checked={item.app}
                  onChange={() => handleCheckboxChange(index, 'app')}
                />
              </div>
            </section>
            <div className='common-separation my-3' />
          </>
        );
      })}

      <div className='common-separation my-3' />

      {editActive && (
        <div className=' d-flex justify-content-end gap-2 mt-4'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              setEditActive(false);
              //   setLocalData({});
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container
            type='submit'
            handleOk={handleMutate}
            //  isDisabled={isLoading}
          >
            <Tick />
            <Button.Title title={'Update'} />
            <Button.Loading isLoading={isPending} />
          </Button.Container>
        </div>
      )}
    </div>
  );
};
