import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CircleCheck, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { Button, Modal } from '../_components';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import ToolTip from '../_components/Tooltip/ToolTip';
import { CommonDropDownStyle, MENU } from '../_constants';
import { MODAL_NOTES } from '../_constants/deletenote.constants';
import { clientService } from '../_services';
import { invoiceService } from '../_services/invoice.service';
import { ClientAddModal } from '../Client/ClientAddModal';
import { SelectedFiles } from '../FileSystem/SelectedFiles';
import { currencyType } from '../Settings/InvoiceSettings';
import { INVOICE_IDS } from '../Settings/SettingsLabels';
import ErrorBlock from '../Template/SubComponents/ErrorBlock';
import {
  FileSystemImage,
  getFileType,
  GlobalContext,
  InvoiceItemState,
  InvoiceState,
  sanitizeFileName,
} from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { AddItemModal } from './AddItemModal';
import { AddTaxModal } from './AddTaxModal';
import './invoice.css';
import { InvoiceAddItemTable } from './InvoiceAddItemTable';
import { TAX_CONST, TRANSACTION_TYPE } from './InvoiceConstant';
import { calculateInvoiceAmount, calculateTaxAndAmount } from './InvoiceHelperFunction';

export const AddInvoice = ({
  handleClose = () => {},
  afterAdd = () => {},
  transactionType = TRANSACTION_TYPE.INVOICE,
  invoiceDetails = null,
  id = null,
}) => {
  const {
    getMenuLabelName,
    globalSettings,
    makeAlert,
    globalSettingsRefetch,
    roleBasedPermission,
  } = useContext(GlobalContext);
  const inputRefs = useRef([]);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const hasExist = (labelID) => {
    const setting = invoiceSettings?.labels?.requiredFields?.find((label) => label.id == labelID);
    return setting ? setting.value : undefined;
  };
  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  // useState section
  const [itemAddModal, setItemAddModal] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [invoiceItemData, setInvoiceItemData] = useState(
    invoiceDetails?.itemDetailsList
      ? invoiceDetails?.itemDetailsList
      : invoiceSettings?.labels?.defaultTax !== (TAX_CONST.NOT_SELECTED || TAX_CONST.TAX_EXEMPT)
      ? [{ ...InvoiceItemState, default_tax: invoiceSettings?.labels?.taxGroup }]
      : [{ ...InvoiceItemState }],
  );

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentCurrencyType, setCurrentCurrencyType] = useState(currencyTextIcon);

  const invoiceNumberOfDigits = parseInt(invoiceSettings?.labels?.numberOfDigits) || 1; // Default to 0 if invoiceNumberOfDigits is not available
  const paddedInvoiceNumber = String(invoiceSettings?.labels?.invoiceNumber).padStart(
    invoiceNumberOfDigits,
    '0',
  );
  const queryClient = useQueryClient();

  const [invoiceData, setInvoiceData] = useState(
    invoiceDetails
      ? {
          ...invoiceDetails?.updateInvoiceData,
          tax_info: null,
          transaction_type: transactionType,
          tax: invoiceSettings?.labels?.defaultTax,
          // tax_type: invoiceSettings?.labels?.taxType,
          terms_and_conditions: invoiceSettings?.labels?.termsCondition,
          invoice_number:
            invoiceSettings?.labels?.invoicePrefix?.toUpperCase() + paddedInvoiceNumber,
        }
      : {
          ...InvoiceState,
          tax_info: null,
          transaction_type: transactionType,
          tax: invoiceSettings?.labels?.defaultTax,
          // tax_type: invoiceSettings?.labels?.taxType,
          terms_and_conditions: invoiceSettings?.labels?.termsCondition,
          invoice_number:
            (transactionType === TRANSACTION_TYPE.ESTIMATE
              ? invoiceSettings?.labels?.estimatePrefix?.toUpperCase()
              : invoiceSettings?.labels?.invoicePrefix?.toUpperCase()) + paddedInvoiceNumber,
        },
  );

  const [addContactModal, setContactAddModal] = useState(false);
  const [addTaxModal, setAddTaxModal] = useState(false);

  // get client details for edit if id >0
  const getInvoiceData = async () => {
    const response = await invoiceService.invoiceDetailsGet({ id: id });

    if (response?.data) {
      const resultInvoiceData = response?.data?.invoiceData;
      const resultInvoiceItemData = response?.data?.invoiceItems;

      const updatedInvoiceData = {
        ...resultInvoiceData,
        bill_date: resultInvoiceData?.bill_date
          ? moment(Number(resultInvoiceData.bill_date)).format('YYYY-MM-DD')
          : null,
        due_date: resultInvoiceData?.due_date
          ? moment(Number(resultInvoiceData.due_date)).format('YYYY-MM-DD')
          : null,
        client_id: resultInvoiceData?.client_id
          ? {
              id: resultInvoiceData?.client_id,
              name: resultInvoiceData?.client_name,
              currency: resultInvoiceData?.client_currency,
              tax_number: resultInvoiceData?.client_tax_number,
              tax_treatment: resultInvoiceData?.client_tax_treatment,
            }
          : null,
      };

      const updatedInvoiceItemData = resultInvoiceItemData?.map((invoiceItem) => ({
        ...invoiceItem,
        default_tax: invoiceItem?.default_tax
          ? {
              id: invoiceItem?.tax_id,
              name: invoiceItem?.tax_name,
              rate: invoiceItem?.tax_rate,
            }
          : null,
      }));

      setInvoiceData(updatedInvoiceData);
      setInvoiceItemData(updatedInvoiceItemData);
      return resultInvoiceData;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: invoiceEditData } = useQuery({
    queryKey: ['invoice-data', id],
    queryFn: () => getInvoiceData(),
    enabled: Boolean(id),
  });
  const handleItemChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItems = [...invoiceItemData];

    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };

    if (['quantity', 'rate', 'discount', 'default_tax'].includes(name)) {
      updatedItems[index] = calculateTaxAndAmount(updatedItems[index], invoiceData?.tax, false);
      const updateInvoiceData = calculateInvoiceAmount(
        invoiceData,
        {
          companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
        },
        updatedItems,
        makeAlert,
      );
      if (updateInvoiceData?.isSuccess) {
        setInvoiceData(updateInvoiceData?.data);
        setInvoiceItemData(updatedItems);
      }
      return;
    }

    setInvoiceItemData(updatedItems);
  };

  // to manage amount input
  const isValidAdjustment = (value) => {
    return /^-?\d*\.?\d*$/.test(value); // Allows numbers, optional negative sign, and decimals
  };
  console.log('check', invoiceItemData);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'tax') {
      const updateItemsData = invoiceItemData?.map((item) => calculateTaxAndAmount(item, value));
      const updateInvoiceData = calculateInvoiceAmount(
        { ...invoiceData, tax: value },
        {
          companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
        },
        updateItemsData,
        makeAlert,
      );
      if (updateInvoiceData?.isSuccess) {
        setInvoiceData(updateInvoiceData?.data);
        setInvoiceItemData(updateItemsData);
      }
      return;
    }

    setInvoiceData({
      ...invoiceData,
      [name]: value,
    });
  };

  const handleAdjustmentBlur = (event) => {
    const { name, value } = event.target;

    if (
      !isValidAdjustment(value) ||
      value === '-' ||
      value === '.' ||
      value === '-.' ||
      value === ''
    ) {
      setInvoiceData({
        ...invoiceData,
        [name]: 0,
      });
    }
  };

  // to delete selected item
  const handleItemDelete = (deleteIndex) => {
    const updateItemsData = invoiceItemData?.filter((_, index) => index !== deleteIndex);
    const updateInvoiceData = calculateInvoiceAmount(
      { ...invoiceData },
      {
        companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
      },
      updateItemsData,
      makeAlert,
    );

    if (updateInvoiceData?.isSuccess) {
      setInvoiceData(updateInvoiceData?.data);
      setInvoiceItemData(updateItemsData);
    }
  };

  // to add new item
  const handleNewItem = () => {
    if (invoiceData?.defaultTax !== (TAX_CONST.NOT_SELECTED || TAX_CONST.TAX_EXEMPT)) {
      setInvoiceItemData([
        ...invoiceItemData,
        { ...InvoiceItemState, default_tax: invoiceSettings?.labels?.taxGroup },
      ]);
    } else {
      setInvoiceItemData([...invoiceItemData, InvoiceItemState]);
    }
    // invoiceSettings?.labels?.defaultTax !== (TAX_CONST.NOT_SELECTED || TAX_CONST.TAX_EXEMPT)
    //   ? [{ ...InvoiceItemState, defaultTax: invoiceSettings?.labels?.taxGroup }]
    //   : [{ ...InvoiceItemState }],
    //   setInvoiceItemData([...invoiceItemData, InvoiceItemState]);
  };

  // save api call
  const handleSave = async () => {
    const updatedInvoiceItemData = invoiceItemData?.map((item) => ({
      ...item,
      default_tax: item?.default_tax?.id,
      discount: item?.discount ? item?.discount : 0,
    }));

    await invoiceService
      .invoiceAdd({
        ...invoiceData,
        client_id: invoiceData?.client_id?.id,
        bill_date: invoiceData?.bill_date
          ? new Date(invoiceData?.bill_date).getTime()
          : moment().valueOf(),
        due_date: invoiceData?.due_date
          ? new Date(invoiceData?.due_date).getTime()
          : moment().valueOf(),
        invoiceItems: [...updatedInvoiceItemData],
        files: selectedFiles,
      })
      .then(() => {
        setSubmitted(false);
        globalSettingsRefetch();
        afterAdd();
      });
    handleClose();
    setAddTaxModal(false);
    setContactAddModal(false);
  };

  const { mutate: handleMutate } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['invoice-add'],
    onSuccess: () => {
      setSubmitted(false);
      globalSettingsRefetch();
      afterAdd();
      handleClose();
      setAddTaxModal(false);
      setContactAddModal(false);
    },
    onError: (err) => {
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (
      invoiceData?.invoice_number?.toString().trim() === '' ||
      !invoiceData?.client_id?.id ||
      !new Date(invoiceData?.bill_date).getTime() ||
      !new Date(invoiceData?.due_date).getTime() ||
      new Date(invoiceData?.bill_date).getTime() > new Date(invoiceData?.due_date).getTime()
    ) {
      setIsLoading(false);
      return null;
    }

    if (invoiceItemData?.length === 0) {
      setIsLoading(false);
      makeAlert('Please enter at least one item with a valid amount');
      return null;
    }

    if (
      invoiceItemData?.length > 0 &&
      (invoiceItemData.some((item) => !item?.amount) ||
        invoiceItemData.some((item) => !item?.item_name) ||
        invoiceItemData.some(
          (item) =>
            !item?.default_tax?.id &&
            invoiceData?.tax !== TAX_CONST.NOT_SELECTED &&
            invoiceData?.tax !== TAX_CONST.TAX_EXEMPT,
        ))
    ) {
      setIsLoading(false);
      makeAlert('Please enter item with valid item details');
      return null;
    }
    // const updatedInvoiceItemData = invoiceItemData?.map((item) => ({
    //   ...item,
    //   default_tax: item?.default_tax?.id,
    // }));

    try {
      // invoice number validation
      let existingInvoice = null;
      if (!id) {
        existingInvoice = await invoiceService.invoiceList({
          invoice_number: invoiceData?.invoice_number,
        });
      }

      if (existingInvoice?.data?.total && !id) {
        setIsConfirmationVisible(true);
      } else {
        handleMutate();
      }
    } catch (error) {
      // setError({
      //   common: error?.message || alertConstants.SERVER_ERROR,
      // });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  // file upload section ,
  const onFileSelect = (files) => {
    let filesArray = [];

    for (let file of files) {
      filesArray.push({
        name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
        sanitizeName: sanitizeFileName(file.name),
        fileType: getFileType(file.name.split('.').pop()),
        size: file.size,
        extension: file.name.split('.').pop(),
        file,
        isUploading: false,
        isFailed: false,
        isSuccess: false,
      });
    }
    selectedFiles?.length > 0
      ? setSelectedFiles([...selectedFiles, ...filesArray])
      : setSelectedFiles([...filesArray]);
  };
  // remove selected file
  const fileRemove = (index) => {
    let tempArray = [...selectedFiles];
    tempArray.splice(index, 1);
    setSelectedFiles(tempArray);
  };

  return (
    <>
      <div className='modal_view_content'>
        <div>
          {/* Customer Info Section */}
          <div className='invoice-form-section'>
            <div className='grid-3-col'>
              <div>
                <div className='d-flex justify-content-between'>
                  <div className='my-1 fz-13px-rem '>{LABEL_OF_CLIENT}</div>
                  {roleBasedPermission?.ACTION_CLIENT_ADD && (
                    <ToolTip tooltipText={`Add ${LABEL_OF_CLIENT}`} isModern>
                      <div
                        className='contact-add-icon pointer'
                        onClick={() => setContactAddModal(true)}
                      >
                        <Plus size={14} />
                      </div>
                    </ToolTip>
                  )}
                </div>
                {/* <label className='mb-1 fz-13px-rem  label_input'>{LABEL_OF_CLIENT} Name</label> */}
                <SearchableInfiniteQuerySelect
                  name='client_id'
                  getOptionLabel={(option) => {
                    return option?.name;
                  }}
                  getOptionValue={(option) => {
                    return option?.id;
                  }}
                  queryFn={(params) =>
                    clientService.clientList({
                      ...params,
                      select: ['name', 'id', 'tax_number', 'country', 'tax_treatment', 'currency'],
                    })
                  }
                  queryKey={['client-list-invoice']}
                  enableCondition={true}
                  isCustomFunction
                  getFunctionParams={(searchKey) => {
                    return {
                      searchKey: searchKey,
                      select: ['id', 'name', 'tax_number', 'country', 'tax_treatment', 'currency'],
                    };
                  }}
                  onChange={(selectedOption) => {
                    let newInvoiceData;

                    if (selectedOption?.tax_treatment?.tax_not_applicable) {
                      newInvoiceData = {
                        ...invoiceData,
                        client_id: selectedOption,
                        tax: TAX_CONST.TAX_EXEMPT,
                      };
                    } else {
                      newInvoiceData = { ...invoiceData, client_id: selectedOption };
                    }
                    // const newInvoiceData = { ...invoiceData, client_id: selectedOption };
                    const updateInvoiceData = calculateInvoiceAmount(
                      newInvoiceData,
                      {
                        companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
                      },
                      invoiceItemData,
                      makeAlert,
                    );

                    if (updateInvoiceData?.isSuccess) {
                      setInvoiceData(updateInvoiceData?.data);
                    }

                    if (selectedOption?.currency) {
                      setCurrentCurrencyType(
                        currencyType.find((currency) => currency.id === selectedOption.currency)
                          ?.textIcon,
                      );
                    }
                  }}
                  placeholder={`Select ${LABEL_OF_CLIENT}`}
                  styles={CommonDropDownStyle}
                  menuPortalTarget={document.getElementById('MODAL')}
                  value={invoiceData?.client_id}
                />
                <ErrorBlock
                  errorMsg={`Please select ${LABEL_OF_CLIENT}`}
                  hasError={submitted && !invoiceData?.client_id?.id}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  {transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'}
                </label>
                <input
                  type='text'
                  className='input-box'
                  placeholder='Invoice number'
                  name='invoice_number'
                  value={invoiceData?.invoice_number}
                  onChange={handleChange}
                />
                <ErrorBlock
                  errorMsg={`Invoice number required`}
                  hasError={submitted && invoiceData?.invoice_number?.toString().trim() === ''}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  {transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Reference' : 'Order'} Number
                </label>
                <input
                  type='text'
                  className='input-box'
                  placeholder={`Enter ${
                    transactionType === TRANSACTION_TYPE.ESTIMATE ? 'reference' : 'order'
                  } number`}
                  name='order_number'
                  value={invoiceData.order_number}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Terms & Dates Section */}
          <div className='invoice-form-section'>
            <div className='grid-3-col'>
              {transactionType === TRANSACTION_TYPE.INVOICE && (
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Terms</label>
                  <input
                    type='text'
                    className='input-box'
                    name='terms'
                    value={invoiceData.terms}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>
                  {transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'} Date
                </label>
                <input
                  type='date'
                  className='input-box'
                  name='bill_date'
                  value={invoiceData.bill_date}
                  onChange={handleChange}
                />
                <ErrorBlock
                  errorMsg={` ${
                    transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'
                  } date required`}
                  hasError={submitted && !new Date(invoiceData?.bill_date).getTime()}
                />
              </div>
              <div>
                <label className='mb-1 fz-13px-rem  label_input'>Due Date</label>
                <input
                  type='date'
                  className='input-box'
                  name='due_date'
                  value={invoiceData.due_date}
                  onChange={handleChange}
                  min={invoiceData.bill_date}
                />
                <ErrorBlock
                  errorMsg={`Due date must be greater than ${
                    transactionType === TRANSACTION_TYPE.ESTIMATE ? 'Estimate' : 'Invoice'
                  } date`}
                  hasError={
                    submitted &&
                    new Date(invoiceData?.bill_date).getTime() >
                      new Date(invoiceData?.due_date).getTime()
                  }
                />
                <ErrorBlock
                  errorMsg={` 
                  Due date required`}
                  hasError={submitted && !new Date(invoiceData?.due_date).getTime()}
                />
              </div>
            </div>
          </div>

          <InvoiceAddItemTable
            invoiceData={invoiceData}
            invoiceItemData={invoiceItemData}
            inputRefs={inputRefs}
            setInvoiceData={setInvoiceData}
            handleItemChange={handleItemChange}
            handleItemDelete={handleItemDelete}
            handleNewItem={handleNewItem}
            setItemAddModal={setItemAddModal}
            handleChange={handleChange}
            setInvoiceItemData={setInvoiceItemData}
            calculateTaxAndAmount={calculateTaxAndAmount}
            setAddTaxModal={setAddTaxModal}
            // calculateInvoiceAmount={calculateInvoiceAmount}
          />

          {/* Notes & Total Section */}
          <div className='invoice-form-section'>
            <div className='grid-2-col'>
              <div className='total-section '>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Notes</label>
                  <textarea
                    className='notes-textarea box-focus '
                    placeholder='Add notes here'
                    name='customer_notes'
                    value={invoiceData.customer_notes}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div>
                  <label className='mb-1 fz-13px-rem  label_input'>Terms & Conditions</label>
                  <textarea
                    className='notes-textarea box-focus'
                    placeholder='Write here'
                    name='terms_and_conditions'
                    value={invoiceData.terms_and_conditions}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className='total-section'>
                <div className='total-row'>
                  <span>Sub total</span>
                  <span>
                    {currentCurrencyType}
                    {invoiceData?.sub_total}
                  </span>
                </div>

                {invoiceData?.tax_info?.map((tax, index) => {
                  return (
                    <div className='total-row' key={index}>
                      <span>{tax?.name}</span>
                      <span>
                        {currentCurrencyType}
                        {tax?.value ?? 0}
                      </span>
                    </div>
                  );
                })}
                {invoiceData?.tax != TAX_CONST.NOT_SELECTED &&
                  invoiceData?.tax != TAX_CONST.TAX_EXEMPT && (
                    <div className='total-row'>
                      <span>
                        {/* Total {TAX_TYPE_LIST?.find((tax) => tax.id === invoiceData?.tax_type)?.name}{' '}
                        amount */}
                        Total tax amount
                      </span>
                      <span>
                        {currentCurrencyType}
                        {invoiceData?.tax_amount}
                      </span>
                    </div>
                  )}
                {hasExist(INVOICE_IDS.SHIPPING_CHARGE) && (
                  <div className='total-row'>
                    <span>Shipping Charges</span>
                    <span style={{ width: '35%' }}>
                      <input
                        type='text'
                        className={`input-box ${
                          !invoiceData?.client_id?.id ? 'cursor-no-drop' : ''
                        }`}
                        name='shipping_charges'
                        value={invoiceData.shipping_charges}
                        onChange={(event) => {
                          if (event.target.value === '.' || isValidAdjustment(event.target.value)) {
                            const newInvoiceData = {
                              ...invoiceData,
                              shipping_charges: event.target.value,
                            };
                            const updateInvoiceData = calculateInvoiceAmount(
                              newInvoiceData,
                              {
                                companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
                              },
                              invoiceItemData,
                              makeAlert,
                            );
                            if (updateInvoiceData?.isSuccess) {
                              setInvoiceData(updateInvoiceData?.data);
                            }
                          }
                        }}
                        onBlur={handleAdjustmentBlur}
                        disabled={!invoiceData?.client_id?.id}
                      />
                    </span>
                  </div>
                )}
                {hasExist(INVOICE_IDS.ADJUSTMENT) && (
                  <div className='total-row'>
                    <span>Adjustment</span>
                    <span style={{ width: '35%' }}>
                      <input
                        type='text'
                        className='input-box'
                        name='adjustment'
                        value={invoiceData?.adjustment}
                        onChange={(event) => {
                          if (
                            event.target.value === '-' ||
                            event.target.value === '.' ||
                            event.target.value === '-.' ||
                            isValidAdjustment(event.target.value)
                          ) {
                            const newInvoiceData = {
                              ...invoiceData,
                              adjustment: event.target.value,
                            };
                            const updateInvoiceData = calculateInvoiceAmount(
                              newInvoiceData,
                              {
                                companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
                              },
                              invoiceItemData,
                              makeAlert,
                            );
                            if (updateInvoiceData?.isSuccess) {
                              setInvoiceData(updateInvoiceData?.data);
                            }
                          }
                        }}
                        onBlur={handleAdjustmentBlur}
                      />
                    </span>
                  </div>
                )}
                <div className='total-row mb-4'>
                  <span>Round off</span>

                  <span>
                    {currentCurrencyType}
                    {invoiceData?.round_off}
                  </span>
                </div>
                <div className='total-row grand-total'>
                  <span>Total</span>
                  <span>
                    {currentCurrencyType}
                    {invoiceData?.total}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* File Upload */}
          <div className='invoice-form-section'>
            {/* <div className="file-upload-box">
          <p>Drag and drop or click to upload</p>
        </div> */}
            <div>
              <input
                accept={'*/*'}
                multiple
                onChange={(e) => onFileSelect(e.target.files)}
                id='upload'
                type='file'
                style={{ display: 'none' }}
              />
              <div
                className='file-upload-contain'
                style={{
                  padding: '1rem 5px',
                  color: 'var(--second-text-color)',
                  backgroundColor: 'var(--bg-light)',
                }}
              >
                <label className='upload-text text-decoration-none' htmlFor='upload'>
                  <div className='icon-outer-file'>
                    <img src={FileSystemImage.FileUpload} alt='upload' height={45} width={45} />
                  </div>
                  {/* Drag and Drop file here or */}
                  Choose file
                </label>
              </div>

              <div style={{ maxHeight: '200px', overflow: 'scroll' }}>
                <SelectedFiles selectedFiles={selectedFiles} fileRemove={fileRemove} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
              setAddTaxModal(false);
              setContactAddModal(false);
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>

      {itemAddModal && (
        <Modal.Container
          handleClose={() => {
            setItemAddModal(false);
          }}
        >
          <Modal.View className={'w-25'}>
            <Modal.Head
              Heading={'New Item'}
              handleClose={() => {
                setItemAddModal(false);
              }}
            />
            <AddItemModal
              handleClose={() => {
                setItemAddModal(false);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {isConfirmationVisible && !id && (
        <Modal.Container>
          <Modal.View className={'status-delete w-50'}>
            <Modal.Head
              handleClose={() => setIsConfirmationVisible(false)}
              Heading={'Invoice Confirmation'}
            />
            <Modal.Description>
              <CircleCheck size={90} color='#fff' fill='#17c666' />
              <div className='del-description'>{MODAL_NOTES.INVOICE_CONFIRMATION}</div>
            </Modal.Description>
            <div className='modal_footer'>
              <Button.Container
                handleOk={() => setIsConfirmationVisible(false)}
                className={'outline-cancel-button'}
              >
                <Cancel />
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container handleOk={handleMutate} className={'blue-btn'}>
                <Tick />
                <Button.Title title={'Save'} />
                <Button.Loading isLoading={isLoading} />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}

      {addContactModal && (
        <Modal.Container
          handleClose={() => {
            setContactAddModal(false);
          }}
        >
          <Modal.View className={'w-75'}>
            <Modal.Head
              Heading={`New ${LABEL_OF_CLIENT}`}
              handleClose={() => {
                setContactAddModal(false);
              }}
            />
            <ClientAddModal
              handleClose={() => {
                setContactAddModal(false);
              }}
              afterAdd={() => queryClient.invalidateQueries('client-list-invoice')}
              // id={editClientId}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {console.log(addTaxModal, 'eeee')}

      {addTaxModal && (
        <Modal.Container
          handleClose={() => {
            setAddTaxModal(false);
          }}
        >
          <Modal.View className={'w-25'}>
            <Modal.Head
              Heading={'New Tax'}
              handleClose={() => {
                setAddTaxModal(false);
              }}
            />
            <AddTaxModal
              // id={editTaxId}
              handleClose={() => {
                setAddTaxModal(false);
                // setEditTaxId(null);
                // refetch();
              }}
              afterAdd={async () =>
                await queryClient.invalidateQueries({ queryKey: ['tax-list-invoice'] })
              }
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
};
