/* eslint-disable no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import { NOTIFICATION_FROM } from '../Notification/notificationConstant';
import { fullNameUser, jsonToUrlEncoded } from '../Utils';
import NoPermission from '../_components/NoPermission';
import { apiConstants, USER_PLACEHOLDER } from '../_constants';
import { notificationService } from '../_services/notification.service';
import { UpdateItem } from './UpdatedItem';

export function NotificationUpdates({ isChecked }) {
  const { isUnRead, setIsUnRead } = useOutletContext();

  const [updateQuery, setUpdateQuery] = useState({
    select: [
      'id',
      'task_id',
      'last_message',
      'last_message_time',
      'message',
      'message_count_info',
      'update_type',
      'updated_time',
    ],
    update_type: 'task',
    unread: isUnRead,
  });

  const [urlQuery, setULRQuery] = useState();

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const {
    data: notificationData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  } = useInfiniteQuery({
    queryKey: ['notification-list-updates', updateQuery, isUnRead],
    queryFn: ({ pageParam = 1 }) =>
      notificationService.updateList(
        getPayLoad({
          ...updateQuery,
          unread: isUnRead,
          notification_from: NOTIFICATION_FROM.CHAT,

          pageVo: {
            pageNo: pageParam,
            noOfItems: 30,
            sort: 'last_message_time',
            // sort: id === NOTIFICATION_TYPE.TASK_CHAT ? 'last_message_time' : 'id', // different sort order for notification and updates list
            sort_order: 'DESC',
          },
        }),
      ),
    select: (data) => data.pages,
    // enabled: Boolean(id),
    getNextPageParam: (res) => (res.data.page < res.data.pages ? res.data.page + 1 : undefined),
  });

  const allNotifications = notificationData?.flatMap((page) => page.data.rows) || [];
  console.log('notificationData', allNotifications?.length);

  const pathName = window.location.pathname;

  const Row = ({ index, style }) => {
    const item = allNotifications[index];
    if (!item) return null;

    const currentIndex = index;
    let taskName = item?.Task?.name;
    let updatesCount = item.update_count;
    let taskOwner = item?.Task?.User;
    let taskOwnerName = fullNameUser(taskOwner?.first_name, taskOwner?.last_name);
    let taskOwnerProfile = taskOwner?.image_url
      ? apiConstants.imgUrlS3 + taskOwner?.image_url
      : USER_PLACEHOLDER.MALE;

    let lastMessageData = item.message[item.message.length - 1];
    let lastMessage = lastMessageData?.message;
    let ltMsgCreatorDetails = lastMessageData?.creator_details;
    let ltMsgCreatorName = fullNameUser(
      ltMsgCreatorDetails?.first_name,
      ltMsgCreatorDetails?.last_name,
    );

    let ltMsgCreatorProfile = item?.User?.image_url
      ? apiConstants.imgUrlS3 + item?.User?.image_url
      : USER_PLACEHOLDER.MALE;

    const formattedDate = moment(parseInt(item?.last_message_time)).format(
      'MMM D, YYYY [at] h:mm A',
    );

    let taskId = item?.task_id;

    return (
      <div style={style}>
        <UpdateItem
          index={currentIndex}
          queryPayload={urlQuery}
          formattedDate={formattedDate}
          taskOwnerProfile={taskOwnerProfile}
          taskName={taskName}
          ltMsgCreatorProfile={ltMsgCreatorProfile}
          ltMsgCreatorName={ltMsgCreatorName}
          lastMessage={lastMessage}
          updatesCount={updatesCount}
          taskId={taskId}
          pathName={pathName}
          isChecked={isUnRead}
        />
      </div>
    );
  };

  if (isPending) {
    return <section className='notification-container'>Loading...</section>;
  }

  return (
    <section className='notification-container'>
      {allNotifications?.length ? (
        <section>
          <List
            style={{ height: '77vh' }}
            onItemsRendered={({ visibleStopIndex }) => {
              if (
                visibleStopIndex >= allNotifications.length - 1 &&
                hasNextPage &&
                !isFetchingNextPage
              ) {
                console.log('Fetching next page...');
                fetchNextPage();
              }
            }}
            height={650}
            itemCount={allNotifications.length}
            itemSize={74}
            width={'100%'}
          >
            {Row}
          </List>
        </section>
      ) : (
        <NoPermission data='No notifications at the moment. Check back later!' />
      )}
    </section>
  );
}
