/* eslint-disable no-unused-vars */
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { ImageCrop } from '../_components/ImageCrop';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { apiConstants, CommonDropDownStyle } from '../_constants';
import { userService } from '../_services';
import { genderList } from '../Myteam/MyTeamAddModal';
import { MY_TEAM_ERROR_MSG } from '../Myteam/MyTeamConstant';
import { GlobalContext, phoneNumberValidation } from '../Utils';
import { Cancel, LoadingIcon, Tick } from '../Utils/SvgIcons';

export const NewProfileDetails = () => {
  const { userProfileData, userRefetch, userLoading } = useOutletContext();
  const { globalSettings, makeAlert } = useContext(GlobalContext);
  const employeeSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'employee-settings')?.labels;

  const hasExist = (name) => {
    const setting = employeeSettings?.find((label) => label.label === name);
    return setting ? setting.value : undefined;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();

  let today = moment().format('YYYY-MM-DD');
  const [localData, setLocalData] = useState({});
  // const [userData, setUserData] = useState({ ...userProfileData });
  const userData = { ...userProfileData, ...localData };

  const [editActive, setEditActive] = useState(false);
  const handleChange = (event) => {
    if (!editActive) {
      setEditActive(true);
    }
    const { name, value } = event.target;
    setLocalData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // save api call
  const handleSave = async () => {
    await userService.updateProfile(
      localData?.dob
        ? { ...localData, dob: new Date(localData?.dob).getTime(), id: userProfileData?.id }
        : { ...localData, id: userProfileData?.id },
    );
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['profile-update'],
    onSuccess: (data) => {
      setEditActive(false);
      userRefetch();
      makeAlert('Profile data updated successfully');
    },
    onError: (err) => {
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (
      (localData?.first_name !== undefined && !localData?.first_name?.toString()?.trim()) ||
      (localData?.last_name !== undefined && !localData?.last_name?.toString()?.trim())
    ) {
      makeAlert('Invalid Data', 'error');
      setIsLoading(false);
      return;
    }

    const isPhoneValid = localData?.phone_no ? phoneNumberValidation(userData?.phone_no) : true;
    if (!isPhoneValid) {
      makeAlert(MY_TEAM_ERROR_MSG.PHONE_NUMBER, 'error');
      setIsLoading(false);
      return;
    }

    try {
      handleMutate();
    } catch (error) {
      makeAlert('Update failed');
    } finally {
      setIsLoading(false);
      // setSubmitted(false);
    }
  };

  if (!userProfileData?.id) {
    return <LoadingIcon />;
  }

  const changePicture = (event) => {
    const { name, files, value } = event.target;
    setFile(URL.createObjectURL(event.target.files[0]));
    setImage((prev) => ({
      ...prev,
      imageFile: files[0],
    }));
    setIsPreviewOpen(true);
  };

  //function to save image
  const editProfileImage = async (img, compressedData) => {
    if (!editActive) {
      setEditActive(true);
    }
    setLocalData((prev) => ({
      ...prev,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed,
    }));
  };

  return (
    <div style={{ padding: 16 }}>
      {/* header section  */}
      <section>
        <div className='profile-head'>Profile</div>
        <div className='profile-description'>Manage your personal details</div>
      </section>
      <div className='common-separation my-3' />
      {/* image section  */}
      {hasExist('Employee Picture') && (
        <section>
          <div className='d-flex align-items-center gap-2 mb-2'>
            <div className='details-heading cursor-pointer ' htmlFor='image_upload'>
              <CircularAvatar
                size={50}
                className='fz-none zoom-in employee-img-with-low-border'
                source={
                  userData?.compressed_image_url
                    ? apiConstants.imgUrlS3 + userData.compressed_image_url
                    : userData?.image_url
                    ? apiConstants.imgUrlS3 + userData.image_url
                    : null
                }
                isMale={userData?.gender === 1}
                isNeutral={userData?.gender === 3}
              />
              {/* Change */}
            </div>
            <div>
              <div className='profile-head'>Profile Picture</div>
              <div className='profile-description'>Support PNG, JPG under 10 MB</div>
              <div
                style={{ color: 'var(--Base-Color)', cursor: 'pointer' }}
                onClick={() => document.getElementById('image_upload')?.click()} // Trigger file selection
              >
                Upload new photo
              </div>
            </div>
            <input
              type='file'
              name='image_url'
              id='image_upload'
              hidden
              onChange={changePicture}
              accept='image/*'
            />
          </div>
        </section>
      )}
      <section>
        <div className='row'>
          <div className='col'>
            <label className='mb-1 fz-13px-rem  label_input_gray'>First Name</label>
            <input
              type='text'
              className='input-box'
              name='first_name'
              value={userData?.first_name}
              placeholder={`Enter first name`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {/* <ErrorBlock
              errorMsg={PROJECT_REQUIRED_FIELD_MSG.NAME}
              hasError={submitted && project?.name.toString().trim() === ''}
            /> */}
          </div>
          <div className='col'>
            <label className='mb-1 fz-13px-rem  label_input_gray'>Last Name</label>
            <input
              type='text'
              className='input-box'
              name='last_name'
              value={userData?.last_name}
              placeholder={`Enter last name`}
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {/* <ErrorBlock
              errorMsg={PROJECT_REQUIRED_FIELD_MSG.NAME}
              hasError={submitted && project?.name.toString().trim() === ''}
            /> */}
          </div>
        </div>

        <div className='row'>
          {hasExist('Gender') && (
            <div className='col'>
              <label className='label_input_gray'>Gender</label>
              <ReactSelect
                styles={CommonDropDownStyle}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                options={genderList}
                getOptionLabel={(option) => {
                  return option?.label;
                }}
                getOptionValue={(option) => {
                  return option?.value;
                }}
                onChange={(selectedOption) => {
                  handleChange({
                    target: {
                      name: 'gender',
                      value: selectedOption.value,
                    },
                  });
                }}
                value={genderList.find((option) => option?.value === userData?.gender) || null}
              />
              {/* <ErrorBlock
                errorMsg={MY_TEAM_REQUIRED_FIELD_MSG.GENDER}
                hasError={submitted && !userData?.gender}
              /> */}
            </div>
          )}

          {hasExist('Date of Birth') && (
            <div className='col'>
              {/* <div> */}
              <label className='label_input_gray dob-text'>Date of Birth</label>
              <input
                type='date'
                className='input-box'
                max={today}
                name='dob'
                min={0}
                style={{ padding: '4px ' }}
                value={userData?.dob ?? null}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col'>
            <label className='mb-1 fz-13px-rem  label_input_gray'>Email</label>
            <input
              type='text'
              className='input-box'
              name='email_id'
              value={userData?.email_id}
              placeholder={`Enter email`}
              disabled
              style={{ backgroundColor: 'var(--bg-light-gray)' }} // onChange={(event) => {
            />
          </div>
          {hasExist('Contact Number') && (
            <div className='col'>
              <label className='mb-1 fz-13px-rem  label_input_gray'>Phone</label>
              <input
                type='text'
                className='input-box'
                name='phone_no'
                value={userData?.phone_no}
                placeholder={`Enter phone`}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          )}
        </div>
      </section>

      {editActive && (
        <div className=' d-flex justify-content-end gap-2 mt-4'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              setEditActive(false);
              setLocalData({});
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container type='submit' handleOk={handleSubmit} isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Update '} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      )}

      {isPreviewOpen && (
        <ImageCrop
          handleClose={() => {
            setIsPreviewOpen(false);
          }}
          file={file}
          uploadCroppedImage={editProfileImage}
          setImage={setImage}
          image={image}
          setIsPreviewOpen={setIsPreviewOpen}
        />
      )}
    </div>
  );
};
