import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Clock, Home, ListFilter } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from '../_components/DatePicker';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { Popover } from '../_components/Popover';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import UserDropDown from '../_components/UserDropDown';
import { COMMON_USER_SELECT, CommonDropDownStyle, RT, USER_LIST_ACTIONS } from '../_constants';
import { userService } from '../_services';
import { reportServices } from '../_services/reports.service';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { useUser } from '../Hook/useUser';
import { formatMillisecondFeatured, GlobalContext } from '../Utils';
import { ReportProgressIcon, User } from '../Utils/SvgIcons';
import ActivityReport from './ActivityReports/ActivityReport';
import { TimeLogFilter } from './ActivityReports/TimeLogFilter';
import { CustomSingleValue } from './TimeHistory';

export function WorkActivity() {
  const { makeAlert, roleBasedPermission } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState(useUser());
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const user_id = searchParams.get('user_id');
  const fromDate = searchParams.get('fromDate');
  const toDate = searchParams.get('toDate');
  const isProfile = searchParams.get('profile');

  // filter popup reference
  const { containerRef: filterRef } = useOutsideClickHandler({
    onClose: () => setIsFilterVisible(false),
  });

  // filter ids in url---
  const project_id = searchParams.get('project_id');
  const client_id = searchParams.get('client');
  // const document_type_id = searchParams.get('document_type');
  const action = searchParams.get('action');
  // const expiry_date = searchParams.get('expiry');

  // filter objects pass with API
  const filterToUrl = {};
  if (project_id) filterToUrl.project_id = project_id;
  if (client_id) filterToUrl.client_id = client_id;
  // if (document_type_id) filterToUrl.document_type_id = document_type_id;
  if (action) filterToUrl.action = action;
  // if (expiry_date) filterToUrl.expiry_date = expiry_date;
  const filterCount = Object.keys(filterToUrl).length;

  // query initialization
  const [query, setQuery] = useState({
    user_id: user_id,
    fromDate: new Date(parseInt(fromDate)).toUTCString(0, 0, 0, 0), // to convert timestamp with timezone format
    toDate: new Date(parseInt(toDate)).toUTCString(23, 59, 59, 999),
  });

  // for fetch log
  const {
    data: activityLog,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    error: logFetchError,
  } = useInfiniteQuery({
    queryKey: ['log-list', user_id, query.fromDate, query.toDate, filterToUrl],
    queryFn: ({ pageParam = 1 }) =>
      reportServices.workLogList({
        ...query,
        select: ['id', 'action', 'status', 'time_difference', 'createdAt', 'data'],
        pageVo: { pageNo: pageParam, noOfItems: 10 },
        ...filterToUrl,
      }),
    select: (data) => data.pages,
    enabled: Boolean(user_id) && roleBasedPermission?.ACTION_TIME_LOG,
    getNextPageParam: (res) => (res.data.page < res.data.pages ? res.data.page + 1 : undefined),
  });
  if (logFetchError) makeAlert(logFetchError.message);

  // fetch user Data assignee's with current user
  const { data: userData, error: assignUserFetchError } = useQuery({
    queryKey: ['user-list'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        select: COMMON_USER_SELECT,
        action: USER_LIST_ACTIONS.ACTION_TASK_LIST,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      return userData;
    },
  });
  if (assignUserFetchError) makeAlert(assignUserFetchError.message);

  // handle to set params like user_id
  const handleSetSearchParams = (name, value) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set(`${name}`, value);
    setSearchParams(updatedSearchParams);
  };

  // handle change of date
  const handleDateChange = (dates) => {
    const startDate = moment(dates.startDate).startOf('day');
    const endDate = moment(dates.endDate).endOf('day');
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('fromDate', startDate.valueOf());
    updatedSearchParams.set('toDate', endDate.valueOf());
    setSearchParams(updatedSearchParams);
    setQuery({
      ...query,
      fromDate: new Date(parseInt(startDate.valueOf())).toUTCString(0, 0, 0, 0), // to convert timestamp with timezone format
      toDate: new Date(parseInt(endDate.valueOf())).toUTCString(23, 59, 59, 999),
    });
  };

  // handler to change user
  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
    handleSetSearchParams(name, value);
  };

  const totalTime = activityLog?.[0]?.data?.total_time_difference;

  // constant to identify the component called from  profile section or not
  const isTimeLogFromProfile = searchParams.get('profile');

  if (!roleBasedPermission?.ACTION_TIME_LOG) {
    return <NoPermission />;
  }

  return (
    <div className=' fade-entry'>
      {/* breadcrumbs area  */}
      <section>
        <div
          className={`d-flex align-items-center url-container  breadcrumbs ${
            isTimeLogFromProfile ? 'd-none' : ''
          } `}
        >
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            color={'#67677F'}
            icon={<Home size={16} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={'Reports'}
            icon={<ReportProgressIcon width={14} height={14} />}
            gap={3}
            color={'#67677F'}
            fontWeight={400}
            onClick={() =>
              navigate(
                `/${RT.WORK_ACTIVITY}?user_id=${searchParams.get(
                  'user_id',
                )}&fromDate=${searchParams.get('fromDate')}&toDate=${searchParams.get('toDate')}`,
              )
            }
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            // label={'Time Log'}
            label={'User Activity'}
            fontWeight={500}
            icon={<Clock width={14} height={14} color='#2A2E34' />}
            color={'#2A2E34'}
            gap={3}
            onClick={() =>
              navigate(
                `/${RT.WORK_ACTIVITY}?user_id=${searchParams.get(
                  'user_id',
                )}&fromDate=${searchParams.get('fromDate')}&toDate=${searchParams.get('toDate')}`,
              )
            }
          />
        </div>
      </section>

      <div
        className='time-log-container'
        style={{ margin: isTimeLogFromProfile ? '16px 0px' : '16px' }}
      >
        <div className={isProfile ? '' : ''}>
          {/* <div className='report-heading '>Time Log</div> */}
          <div className='report-heading '>User Activity</div>

          <div className='my-3 d-flex justify-content-between'>
            <div className='d-flex gap-2 align-items-center'>
              <DatePicker
                inputContainerClass={'date-picker'}
                isDatePrefixNeed={false}
                onDateChange={handleDateChange}
                initialStartDate={parseInt(searchParams.get('fromDate'))}
                initialEndDate={parseInt(searchParams.get('toDate'))}
                isEditable
              />
              <div style={{ width: 'fit-content' }}>
                <SearchableQuerySelect
                  queryKey={'document-type-list'}
                  queryFn={userService.getRoleBasedUserList}
                  getFunctionParams={(searchKey) => ({
                    searchKey,
                    action: USER_LIST_ACTIONS.ACTION_TASK_LIST,
                    select: COMMON_USER_SELECT,
                  })}
                  isCustomFunction
                  initialValue={userData}
                  menuPortalTarget={document.getElementById('MODAL')}
                  formatOptionLabel={(data, metaData) => (
                    <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
                  )}
                  getOptionValue={(option) => option.id}
                  onChange={(selectedOptions) => {
                    setSelectedUser(selectedOptions);
                    handleChange({
                      target: { value: parseInt(selectedOptions?.id), name: 'user_id' },
                    });
                  }}
                  styles={CommonDropDownStyle}
                  components={{
                    SingleValue: (props) => (
                      <CustomSingleValue {...props} icon={<User color='var(--icon-light)' />} />
                    ),
                    DropdownIndicator: CustomDropdownIndicator,
                  }}
                  placeholder={
                    <div className='d-flex align-items-center gap-1'>
                      <User /> User
                    </div>
                  }
                  value={selectedUser}
                />
              </div>
              <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                <div
                  className='menu-item-outline'
                  style={{
                    background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                  }}
                  ref={filterRef}
                  onClick={() => {
                    // setIsSortVisible(false);
                    setIsFilterVisible(true);
                  }}
                >
                  <ListFilter
                    size={16}
                    color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                  />
                  {isFilterVisible && (
                    <Popover.PopoverItem>
                      <TimeLogFilter
                        setSearchParams={setSearchParams}
                        handleClose={(event) => {
                          event.stopPropagation();
                          setIsFilterVisible(false);
                        }}
                        searchParams={searchParams}
                      />
                    </Popover.PopoverItem>
                  )}

                  {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                </div>
              </ToolTip>
            </div>
            {totalTime > 0 && (
              <div className='my-auto fz-12px ' style={{ height: 28 }}>
                <span className='pe-1'>{formatMillisecondFeatured(parseInt(totalTime), true)}</span>
                <Clock size={18} />
              </div>
            )}
          </div>

          <section className=''>
            <div className='row sub-section'>
              <ActivityReport
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                activityLog={activityLog}
                hasNextPage={hasNextPage}
                isList
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
