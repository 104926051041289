import { useCallback, useRef } from 'react';

function useDebouncedOnChange(callback, delay) {
  const timeoutRef = useRef(null);

  const debouncedFunction = useCallback(
    (...args) => {
      // Clear the existing timeout if there’s any
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  // Cleanup on unmount
  const cancel = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  return [debouncedFunction, cancel];
}

export default useDebouncedOnChange;
