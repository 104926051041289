/* eslint-disable no-unused-vars */
import { Flag } from 'lucide-react';
import React, { useEffect } from 'react';
import StackedAvatars from '../../_components/Avatar/StackedAvatars';
import { getPriorityByContextAPI } from '../../Task/taskValidation';
import { fullNameUser, Images } from '../../Utils';
import { CircleRightArrow } from '../../Utils/SvgIcons';
import './AiComponent.css';

export const AIChatHeader = ({ user }) => {
  useEffect(() => {}, []);

  return (
    <header className='header-ai'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {/* First line typing effect */}
        <span
          style={{
            fontSize: '0.8em',
            background: ' #f4b400',
            color: 'white',
            padding: ' 2px 6px',
            borderRadius: 4,
          }}
        >
          Hidesk AI Beta
        </span>
        <p className='type first-line'>
          <span className='hi-emoji'>👋 </span> Hi, {user?.first_name} {user?.last_name}
        </p>

        {/* Second line typing effect, hidden until ready */}
      </div>
    </header>
  );
};

export const TaskCard = (props) => {
  const { data, openTask } = props;
  const options = getPriorityByContextAPI(); // to fetch the priority  label from context api

  return (
    <div
      style={{
        minWidth: 260,
        width: 300,
        background: 'rgb(255 255 255)',
        borderRadius: 5,
        padding: '9px 8px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 6px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 5,
        cursor: 'pointer',
        gap: 5,
      }}
      onClick={() => openTask(data?.id)}
    >
      {/* Top Label Section */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '2px',
          justifyContent: 'space-between',
        }}
      >
        <section>
          {data?.priority && (
            <LabelView name={`${options?.find((item) => item.value == data?.priority)?.label}`} />
          )}
        </section>
        {data?.user_details && (
          <StackedAvatars
            imageList={[data?.user_details]}
            getSourceByKey={(user) => user?.image_url}
            getNameByKey={(user) => fullNameUser(user?.first_name, user?.last_name)}
            showInitials
            hideTooltip
          />
        )}
      </div>

      {/* Title Section */}
      <div
        style={{
          fontSize: '14px',
          fontWeight: '500',
          color: '#1D1D1D',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {data?.task_name || data?.name}
      </div>

      {/* Flag Section */}
      <section style={{ height: 25 }}>
        {data?.order_seq && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Flag color='#BC4841' size={13} />
            <span style={{ marginLeft: '5px', fontSize: '15px', color: '#1D1D1D' }}>
              {data?.order_seq}
            </span>
          </div>
        )}
      </section>
    </div>
  );
};

const LabelView = ({ name = 'Energy booster' }) => {
  return (
    <span
      style={{
        backgroundColor: '#E2EBFE',
        color: '#4F73D1',
        borderRadius: '15px',
        padding: '2px 8px',
        fontSize: '11px',
        fontWeight: 'bold',
        marginRight: 'auto',
      }}
    >
      {name}
    </span>
  );
};

export const TaskListContainer = ({
  children,
  aiIconNeed = false,
  isNavigable = true,
  onNavigate = () => {},
}) => {
  return (
    <div style={{ display: 'flex', overflow: 'auto' }}>
      <div
        style={{
          borderRadius: ' 10px 10px 10px 10px',
          border: ' 1px solid var(--border, #f2f3f8)',
          background: '#f5f5f5',
          // marginTop: '10px',
          padding: '10px 10px',
          // maxHeight: ' 165px',
          display: 'flex',
          //   flexDirection: 'column',
          position: 'relative',
          marginBottom: 10,
        }}
      >
        {aiIconNeed && (
          <div style={{ position: 'absolute', left: -40, top: 4 }}>
            <img src={Images.ai} height={30} width={30} />
          </div>
        )}
        {children}
        {isNavigable && (
          <div onClick={() => onNavigate()} style={{ position: 'absolute', right: -35 }}>
            <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
          </div>
        )}
      </div>
    </div>
  );
};

export const MessageContainer = ({
  children,
  aiIconNeed = false,
  isNavigable = false,
  onNavigate = () => {},
  isHoverBased = true,
  customClass,
}) => {
  return (
    <div
      className={`${customClass ? customClass : 'message-container'} ${
        isHoverBased ? 'hover-based' : ''
      }`}
    >
      {aiIconNeed && (
        <div style={{ position: 'absolute', left: -40, top: 4 }}>
          <img src={Images.ai} height={30} width={30} />
        </div>
      )}
      <div>{children} </div>

      {isNavigable && (
        <div
          className='navigate-arrow'
          onClick={() => onNavigate()}
          style={{ position: 'absolute', right: -35, cursor: 'pointer' }}
        >
          <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
        </div>
      )}
    </div>
  );
};
export const Test = ({
  children,
  aiIconNeed = false,
  isNavigable = false,
  onNavigate = () => {},
  isHoverBased = true,
  customClass,
}) => {
  return (
    <div style={{ display: 'flex', background: 'red' }}>
      <div
        className={`${customClass ? customClass : 'message-container'} ${
          isHoverBased ? 'hover-based' : ''
        }`}
      >
        {aiIconNeed && (
          <div style={{ position: 'absolute', left: -40, top: 4 }}>
            <img src={Images.ai} height={30} width={30} />
          </div>
        )}
        <div style={{ overflow: 'auto' }}>{children} </div>

        {isNavigable && (
          <div
            className='navigate-arrow'
            onClick={() => onNavigate()}
            style={{ position: 'absolute', right: -35, cursor: 'pointer' }}
          >
            <CircleRightArrow color='#879096' size={28} strokeWidth={1.5} />
          </div>
        )}
      </div>
    </div>
  );
};
