import { useQuery } from '@tanstack/react-query';
import { SquarePen, X } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants, RT, USER_PLACEHOLDER } from '../_constants';
import { CHAT_UPDATE_TYPE } from '../_constants/chat.constants';
import { chatService } from '../_services/chat.service';
import { GlobalContext } from '../Utils';
import ChatCreationPopUp from './ChatCreationPopUp';
import GroupMemberDetails from './GroupMemberDetails';

const ChatHeader = ({ activeUpdate, updateRefetch = () => {} }) => {
  const memberCountRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isGroupPopUpVisible, setIsGroupPopUpVisible] = useState(false);
  const { roleBasedPermission } = useContext(GlobalContext);

  const {
    data,
    isLoading,
    isError,
    refetch: refetchMembers,
  } = useQuery({
    queryFn: () =>
      chatService.getChatGroupMembers({
        payload: {
          id: activeUpdate.id,
          select: ['id', 'first_name', 'image_url', 'last_name'],
        },
      }),
    queryKey: ['group-members', activeUpdate.id],
    enabled: Boolean(activeUpdate?.members),
    select: (res) => res.data,
    staleTime: Infinity,
  });

  const members = data?.rows ?? [];
  const groupCreatorId = data?.creator_id ? parseInt(data?.creator_id) : null;
  const loggerUserId = parseInt(JSON.parse(localStorage.getItem('user'))?.id ?? 0);
  const isCreatorIsUser = groupCreatorId ? loggerUserId === groupCreatorId : false;

  useEffect(() => {
    if (!memberCountRef.current) {
      return;
    }

    const handleHover = () => {
      setIsHovering(true);
    };

    const handleMouseLeave = () => {
      setTimeout(() => {
        setIsHovering(false);
      }, 200);
    };

    memberCountRef.current?.addEventListener('mouseover', handleHover);
    memberCountRef.current?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      memberCountRef.current?.removeEventListener('mouseover', handleHover);
      memberCountRef.current?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [memberCountRef.current]);

  if (!activeUpdate) {
    return null;
  }

  const { update_type, image, gender = 1 } = activeUpdate;

  const imageUrl = apiConstants.imgUrlS3 + image;

  const isGroup = update_type === CHAT_UPDATE_TYPE.GROUP;
  const navigate = useNavigate();

  return (
    <div className='d-flex  chat_header_condainer  ' style={{ borderRadius: 0 }}>
      <div
        className={`d-flex align-items-center chat-user-list chat-head-content px-2 w-100`}
        style={{ borderBottom: 'none' }}
      >
        {/* Avatar */}
        <CircularAvatar
          source={imageUrl}
          size={35}
          className='fz-none zoom-in employee-img-with-low-border'
          isMale={gender === 1}
          placeholderSrc={isGroup ? USER_PLACEHOLDER.GROUP : null}
        />
        <div className=' px-2 d-flex flex-column justify-content-center' style={{ flex: 1 }}>
          {/* Name and time */}
          <div
            className='d-flex align-items-center user-details-container'
            style={{ gap: 3, whiteSpace: 'nowrap' }}
          >
            <div className='chat_msg_name fz-15px fw-500'>{activeUpdate.title}</div>
          </div>

          {/* Recent Msg */}

          {activeUpdate?.members > 0 && (
            <div ref={memberCountRef} className='members-count fz-14px position-relative'>
              {activeUpdate.members} Members
              <GroupMemberDetails
                members={members}
                isLoading={isLoading}
                isError={isError}
                onMemberRefetch={refetchMembers}
                isContainerHover={isHovering}
              />
            </div>
          )}
        </div>
        {/* Message Count */}
        <div>
          <div className='chat_header_icon_condainer gap-2'>
            <X onClick={() => navigate('/chat?tab=all')} className='chat-close' color='#87909E' />

            {/* <Video /> */}
            {/* <Search /> */}
            {/* <Ellipsis /> */}
          </div>
          <div className='position-relative left-0 right-auto'>
            {((activeUpdate.group_id && isCreatorIsUser) || activeUpdate.project_id) &&
              roleBasedPermission?.ACTION_PROJECT_EDIT && (
                <div
                  role='button'
                  onClick={() => {
                    if (activeUpdate.project_id) {
                      navigate(`/${RT.PROJECT_ADD}/${activeUpdate.project_id}`);
                    } else {
                      setIsGroupPopUpVisible(true);
                    }
                  }}
                >
                  <SquarePen size={18} />
                </div>
              )}

            {/* <Group */}
            <EditGroupPopUp
              members={members}
              isLoading={isLoading}
              updateRefetch={() => {
                refetchMembers();
                updateRefetch();
              }}
              activeUpdate={activeUpdate}
              isVisible={isGroupPopUpVisible}
              onClose={() => setIsGroupPopUpVisible(false)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const EditGroupPopUp = ({
  isVisible,
  isLoading,
  members = [],
  onClose,
  activeUpdate,
  updateRefetch,
}) => {
  if (isLoading) {
    return null;
  }

  return (
    <>
      {isVisible && (
        <ChatCreationPopUp
          onClose={onClose}
          isEdit
          onGroupCreation={() => updateRefetch()}
          initialDetails={{
            id: activeUpdate.group_id,
            groupImage: activeUpdate.group.image,
            groupName: activeUpdate.group.name,
            selectedUsers: members,
          }}
          initialStep={2}
          className='group-edit'
        />
      )}
    </>
  );
};

export default ChatHeader;
