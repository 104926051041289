import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const chatService = {
  getChatMessages,
  getChatList,
  addChatMessage,
  getChatUpdateList,
  getChatUpdateListAdvanced,
  addChatUpdate,
  getLatestMessages,
  updateMessageReaction,
  addChatGroup,
  deleteChatMessage,
  getUpdateCount,
  getChatGroupMembers,
  forwardChatMessage,
};

async function getChatMessages(payload) {
  const params = jsonToQueryString(payload);
  const apiUrl = `${apiConstants.apiUrl}/get_chat_message?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
async function getUpdateCount() {
  // const params = jsonToQueryString(payload);
  const apiUrl = `${apiConstants.apiUrl}/chat_update_count`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getChatList({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_message_list`, requestOptions).then(handleResponse);
}

async function addChatMessage({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_message_add`, requestOptions).then(handleResponse);
}

async function forwardChatMessage(payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/forward_chat_message`, requestOptions).then(handleResponse);
}

async function getChatUpdateList({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_update_list`, requestOptions).then(handleResponse);
}
async function getChatUpdateListAdvanced({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_update_list_advanced`, requestOptions).then(
    handleResponse,
  );
}

async function addChatUpdate({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_update_list`, requestOptions).then(handleResponse);
}

async function getLatestMessages({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/latest_chat_messages`, requestOptions).then(handleResponse);
}

async function updateMessageReaction({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/update_message_reaction`, requestOptions).then(
    handleResponse,
  );
}

async function addChatGroup({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_group_add`, requestOptions).then(handleResponse);
}

async function getChatGroupMembers({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/group_members`, requestOptions).then(handleResponse);
}

async function deleteChatMessage({ payload }) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${apiConstants.apiUrl}/chat_message_delete`, requestOptions).then(handleResponse);
}
