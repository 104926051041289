/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Download, Home, ReceiptText } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, Modal, PaginationComponent, TableRow } from '../_components';
import DatePicker from '../_components/DatePicker';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { CommonDropDownStyle, MENU, RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService } from '../_services';
import { invoiceService } from '../_services/invoice.service';
import '../common.css';
import { Export } from '../Export/Export';
import { CustomSingleValue } from '../Report/TimeHistory';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext, MyTeamIcon } from '../Utils';
import {
  Calendar,
  ClientIcon,
  InvoiceIcon,
  LoadingIcon,
  PaymentIcon,
  SINoIcon,
} from '../Utils/SvgIcons';
import './invoice.css';
import { FROM_VALUE } from './InvoiceConstant';
export function Ledger() {
  // hooks Section
  const nav = useNavigate();
  const [exportModal, setExportModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // for export
  const [responseData, setResponseData] = useState({});
  const [paginationActivate, setPaginationActivate] = useState(false);

  const [selectedClient, setSelectedClient] = useState();

  const { getMenuLabelName, globalSettings, roleBasedPermission, makeAlert } =
    useContext(GlobalContext);
  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  const TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <InvoiceIcon height={15} width={16} primeColor='var(--second-text-color)' /> Item
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },

    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Due Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' />
          Debit
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' />
          Credit
        </div>
      ),
      minWidth: 50,
    },
  ];

  const downloadFile = async (fileUrl, fileName) => {
    try {
      if (!fileUrl) throw new Error('Invalid file URL response');

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error.message);
    }
  };

  const handleDownload = async (data) => {
    try {
      data.pageNo = data.pageNumber || 1;
      data.noOfItems = 30;
      data.firstExport = !data.pageNumber;

      let response;
      let fileExtension;

      if (data.type === 'pdf') {
        response = await invoiceService.ledgerDownloadPdf(data);
        fileExtension = 'pdf';
      } else {
        response = await invoiceService.ledgerDownloadExcel(data);
        fileExtension = 'xlsx';
      }

      if (response?.error_code === 69) {
        makeAlert(response?.message);
        setPaginationActivate(true);
        setResponseData(response.data);
        return;
      }

      const fileUrl = response?.data?.url;
      const timestamp = new Date().toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0];

      const fileName = `${data?.fileName || `ledger_${timestamp}`}.${fileExtension}`;

      await downloadFile(fileUrl, fileName);
    } catch (error) {
      console.error('Download failed:', error.message);
    }
  };

  // --react query fot mutation function
  const {
    mutate: handleMutate,
    error: mutateError,
    isError,
    isPending,
  } = useMutation({
    mutationFn: handleDownload,
  });

  // reference section
  const tableContainerRef = useRef();

  // useState section
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  // noaa
  const debouncedSearch = useDebounce(query?.searchKey, 500);

  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const client_id = searchParams.get('client_id');

  const fetchClient = async () => {
    const data = await clientService.clientGet({
      id: client_id,
      select: ['id', 'name', 'currency'],
    });
    setSelectedClient(data?.data?.[0]);

    return data;
  };

  // fetch client details
  // eslint-disable-next-line no-unused-vars
  console.log('checking');
  const { data: client } = useQuery({
    queryKey: ['client-data'],
    queryFn: () => fetchClient(),
    enabled: Boolean(client_id),
  });

  const {
    data: ledgerData,
    isLoading,
    isSuccess,
    error,
  } = useQuery({
    queryKey: [
      'ledger-list',
      query?.pageVo?.pageNo,
      debouncedSearch,
      client_id,
      startDate,
      endDate,
    ],
    queryFn: () => invoiceService.ledgerList({ ...query?.pageVo, startDate, endDate, client_id }),
    enabled: roleBasedPermission?.ACTION_MANAGE_INVOICE,
    // placeholderData: keepPreviousData,
  });
  // NO ACCESS MANAGE
  if (!roleBasedPermission?.ACTION_MANAGE_INVOICE) {
    return <NoPermission />;
  }

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  // get the count of project
  const dataLength = ledgerData?.data?.length;
  const listItemCount = ledgerData?.data?.data?.length;
  const ledgerInfo = ledgerData?.data;
  const tableData = ledgerInfo?.data?.map((item, index) => {
    const slNo = (ledgerInfo?.page - 1) * ledgerInfo?.limit + index + 1;
    const isInvoice = item?.type === 'invoice';
    const formattedDate = new Date(item?.date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    return (
      <Tr key={item?.id}>
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td className='cursor-pointer table-responsive-td' style={{ fontWeight: 500 }}>
          {item?.invoice_number}
        </Td>
        <Td>{formattedDate}</Td>
        <Td>
          {isInvoice ? (
            <>
              {selectedClient?.currency
                ? currencyType.find((currency) => currency.id === selectedClient.currency)?.textIcon
                : currencyTextIcon}
              {item?.amount}
            </>
          ) : (
            '--'
          )}
        </Td>
        <Td>
          {!isInvoice ? (
            <>
              {selectedClient?.currency
                ? currencyType.find((currency) => currency.id === selectedClient.currency)?.textIcon
                : currencyTextIcon}{' '}
              {item?.amount}
            </>
          ) : (
            '--'
          )}
        </Td>
      </Tr>
    );
  });

  // handle to set params like user_id
  const handleSetSearchParams = (name, value) => {
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (value) {
      updatedSearchParams.set(`${name}`, value);
    } else {
      updatedSearchParams.delete(name);
    }
    setSearchParams(updatedSearchParams);
  };

  // handle change of date
  const handleDateChange = (dates) => {
    const startDate = moment(dates.startDate).startOf('day');
    const endDate = moment(dates.endDate).endOf('day');
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('startDate', startDate.format('YYYY-MM-DD'));
    updatedSearchParams.set('endDate', endDate.format('YYYY-MM-DD'));
    updatedSearchParams.set('pageNo', 1);
    setQuery({
      ...query,
      pageVo: { ...query.pageVo, pageNo: 1 },
    });
    setSearchParams(updatedSearchParams);
  };

  const balanceAmount = (
    (parseFloat(ledgerData?.data?.fullyInvoiceAmount) || 0) -
    (parseFloat(ledgerData?.data?.fullyPaymentAmount) || 0)
  ).toFixed(2);

  const handleExport = (data) => {
    if (parseInt(dataLength) === 0) {
      makeAlert('No data found', 'error');
      return;
    }

    handleMutate({
      ...query?.pageVo,
      ...data,
      startDate: data.startDate ? data.startDate : startDate,
      endDate: data.endDate ? data.endDate : endDate,
      client_id,
      fileName: data?.fileName,
      type: data?.selectedFormat,
      menuItems: data?.menuItems,
    });
  };
  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={LABEL_OF_CLIENT}
            fontWeight={400}
            icon={<InvoiceIcon height={12} width={12} />}
            onClick={() => nav(`/${RT.INVOICE_CLIENT_LIST}?status=done`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Report'}
            fontWeight={500}
            icon={<ReceiptText size={13} />}
            // onClick={() => nav(`/${RT.LEDGER}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        <ErrorComponent error={error?.message} />
        <div className='project-table-container'>
          {/* header input section */}
          <section>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='report-filter'>
                <div style={{ width: 'fit-content' }}>
                  <SearchableInfiniteQuerySelect
                    name='client_id'
                    getOptionLabel={(option) => {
                      return option?.name;
                    }}
                    getOptionValue={(option) => {
                      return option?.id;
                    }}
                    queryFn={(params, page) =>
                      clientService.clientList({
                        ...params,
                        select: ['name', 'id', 'currency'],
                        pageVo: {
                          pageNo: page,
                          noOfItems: 10,
                        },
                      })
                    }
                    queryKey={['client-list-task']}
                    enableCondition={true}
                    isCustomFunction
                    getFunctionParams={(searchKey) => {
                      return {
                        searchKey: searchKey,
                        select: ['id', 'name', 'currency'],
                      };
                    }}
                    placeholder={`Select ${LABEL_OF_CLIENT}`}
                    styles={CommonDropDownStyle}
                    components={{
                      SingleValue: (props) => (
                        <CustomSingleValue
                          {...props}
                          icon={<ClientIcon height={18} width={17} color='#07082F' />}
                        />
                      ),
                      DropdownIndicator: CustomDropdownIndicator,
                    }}
                    value={selectedClient}
                    onChange={(selectedOption) => {
                      setSelectedClient(selectedOption);
                      handleSetSearchParams('client_id', selectedOption?.id);
                    }}
                  />
                </div>
                <DatePicker
                  inputContainerClass={'date-picker fit-width'}
                  isDatePrefixNeed={false}
                  onDateChange={handleDateChange}
                  initialStartDate={moment(searchParams.get('startDate')).valueOf()}
                  initialEndDate={moment(searchParams.get('endDate')).valueOf()}
                  isEditable
                  monthFormat='short'
                />
              </div>
              <div className='d-flex gap-2'>
                {/* <Button.Container
                  className='btn d-flex align-items-center gap-1 fz-13px'
                  handleOk={() => downloadInvoice('excel', dataLength)}
                  disabled={isExporting} // Disable button while exporting
                >
                  <Button.Title
                    className={'export-btn-text'}
                    title={isExporting ? 'Exporting...' : ' Excel'}
                  />

                  <Download />
                </Button.Container>
                <Button.Container
                  className='btn d-flex align-items-center gap-1 fz-13px'
                  handleOk={() => downloadInvoice('pdf', dataLength)}
                  disabled={pdfDownloading} // Disable button while exporting
                >
                  <Button.Title
                    className={'export-btn-text'}
                    title={pdfDownloading ? 'Downloading...' : ' PDF'}
                  />

                  <Download />
                </Button.Container> */}

                <Button.Container
                  className='btn d-flex align-items-center gap-1 fz-13px'
                  handleOk={() => setExportModal(true)}
                  disabled={isPending} // Disable button while exporting
                >
                  <Button.Title className={'export-btn-text'} title={'Export'} />

                  <Download />
                </Button.Container>
              </div>
            </div>

            <div className='tab_contain justify-content-end gap-4' style={{ fontWeight: 600 }}>
              {/* <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              /> */}
              <div>
                <div> Total Amount </div>
                <div>
                  {' '}
                  {selectedClient?.currency
                    ? currencyType.find((currency) => currency.id === selectedClient.currency)
                        ?.textIcon
                    : currencyTextIcon}
                  {ledgerData?.data?.fullyInvoiceAmount ?? 0}
                </div>
              </div>
              <div>
                <div>Due Amount </div>
                <div style={{ color: balanceAmount > 0 ? 'red' : 'green' }}>{balanceAmount}</div>
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: heading.maxWidth,
                            color: 'var(--second-text-color)',
                          }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={9}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={9}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={ledgerData?.data}
              />
            </section>
          )}
        </div>
      </div>

      {exportModal && (
        <Modal.Container handleClose={() => setExportModal(false)}>
          <Modal.View className={'modal-view-trigger '} containerClass={'view-end'}>
            <Modal.Head
              Heading={'Export'}
              handleClose={() => {
                setExportModal(false);
              }}
            />
            <Export
              from={FROM_VALUE.LEDGER}
              handleClose={() => setExportModal(false)}
              handleOk={(data) => handleExport(data)}
              Loading={isPending}
              durationEnabled
              setDefaultDuration
              paginationActive={paginationActivate}
              setPaginationActivate={setPaginationActivate}
              responseData={responseData}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}
