/* eslint-disable no-unused-vars */
import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const invoiceService = {
  invoiceAdd,
  invoiceList,
  invoiceGet,
  invoiceDetailsGet,
  invoiceDeactivate,
  invoiceDownload,
  invoiceGenerateBulkInvoice,
  ledgerList,
  ledgerDownloadExcel,
  ledgerDownloadPdf,
  InvoiceDownloadExcel,
};

async function invoiceList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/invoice_list', requestOptions).then(handleResponse);
}

function invoiceGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_invoice?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
function invoiceDetailsGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get-invoice-details?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function invoiceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invoice_add', requestOptions).then(handleResponse);
}
function invoiceDownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invoice-download', requestOptions).then(handleResponse);
}

async function invoiceDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/invoice_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function invoiceGenerateBulkInvoice(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/generate-bulk-invoice', requestOptions).then(handleResponse);
}
function ledgerList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get-ledger-list', requestOptions).then(handleResponse);
}

function _ledgerDownloadExcel(data) {
  const apiUrl = `${apiConstants.apiUrl}/download-ledger-excel`;

  return fetch(apiUrl, {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to download file');
    }
    return response.blob(); // Return Blob directly
  });
}

function _ledgerDownloadPdf(data) {
  const apiUrl = `${apiConstants.apiUrl}/download-ledger-pdf`;

  return fetch(apiUrl, {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      throw new Error('Failed to download file');
    }
    return response.blob(); // Return Blob directly
  });
}

function ledgerDownloadPdf(data) {
  // const apiUrl = `${apiConstants.apiUrl}/download-ledger-excel`;
  const apiUrl = `${apiConstants.apiUrl}/download-ledger-pdf`;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
function ledgerDownloadExcel(data) {
  const apiUrl = `${apiConstants.apiUrl}/download-ledger-excel`;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

function InvoiceDownloadExcel(data) {
  const apiUrl = `${apiConstants.apiUrl}/download-invoice-excel`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}
