/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { ChevronRight, Eye, EyeOff } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { Button } from '../_components';
import { logout } from '../_helpers';
import { authService, userService } from '../_services';
import { useUser } from '../Hook/useUser';
import { GlobalContext } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export const ProfileSecurity = () => {
  const { makeAlert } = useContext(GlobalContext);

  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [passwordEditActive, setPasswordEditActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [userData, setUserData] = useState({});
  let currentUserLoginId = useUser()?.user_login_id;

  const fetchUserCredential = async () => {
    const data = await userService.userLoginGet({
      id: currentUserLoginId,
    });
    setUserData(data?.data);
    return data?.data;
  };

  const { data: userLoginData, refetch: userLoginRefetch } = useQuery({
    queryKey: ['user-login'],
    queryFn: () => fetchUserCredential(),
    select: (data) => data,
    // placeholderData: keepPreviousData,
  });

  const handleChange = (event) => {
    if (!editActive) {
      setEditActive(true);
    }
    const { name, value } = event.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // save api call
  const handleSave = async () => {
    // await userService.updateProfile(
    //   localData?.dob
    //     ? { ...localData, dob: new Date(localData?.dob).getTime(), id: userProfileData?.id }
    //     : { ...localData, id: userProfileData?.id },
    // );
    if (userLoginData?.username !== userData?.username) {
      const res = await userService.updateLoginDetails({
        id: currentUserLoginId,
        username: userData?.username,
      });
      if (res.error_code == 0) {
        userLoginRefetch();
        makeAlert('Success');
      }
    }
    if (passwordEditActive) {
      const reponse = await authService.changePassword({
        current_password: userData?.current_password,
        password: userData?.password,
        confirm_password: userData?.confirm_password,
      });
      logout();
    }
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    // error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['profile-update'],
    onSuccess: (data) => {
      setEditActive(false);
      userLoginRefetch();
      makeAlert('User credential updated successfully');
    },
    onError: (err) => {
      if (passwordEditActive) {
        setError(err?.message);
      }
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (userData?.username?.toString()?.trim() == '') {
      setError('Invalid username');
      setIsLoading(false);
      return;
    }
    if (
      passwordEditActive &&
      (!userData?.current_password?.toString()?.trim() ||
        !userData?.password?.toString()?.trim() ||
        !userData?.confirm_password?.toString()?.trim())
    ) {
      setError('Please complete password change process');
      setIsLoading(false);
      return;
    }

    if (
      passwordEditActive &&
      userData?.password?.toString().trim() !== userData?.confirm_password?.toString()?.trim()
    ) {
      setIsLoading(false);
      setError('Confirm Passwords does not match');
      return;
    }
    setTimeout(() => {
      setError('');
    }, 3000);

    // if (
    //   // invoiceItemData?.length > 0 &&
    //   // taskListData?.some((item) => !item?.amount)
    //   taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0) <= 0
    // ) {
    //   // console.log('test');

    //   setIsLoading(false);
    //   makeAlert('Please enter valid amount');
    //   return null;
    // }

    try {
      handleMutate();
    } catch (error) {
      makeAlert('Update failed');
    } finally {
      setIsLoading(false);
      // setSubmitted(false);
    }
  };
  return (
    <div style={{ padding: 16 }}>
      <section>
        <div className='profile-head'>Security</div>
        <div className='profile-description'>Manage your personal details</div>
      </section>

      <div className='common-separation my-3 ' />
      <section className=''>
        <div style={{ fontWeight: 600, fontSize: 14 }}>Username</div>
        <div style={{ fontWeight: 500, color: 'var(--second-text-color)', marginBottom: 10 }}>
          Manage your username
        </div>

        <div className='col-6'>
          <label className='mb-1 fz-13px-rem  label_input_gray'> Username</label>
          <input
            type='text'
            className='input-box'
            name='username'
            value={userData?.username}
            placeholder={`Enter username`}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </div>
      </section>
      <div className='common-separation my-3' />

      {!passwordEditActive && (
        <section className='d-flex justify-content-between align-items-center'>
          <div>
            <div style={{ fontWeight: 600, fontSize: 14 }}>Password</div>
            <div style={{ fontWeight: 500, color: 'var(--second-text-color)', marginBottom: 10 }}>
              Change the password for your account
            </div>
          </div>
          <div
            style={{ color: 'var(--Base-Color)', cursor: 'pointer' }}
            onClick={() => setPasswordEditActive(true)}
          >
            Change Password <ChevronRight size={15} />
          </div>
        </section>
      )}
      {passwordEditActive && (
        <section>
          <div style={{ fontWeight: 600, fontSize: 14 }}>Change Password</div>
          <div style={{ fontWeight: 500, color: 'var(--second-text-color)', marginBottom: 10 }}>
            Change the password for your account
          </div>
          <div>
            <label className=' fz-13px-rem  label_input_gray' htmlFor='current_password'>
              Current Password<span className='field-required'> *</span>
            </label>
            <input
              style={{ fontSize: '13px' }}
              type='text'
              className={`input-box mt-2
                  
                `}
              id='current_password'
              name='current_password'
              placeholder='Current Password'
              onChange={handleChange}
              // value={userData?.password}
            />
            {/* ${
                    submitted && password?.current_password.toString().trim() == ''
                      ? 'has-error'
                      : ''
                  } */}
          </div>
          <div>
            <label className='fz-13px-rem  label_input_gray' htmlFor='password'>
              New Password<span className='field-required'> *</span>
            </label>
            <div style={{ position: 'relative' }}>
              <input
                style={{ fontSize: '13px' }}
                type={isPasswordVisible ? 'text' : 'password'}
                className={`input-box mt-2
                  `}
                id='password'
                name='password'
                placeholder='New Password'
                onChange={handleChange}
                autoComplete='off'
                // value={userData?.password}
              />
              {/* ${submitted && password?.password.toString().trim() == '' ? 'has-error' : ''} */}

              <a className='password-icon' onClick={() => setIsPasswordVisible((prev) => !prev)}>
                {isPasswordVisible ? (
                  <Eye width={18} height={18} />
                ) : (
                  <EyeOff width={18} height={18} />
                )}
              </a>
            </div>
          </div>
          <div>
            <label className=' fz-13px-rem  label_input_gray' htmlFor='confirm_password'>
              Password<span className='field-required'> *</span>
            </label>
            <div style={{ position: 'relative' }}>
              <input
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                className={`input-box mt-2
                  
                  `}
                id='confirm_password'
                name='confirm_password'
                placeholder='Confirm Password'
                onChange={handleChange}
                autoComplete='off'
              />
              {/* ${
                      submitted && password?.confirm_password.toString().trim() == ''
                        ? 'has-error'
                        : ''
                    } */}
              <a
                className='password-icon'
                onClick={() => setIsConfirmPasswordVisible((prev) => !prev)}
              >
                {isConfirmPasswordVisible ? (
                  <Eye width={18} height={18} />
                ) : (
                  <EyeOff width={18} height={18} />
                )}
              </a>
            </div>
          </div>
        </section>
      )}
      <div className='d-flex error-txt'>{error}</div>
      {(editActive || passwordEditActive) && (
        <div className=' d-flex justify-content-end gap-2 mt-4'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              setEditActive(false);
              setPasswordEditActive(false);
              setIsConfirmPasswordVisible(false);
              setUserData(userLoginData);
              setIsLoading(false);
              setError('');
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container type='submit' handleOk={handleSubmit} isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Update'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      )}
    </div>
  );
};
