/* eslint-disable no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import NoPermission from '../_components/NoPermission';
import { notificationService } from '../_services/notification.service';
import { NOTIFICATION_FROM } from '../Notification/notificationConstant';
import { jsonToUrlEncoded } from '../Utils';
import { NotificationItem } from './NotificationItem';

export const NotificationAll = () => {
  const pathName = window.location.pathname;

  const { isUnRead } = useOutletContext();

  console.log('isUnRead', isUnRead);

  //-- for all notification
  const [notificationQuery, setNotificationQuery] = useState({
    select: [
      'id',
      'note',
      'task_id',
      'reminder_id',
      'request_id',
      'user_id',
      'is_read',
      'mode',
      'message_id',
      'notification_type',
      'updated_time',
      'chat_group_id',
      'project_id',
      'team_id',
    ],
    notification_from: NOTIFICATION_FROM.ALL_NOTIFICATION,
    isUnReadFlag: isUnRead,
  });

  const [urlQuery, setULRQuery] = useState();

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const {
    data: notificationData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  } = useInfiniteQuery({
    queryKey: ['notification-list-all', notificationQuery, isUnRead],
    queryFn: ({ pageParam = 1 }) =>
      notificationService.notificationList(
        getPayLoad({
          ...notificationQuery,
          isUnReadFlag: isUnRead,
          notification_from: NOTIFICATION_FROM.ALL_NOTIFICATION,

          pageVo: {
            pageNo: pageParam,
            noOfItems: 30,
            sort: 'id',
            sort_order: 'DESC',
          },
        }),
      ),
    select: (data) => data.pages,
    getNextPageParam: (res) => (res.data.page < res.data.pages ? res.data.page + 1 : undefined),
  });

  const allNotifications = notificationData?.flatMap((page) => page.data.rows) || [];
  console.log('notificationData', allNotifications?.length);

  const Row = ({ index, style }) => (
    <div style={style}>
      <NotificationItem data={allNotifications[index]} pathName={pathName} />
    </div>
  );

  if (isPending) {
    return <section className='notification-container'>Loading...</section>;
  }

  return (
    <section className='notification-container'>
      {allNotifications?.length ? (
        <section>
          <List
            style={{ height: '77vh' }}
            onItemsRendered={({ visibleStopIndex }) => {
              if (
                visibleStopIndex >= allNotifications.length - 1 &&
                hasNextPage &&
                !isFetchingNextPage
              ) {
                console.log('Fetching next page...');
                fetchNextPage();
              }
            }}
            height={650}
            itemCount={allNotifications.length}
            itemSize={74}
            width={'100%'}
          >
            {Row}
          </List>
        </section>
      ) : (
        <NoPermission data='No notifications at the moment. Check back later!' />
      )}
    </section>
  );
};
