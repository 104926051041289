/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState } from 'react';
import useObserver from '../Hook/useObserver';
import { GlobalContext, formatMessageDate } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import useAlert from '../_components/ActionPopup/useAlert';
import { MESSAGE_TYPES } from '../_constants/chat.constants';
import ChatContextMenu from './ChatMenu/ChatContextMenu';
import { ChatMenu } from './ChatMenu/ChatMenu';
import MediaPreview from './MediaPreview';
import MessageItem from './MessageItem';

const MessagesList = ({
  deleteFromUseQuery,
  recoverMessageFromUseQuery,
  chatList,
  isFetchingNextPage,
  tempMessages,
  listBottomRef,
  listClassName = '',
  messageListRef,
  onDelete = () => {},
  onTopReached = () => {},
  onReactionUpdate = () => {},
  onReplySelect = () => {},
  onRetry = () => {},
  isInTask,
}) => {
  const { makeAlert } = useContext(GlobalContext);
  const { makeActionAlert } = useAlert();

  const [isContextVisible, setIsContextVisible] = useState(false);
  const [reactionVisibleIndex, setReactionVisibleIndex] = useState(false);
  const [infoVisibleIndex, setInfoVisibleIndex] = useState(null);
  const [forwardVisibleIndex, setForwardVisibleIndex] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState({ x: 0, y: 0, item: null });
  const [previewIndex, setPreviewIndex] = useState({
    isVisible: false,
    fileIndex: null,
    item: null,
  });

  const replyMessageRef = useRef({});
  // For handling the next page call on scroll. Using intersection observer for that
  const { observerRef: loadMoreRef } = useObserver({
    onIntersection: () => {
      onTopReached();
    },
  });

  const allChatItems = React.useMemo(() => chatList?.flatMap((page) => page.data.rows), [chatList]);

  const onContextMenuClose = () => {
    setIsContextVisible(false);
    setSelectedPosition({ x: 0, y: 0, item: null });
    // setInfoVisibleIndex(null);
  };

  const updatedChatList = [...tempMessages, ...(allChatItems ?? [])];
  const handleScroll = () => {
    if (isContextVisible) setIsContextVisible(false);
    if (infoVisibleIndex) {
      setInfoVisibleIndex(null);
    }
  };

  return (
    <>
      <div className={listClassName} ref={messageListRef} onScroll={handleScroll}>
        <div ref={listBottomRef} />

        {updatedChatList.map((item, index, pageList) => {
          const messageDate = formatMessageDate(item.createdAt);
          const showDate =
            index === pageList.length - 1 ||
            formatMessageDate(pageList[index + 1].createdAt) !== messageDate;
          // For consecutive msgs, the username will be hidden
          const isConsecutiveUserMsg =
            !showDate &&
            index < pageList.length - 1 &&
            pageList.filter((item) => item.type !== MESSAGE_TYPES.CHAT_CREATION)?.[index + 1]
              ?.creator_details.id === item?.creator_details.id;

          // Grouped props
          const dataProps = {
            item,
            index,
            showDate,
            messageDate,
            isConsecutiveUserMsg,
            isInTask,
          };

          const stateProps = {
            reactionIndex: reactionVisibleIndex,
            selectedPosition,
            infoVisibleIndex,
            forwardVisibleIndex,
          };

          const handlerProps = {
            onRetry,
            setIsContextVisible,
            setPreviewIndex,
            setSelectedPosition,
            onReactionUpdate,
            onReactionOutsideClick: () => {
              setReactionVisibleIndex(null);
            },
            onInfoOutsideClick: () => {
              setInfoVisibleIndex(null);
            },
            onForwardOutsideClick: () => {
              setForwardVisibleIndex(null);
            },
          };

          const refProps = {
            replyMessageRef,
            messageListRef,
          };

          return (
            <>
              <MessageItem
                data={dataProps}
                state={stateProps}
                handlers={handlerProps}
                refs={refProps}
                key={item.tempId ?? item.id}
              />
            </>
          );
        })}
        {previewIndex.isVisible && (
          <MediaPreview
            isInput={false}
            createdTime={previewIndex?.item?.createdAt}
            creatorDetails={{
              ...previewIndex.item.creator_details,
              createdAt: previewIndex.item.createdAt,
            }}
            files={previewIndex.item.files}
            isLocal={Boolean(previewIndex.item?.tempId)}
            currentIndex={previewIndex.fileIndex}
            onClose={() => setPreviewIndex({ isVisible: false, fileIndex: null, item: null })}
          />
        )}

        <div ref={loadMoreRef} className='w-100 d-flex justify-content-center'>
          <NextPageLoader isFetchingNextPage={isFetchingNextPage} />
        </div>

        {/* Chat context menu */}
        <ChatContextMenu
          isVisible={isContextVisible}
          onClose={() => {
            setSelectedPosition({ x: 0, y: 0, item: null });
            setIsContextVisible(false);
          }}
          selectedPosition={selectedPosition}
        >
          <ChatMenu.DeleteMenuOption
            deleteFromUseQuery={deleteFromUseQuery}
            makeActionAlert={makeActionAlert}
            onClose={onContextMenuClose}
            onDelete={onDelete}
            recoverMessageFromUseQuery={recoverMessageFromUseQuery}
            selectedPosition={selectedPosition}
          />
          <ChatMenu.ReplyMenuOption
            onClose={onContextMenuClose}
            onReplySelect={onReplySelect}
            selectedPosition={selectedPosition}
          />
          {Boolean(selectedPosition?.item?.message) && (
            <ChatMenu.CopyMenuOption
              makeAlert={makeAlert}
              onClose={onContextMenuClose}
              selectedPosition={selectedPosition}
            />
          )}
          <ChatMenu.ReactionMenuOption
            onClose={onContextMenuClose}
            selectedPosition={selectedPosition}
            setReactionVisibleIndex={setReactionVisibleIndex}
          />
          <ChatMenu.InfoMenuOption
            onClose={onContextMenuClose}
            selectedPosition={selectedPosition}
            setInfoVisibleIndex={setInfoVisibleIndex}
          />

          <ChatMenu.ForwardMenuOption
            onClose={onContextMenuClose}
            selectedPosition={selectedPosition}
            setForwardVisibleIndex={setForwardVisibleIndex}
          />

          {/* <ChatMessageCards.DefaultReactions isVisible /> */}
        </ChatContextMenu>
      </div>
    </>
  );
};

const NextPageLoader = ({ isFetchingNextPage = false }) => {
  if (isFetchingNextPage) {
    return (
      <div className='m-auto' style={{ height: 30 }}>
        <LoadingIcon size={40} />
      </div>
    );
  }
  return <div style={{ height: 30 }} className='w-100' />;
};

export default MessagesList;
