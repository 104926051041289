/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Trash2 } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext, InvoiceState } from '../Utils';
import { Cancel, LoadingIcon, PaymentIcon2, Tick, TotalTaskIcon } from '../Utils/SvgIcons';
import { Button, Modal, TableRow } from '../_components';
import { RT } from '../_constants';
import { taskService } from '../_services';
import { invoiceService } from '../_services/invoice.service';
import { AddInvoice } from './AddInvoice';
import { TAX_CONST, TRANSACTION_TYPE } from './InvoiceConstant';
import { calculateInvoiceAmount, calculateTaxAndAmount } from './InvoiceHelperFunction';
export const BillDetails = ({
  id = null,
  handleClose = () => {},
  searchParams,
  filter,
  billDetails,
}) => {
  const { globalSettings, makeAlert } = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [invoiceAddModal, setInvoiceAddModal] = useState(false);
  const nav = useNavigate();
  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const invoiceNumberOfDigits = parseInt(invoiceSettings?.labels?.numberOfDigits) || 1; // Default to 0 if invoiceNumberOfDigits is not available
  const paddedInvoiceNumber = String(invoiceSettings?.labels?.invoiceNumber).padStart(
    invoiceNumberOfDigits,
    '0',
  );

  const selectedCurrency = invoiceSettings?.labels?.currency;
  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  const [query, setQuery] = useState({
    select: ['id', 'name', 'task_start_date'],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 100,
    },
    action: 'billing',
  });

  const [taskListData, setTaskListData] = useState([]);

  const fetchTaskList = async () => {
    const { status, ...restFilter } = filter || {}; // Remove status from filter
    const data = await taskService.taskListAdvanced({
      ...query,
      ...restFilter, // Spread the rest of the filter properties without status
      client_id: [billDetails?.id],
      is_duration_required: true,
      ...(status ? { default_name: status } : {}), // Add status separately if it exists
      // is_bill_generated: 1,
    });

    setTaskListData(data?.data?.rows);
    return data;
  };

  // to get clients using react query
  const {
    data: taskList,
    isLoading: taskLoading, // Renamed for clarity
    isSuccess,
  } = useQuery({
    queryKey: ['task-list-client', filter],
    queryFn: fetchTaskList,
    select: (data) => data?.data,
  });

  // const handleChange = () => {};

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedItems = [...taskListData];

    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };

    setTaskListData(updatedItems);
  };
  // to manage amount input
  const isValidAdjustment = (value) => {
    return /^-?\d*\.?\d*$/.test(value); // Allows numbers, optional negative sign, and decimals
  };

  const handleAdjustmentBlur = (event, index) => {
    const { name, value } = event.target;
    console.log('coooo', isValidAdjustment(value), value);

    if (
      !isValidAdjustment(value) ||
      value === '-' ||
      value === '.' ||
      value === '-.' ||
      value === ''
    ) {
      const updatedItems = [...taskListData];

      updatedItems[index] = {
        ...updatedItems[index],
        [name]: 0,
      };

      setTaskListData(updatedItems);
    }
  };

  const handleDelete = (deleteIndex) => {
    setTaskListData(taskListData?.filter((_, index) => index !== deleteIndex));
  };

  // save api call
  const handleSave = async () => {
    await invoiceService
      .invoiceGenerateBulkInvoice({
        client_id: billDetails?.id,
        taskData: taskListData,
      })
      .then(() => {
        setSubmitted(false);
        // afterAdd();
      });
    handleClose();
  };

  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['invoice-bill-add'],
    onSuccess: (data) => {
      setSubmitted(false);
      // globalSettingsRefetch();
      // afterAdd();
      handleClose();
    },
    onError: (err) => {
      setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);

    if (
      // invoiceItemData?.length > 0 &&
      // taskListData?.some((item) => !item?.amount)
      taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0) <= 0
    ) {
      // console.log('test');

      setIsLoading(false);
      makeAlert('Please enter valid amount');
      return null;
    }

    try {
      const itemDetailsList = taskListData?.map((tsk) => {
        console.log(tsk, 'ttttt');
        const data = {
          name: tsk.name,
          task_id: tsk.id,
          quantity: 1,
          rate: tsk?.amount,
          default_tax: null,
          item_id: null,
        };
        const newData = calculateTaxAndAmount(
          data,
          billDetails?.tax_treatment?.tax_not_applicable
            ? TAX_CONST.TAX_EXEMPT
            : invoiceSettings?.labels?.defaultTax ?? null,
          false,
        );
        return newData;
      });

      const updateInvoiceData = calculateInvoiceAmount(
        {
          ...InvoiceState,
          client_id: {
            name: billDetails?.name,
            id: billDetails?.id,
            currency: billDetails?.currency,
            tax_treatment: billDetails?.tax_treatment,
            tax_number: billDetails?.tax_number,
          },
          // transaction_type: TRANSACTION_TYPE.INVOICE,
          // tax_info: null,
          tax: billDetails?.tax_treatment?.tax_not_applicable
            ? TAX_CONST.TAX_EXEMPT
            : invoiceSettings?.labels?.defaultTax ?? 1,
          // tax_type: invoiceSettings?.labels?.taxType,
          // terms_and_conditions: invoiceSettings?.labels?.termsCondition,
          // invoice_number:
          //   invoiceSettings?.labels?.invoicePrefix?.toUpperCase() + paddedInvoiceNumber,
          total: taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0),
          sub_total: taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0),
        },
        {
          companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
        },
        itemDetailsList,
        makeAlert,
      );

      // handleMutate();
      // const invoiceDetails = {
      //   client_id: {
      //     name: billDetails?.name,
      //     id: billDetails?.id,
      //     currency: billDetails?.currency,
      //   },
      //   total: taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0),
      //   sub_total: taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0),
      // };
      setInvoiceAddModal(true);
    } catch (error) {
      makeAlert('Generation failed');
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  return (
    <>
      <div className='modal_view_content'>
        <div className='d-flex justify-content-between my-2'>
          <span style={{ fontWeight: 500, fontSize: 15 }}>{billDetails?.name?.toUpperCase()}</span>
          <span>
            <span style={{ color: 'var(--second-text-color)' }}>Total Amount : </span>
            <span style={{ fontWeight: 500 }}>
              {billDetails?.currency
                ? currencyType.find((currency) => currency.id === billDetails.currency)?.textIcon
                : currencyTextIcon}
              {taskListData?.reduce((acc, task) => acc + (parseFloat(task?.amount) || 0), 0)}
            </span>
          </span>
        </div>

        <div className='task-count-bill-details-container'>
          <span className='task-count-bill-details'>
            <span>
              <TotalTaskIcon />
              <span className='ms-1'>Total Billable Tasks</span>
            </span>
            <span>{billDetails?.total_billable_task_count}</span>
          </span>
          <span className='task-count-bill-details' style={{ border: 'none' }}>
            <span>
              <PaymentIcon2 />
              <span className='ms-1'>Total UnBilled tasks</span>
            </span>
            <span>{taskList?.count}</span>
          </span>
        </div>

        <div className='common-separation my-3' />

        <section>
          <Table className='table tbl mb-0'>
            <Thead>
              <Tr
                className='table-head no-first-th'
                style={{ height: '40px', position: 'inherit' }}
              >
                <Th style={{ width: 'auto' }}>Tasks</Th>
                <Th>Date</Th>
                <Th>Amount</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody style={{ verticalAlign: 'middle' }}>
              {isSuccess &&
                taskListData?.map((task, index) => {
                  return (
                    <Tr key={index} className='no-hover'>
                      <Td>
                        <div>{task?.name}</div>
                        <div className='fz-12px'>( {task?.status_name} )</div>
                      </Td>
                      <Td>{moment(parseInt(task?.task_start_date)).format('MMM DD, YYYY')}</Td>
                      <Td className='d-flex align-items-center'>
                        {billDetails?.currency
                          ? currencyType.find((currency) => currency.id === billDetails.currency)
                              ?.textIcon
                          : currencyTextIcon}
                        <input
                          type='text'
                          className='input-box w-fit ms-1'
                          name='amount'
                          value={task?.amount}
                          onChange={(e) => {
                            // Allow only numbers and at most one decimal point
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value) || value === '') {
                              handleChange(e, index);
                            }
                          }}
                          onBlur={(e) => handleAdjustmentBlur(e, index)}
                        />
                      </Td>
                      <Td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        <span className='cursor-pointer' onClick={() => handleDelete(index)}>
                          <Trash2 size={16} stroke='var(--second-text-color)' />
                        </span>
                      </Td>
                    </Tr>
                  );
                })}

              {taskLoading && (
                <TableRow noOfCols={9}>
                  <LoadingIcon size={60} />
                </TableRow>
              )}
            </Tbody>
          </Table>
        </section>
      </div>
      <div className='modal_footer'>
        <div className=' d-flex gap-2'>
          <Button.Container
            className={'cancel-btn'}
            type='reset'
            handleOk={() => {
              handleClose();
            }}
          >
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleSubmit(e)} type='submit' isDisabled={isLoading}>
            <Tick />
            <Button.Title title={'Generate'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </div>

      {invoiceAddModal && (
        <Modal.Container
          handleClose={() => {
            setInvoiceAddModal(false);
          }}
        >
          <Modal.View className={'w-75'}>
            <Modal.Head
              Heading={'Create Invoice'}
              handleClose={() => {
                setInvoiceAddModal(false);
              }}
            />
            <AddInvoice
              transactionType={TRANSACTION_TYPE.INVOICE}
              handleClose={() => setInvoiceAddModal(false)}
              afterAdd={() => nav(`/${RT.INVOICE_LIST}`)}
              invoiceDetails={(() => {
                const invoiceTax = billDetails?.tax_treatment?.tax_not_applicable
                  ? TAX_CONST.TAX_EXEMPT
                  : invoiceSettings?.labels?.defaultTax;

                const itemDetailsList = taskListData?.map((tsk) => {
                  const data = {
                    item_name: tsk.name,
                    task_id: tsk.id,
                    discount: 0,
                    quantity: 1,
                    rate: tsk?.amount,
                    default_tax:
                      invoiceTax !== (TAX_CONST.NOT_SELECTED || TAX_CONST.TAX_EXEMPT)
                        ? invoiceSettings?.labels?.taxGroup
                        : null,
                    item_id: null,
                  };

                  return calculateTaxAndAmount(data, invoiceTax ?? 1, false);
                });

                const updateInvoiceData = calculateInvoiceAmount(
                  {
                    ...InvoiceState,
                    client_id: {
                      name: billDetails?.name,
                      id: billDetails?.id,
                      currency: billDetails?.currency,
                      tax_treatment: billDetails?.tax_treatment,
                      tax_number: billDetails?.tax_number,
                    },
                    tax: invoiceTax ?? 1,
                    total: taskListData?.reduce(
                      (acc, task) => acc + (parseFloat(task?.amount) || 0),
                      0,
                    ),
                    sub_total: taskListData?.reduce(
                      (acc, task) => acc + (parseFloat(task?.amount) || 0),
                      0,
                    ),
                  },
                  {
                    companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
                  },
                  itemDetailsList,
                  makeAlert,
                );

                if (updateInvoiceData.isSuccess) {
                  return { updateInvoiceData: updateInvoiceData.data, itemDetailsList };
                }
              })()}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
};
