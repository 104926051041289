import { useQuery } from '@tanstack/react-query';
import { Plus, Trash2 } from 'lucide-react';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import SearchableInfiniteQuerySelect from '../_components/SearchableInfiniteQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import { CommonDropDownStyle } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { itemService } from '../_services/item.service';
import { taxService } from '../_services/tax.service';
import { GlobalContext } from '../Utils';
import { TAX_CONST, TAX_LIST } from './InvoiceConstant';
import { calculateInvoiceAmount, calculateTaxAndAmount } from './InvoiceHelperFunction';
export const InvoiceAddItemTable = ({
  invoiceData,
  setInvoiceData,
  invoiceItemData,
  handleItemChange,
  handleItemDelete,
  handleNewItem,
  setItemAddModal,
  inputRefs,
  handleChange,
  setInvoiceItemData,
  // setAddTaxModal,
  // calculateTaxAndAmount,
  // calculateInvoiceAmount,
}) => {
  return (
    <div className='invoice-form-section'>
      <div className='d-flex mb-1'>
        <div
          style={{
            display: 'flex',
            'align-items': 'center',
            color: 'var(--second-text-color)',
          }}
        >
          <label>Tax</label>
          <ReactSelect
            isCustomFunction
            options={TAX_LIST}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selectedOption) => {
              handleChange({
                target: { value: parseInt(selectedOption?.id), name: 'tax' },
              });
            }}
            styles={{
              ...CommonDropDownStyle,

              control: (styles, { isFocused, selectProps }) => {
                const hasError = selectProps?.className?.includes('field-error');
                const isDisabled = selectProps?.isDisabled;

                return {
                  ...styles,
                  boxShadow: 'none',
                  border: 'none',
                  backgroundColor: 'none',
                  minHeight: '34px',
                  borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : 'transparent',
                  // boxShadow: 'var(--input-border-shadow)',
                  padding: '0px 1px 0px 4px',
                  // backgroundColor: 'var(--bg-primary)',
                  borderRadius: '8px',
                  cursor: isDisabled ? 'no-drop' : 'default', // Apply cursor style
                  opacity: isDisabled ? 0.6 : 1, // Optional: Make it look visually disabled
                  pointerEvents: isDisabled ? 'auto' : 'auto', // Force pointer events to allow cursor
                };
              },
            }}
            components={{
              DropdownIndicator: CustomDropdownIndicator,
            }}
            placeholder='select'
            value={TAX_LIST?.find((item) => item.id === invoiceData?.tax)}
            isDisabled={!invoiceData?.client_id?.id}
          />
        </div>
        {/* {invoiceData?.tax === TAX_CONST.INCLUSIVE && ( */}
        {/* <div
          style={{
            display: 'flex',
            'align-items': 'center',
            color: 'var(--second-text-color)',
          }}
        >
          <label>Tax Type</label>
          <ReactSelect
            isCustomFunction
            options={EXTRA_TAX_TYPE_LIST}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            onChange={(selectedOption) => {
              handleChange({
                target: { value: parseInt(selectedOption?.id), name: 'tax_type' },
              });
            }}
            styles={{
              ...CommonDropDownStyle,
              control: (style) => ({
                ...style,
                boxShadow: 'none',
                border: 'none',
              }),
            }}
            components={{
              DropdownIndicator: CustomDropdownIndicator,
            }}
            placeholder='select'
            value={EXTRA_TAX_TYPE_LIST?.find((item) => item?.id === invoiceData?.tax_type)}
          />
        </div> */}
        {/* )} */}
      </div>
      <Table className='table tbl mb-0'>
        <Thead>
          <Tr className='table-head no-first-th' style={{ height: '40px', position: 'inherit' }}>
            <Th style={{ width: 'auto' }}>Item Details</Th>
            <Th>Quantity</Th>
            <Th>Rate</Th>
            <Th>Discount</Th>
            {invoiceData?.tax != TAX_CONST.NOT_SELECTED &&
              invoiceData?.tax != TAX_CONST.TAX_EXEMPT && (
                <Th style={{ minWidth: '150px' }}>
                  {/* {invoiceData?.tax_type === TAX_TYPE_CONST.VAT
                    ? 'VAT'
                    : invoiceData?.tax_type === TAX_TYPE_CONST.GST
                    ? 'GST'
                    : 'Other Tax'} */}
                  {/* <div className='d-flex justify-content-between'>
                    <div className='my-1 fz-13px-rem '>Tax</div>
                    <ToolTip tooltipText={`Add Tax`} isModern>
                      <div
                        className='contact-add-icon pointer'
                        onClick={() => setAddTaxModal(true)}
                      >
                        <Plus size={14} />
                      </div>
                    </ToolTip>
                  </div> */}
                  Tax
                </Th>
              )}
            {invoiceData?.tax != TAX_CONST.NOT_SELECTED &&
              invoiceData?.tax != TAX_CONST.TAX_EXEMPT && (
                <Th>
                  {/* {TAX_TYPE_LIST?.find((tax) => tax.id === invoiceData?.tax_type)?.name} Amount */}
                  Tax Value
                </Th>
              )}

            <Th>Amount</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoiceItemData?.map((item, index) => {
            return (
              <Tr key={index} className='no-hover'>
                <Td>
                  <div style={{ position: 'relative' }}>
                    <ItemInput
                      setInvoiceItemData={setInvoiceItemData}
                      invoiceItemData={invoiceItemData}
                      item={item}
                      index={index}
                      invoiceData={invoiceData}
                      calculateTaxAndAmount={calculateTaxAndAmount}
                      // calculateInvoiceAmount={calculateInvoiceAmount}
                      setInvoiceData={setInvoiceData}
                      // handleTotalAmount={handleTotalAmount}
                    />
                  </div>
                </Td>
                <Td>
                  <input
                    type='number'
                    name='quantity'
                    value={item?.quantity}
                    onChange={(e) => handleItemChange(e, index)}
                    min={1}
                    className={`input-box ${!invoiceData?.client_id?.id ? 'cursor-no-drop' : ''}`}
                    disabled={!invoiceData?.client_id?.id}
                  />
                </Td>
                <Td>
                  <input
                    type='text'
                    name='rate'
                    value={item?.rate}
                    onChange={(e) => {
                      // Allow only numbers and at most one decimal point
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) || value === '') {
                        handleItemChange(e, index);
                      }
                    }}
                    className={`input-box ${!invoiceData?.client_id?.id ? 'cursor-no-drop' : ''}`}
                    disabled={!invoiceData?.client_id?.id}
                  />
                </Td>
                <Td>
                  <div className='currency-indicator-input'>
                    <input
                      type='text'
                      name='discount'
                      className={`input-box border-none ${
                        !invoiceData?.client_id?.id ? 'cursor-no-drop' : ''
                      }`}
                      disabled={!invoiceData?.client_id?.id}
                      value={item?.discount}
                      // onChange={(e) => handleItemChange(e, index)}
                      onChange={(e) => {
                        // Allow only numbers and at most one decimal point
                        const value = e.target.value;

                        if (/^\d*\.?\d*$/.test(value) || value === '') {
                          if (value <= 99) handleItemChange(e, index);
                        }
                      }}
                      ref={(el) => (inputRefs.current[index] = el)} // Store reference
                    />
                    <span
                      className='currency-indicator'
                      style={{ border: 'none', cursor: 'pointer' }}
                      onClick={() => inputRefs.current[index]?.focus()} // Focus input
                    >
                      %
                    </span>
                  </div>
                </Td>

                {invoiceData?.tax != TAX_CONST.NOT_SELECTED &&
                  invoiceData?.tax != TAX_CONST.TAX_EXEMPT && (
                    <Td>
                      <SearchableInfiniteQuerySelect
                        name='default_tax'
                        getOptionLabel={(option) => {
                          return `${option?.name} - ${option?.rate}%`;
                        }}
                        getOptionValue={(option) => {
                          return option?.id;
                        }}
                        queryFn={taxService.taxList}
                        queryKey={'tax-list-invoice'}
                        enableCondition={true}
                        isCustomFunction
                        getFunctionParams={(searchKey) => {
                          return {
                            searchKey: searchKey,
                            select: ['id', 'name'],
                          };
                        }}
                        onChange={(selectedOption) => {
                          handleItemChange(
                            {
                              target: { value: selectedOption, name: 'default_tax' },
                            },
                            index,
                          );
                        }}
                        placeholder={`Select `}
                        styles={CommonDropDownStyle}
                        isDisabled={!invoiceData?.client_id?.id}
                        // menuPortalTarget={document.getElementById('MODAL')}
                        value={item?.default_tax}
                      />
                    </Td>
                  )}
                {/* tax amount show  */}
                {invoiceData?.tax != TAX_CONST.NOT_SELECTED &&
                  invoiceData?.tax != TAX_CONST.TAX_EXEMPT && (
                    <Td>
                      <input
                        type='text'
                        className='input-box cursor-no-drop'
                        name='item_tax_amount'
                        value={item?.item_tax_amount}
                        disabled
                      />
                    </Td>
                  )}
                <Td>
                  <input
                    type='text'
                    className='input-box cursor-no-drop'
                    name='amount'
                    value={item?.amount}
                    // onChange={(e) => handleItemChange(e, index)}
                    onChange={(e) => {
                      // Allow only numbers and at most one decimal point
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value) || value === '') {
                        handleItemChange(e, index);
                      }
                    }}
                    disabled
                  />
                </Td>

                <Td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <span
                    // className='cursor-pointer'
                    className={`cursor-pointer ${
                      !invoiceData?.client_id?.id ? 'cursor-no-drop' : ''
                    }`}
                    disabled={!invoiceData?.client_id?.id}
                    onClick={() => handleItemDelete(index)}
                  >
                    <Trash2 size={16} stroke='var(--second-text-color)' />
                  </span>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <span
        style={{ color: 'var(--Base-Color)' }}
        className='cursor-pointer '
        onClick={() => setItemAddModal(true)}
      >
        <Plus size={15} /> Add item
      </span>
      <span
        style={{ color: 'var(--Base-Color)', marginLeft: 10 }}
        className='cursor-pointer '
        onClick={() => handleNewItem(true)}
      >
        <Plus size={15} /> Add new row
      </span>
      {/* <button className="add-item-btn">+ Add item</button> */}
    </div>
  );
};

const ItemInput = ({
  setInvoiceItemData,
  invoiceItemData,
  item,
  index,
  invoiceData,
  setInvoiceData,
  // calculateTaxAndAmount,
  // calculateInvoiceAmount,
}) => {
  const [showDropdownIndex, setShowDropdownIndex] = useState(null); // Track open dropdown
  const [itemSearchKey, setItemSearchKey] = useState(''); // Store all fetched items
  const { globalSettings, makeAlert } = useContext(GlobalContext);
  const debouncedItemSearch = useDebounce(itemSearchKey, 500);
  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  // Fetch Items - useQuery (Runs once on focus)
  const { data: itemData } = useQuery({
    queryKey: ['invoice-list', debouncedItemSearch],
    queryFn: () =>
      itemService.itemList({ select: ['name', 'price', 'id'], searchKey: debouncedItemSearch }),
    select: (data) => data.data.rows,
  });

  // Handle Focus - Fetch Initial Data
  const handleFocus = (index) => {
    setShowDropdownIndex(index);
  };

  // Handle Search in Input
  const handleItemSearch = (event, index) => {
    const value = event.target.value;

    // Filter based on search query
    setItemSearchKey(value);
    setShowDropdownIndex(index); // Keep dropdown open

    // Update input value
    const updatedItems = [...invoiceItemData];
    updatedItems[index] = {
      ...updatedItems[index],
      item_name: value,
    };
    setInvoiceItemData(updatedItems);
  };

  // // Handle Selection from Dropdown
  // const handleItemSelect = (selectedItem, index) => {
  //   const updatedItems = [...invoiceItemData];
  //   updatedItems.pop();

  //   const totalAmount =
  //     invoiceData?.tax === TAX_CONST.INCLUSIVE
  //       ? (selectedItem?.price / 100) * selectedItem?.Tax?.rate + selectedItem?.price
  //       : selectedItem?.price;
  //   updatedItems.push({
  //     item_name: selectedItem.name,
  //     rate: selectedItem?.price, // Update price
  //     quantity: 1, // Update price
  //     default_tax: invoiceData?.tax === TAX_CONST.INCLUSIVE ? selectedItem?.Tax : null,
  //     amount: totalAmount, // Update amount
  //     item_id: selectedItem?.id ?? null,
  //   });

  //   setInvoiceItemData(updatedItems);
  //   setShowDropdownIndex(null); // Hide dropdown1
  //   // handleTotalAmount();
  // };
  // Handle Selection from Dropdown - inclusive updates
  // const handleItemSelect = (selectedItem, index) => {
  //   const updatedItems = [...invoiceItemData];

  //   // Remove the item at the specified index
  //   updatedItems.splice(index, 1);

  //   // Calculate the total amount based on tax type
  //   const totalAmount =
  //     invoiceData?.tax === TAX_CONST.INCLUSIVE
  //       ? (selectedItem?.price / 100) * selectedItem?.Tax?.rate + selectedItem?.price
  //       : selectedItem?.price;

  //   // Add the new item at the specified index
  //   updatedItems.splice(index, 0, {
  //     item_name: selectedItem.name,
  //     rate: selectedItem?.price, // Update price
  //     quantity: 1, // Update quantity
  //     default_tax: selectedItem?.Tax,
  //     amount: totalAmount, // Update amount
  //     item_id: selectedItem?.id ?? null,
  //   });

  //   // Update the state with the modified array
  //   setInvoiceItemData(updatedItems);
  //   setShowDropdownIndex(null); // Hide dropdown
  //   // handleTotalAmount(); // Uncomment if needed
  // };

  const handleItemSelect = (selectedItem, index) => {
    const updatedItems = [...invoiceItemData];

    // Remove the existing item at the specified index
    updatedItems.splice(index, 1);

    const quantity = 1; // Default quantity
    const rate = parseFloat(selectedItem?.price) || 0;

    // Create the new item
    let newItem = {
      item_name: selectedItem.name,
      rate,
      quantity,
      default_tax: selectedItem?.Tax?.id ? selectedItem?.Tax : null,
      item_id: selectedItem?.id ?? null,
    };

    // Apply tax calculations based on the current tax type
    newItem = calculateTaxAndAmount(newItem, invoiceData?.tax, false);

    // Add the new item at the specified index
    updatedItems.splice(index, 0, newItem);

    const updateInvoiceData = calculateInvoiceAmount(
      invoiceData,
      {
        companyTaxNumber: invoiceSettings?.labels?.companyTaxNumber,
      },
      updatedItems,
      makeAlert,
    );

    if (updateInvoiceData?.isSuccess) {
      setInvoiceData(updateInvoiceData?.data);
      // Update the state with the modified array
      setInvoiceItemData(updatedItems);
    }
    setShowDropdownIndex(null); // Hide dropdown
  };

  // const handleItemSelect = (selectedItem, index) => {

  //   const updatedItems = [...invoiceItemData];

  //   // Remove the existing item at the specified index
  //   updatedItems.splice(index, 1);

  //   const rate = parseFloat(selectedItem?.price) || 0;
  //   const taxRate = parseFloat(selectedItem?.Tax?.rate) || 0;
  //   const quantity = 1; // Default quantity

  //   let totalAmount = quantity * rate;
  //   let taxAmount = 0;
  //   let finalAmount = totalAmount;

  //   if (invoiceData?.tax === TAX_CONST.EXCLUSIVE) {
  //     taxAmount = (totalAmount * taxRate) / 100;
  //     finalAmount += taxAmount;
  //   } else if (invoiceData?.tax === TAX_CONST.INCLUSIVE) {
  //     taxAmount = totalAmount - totalAmount / (1 + taxRate / 100);
  //   }

  //   // Add the new item at the specified index with updated values
  //   updatedItems.splice(index, 0, {
  //     item_name: selectedItem.name,
  //     rate,
  //     quantity,
  //     default_tax: selectedItem?.Tax,
  //     amount: parseFloat(finalAmount.toFixed(2)), // Ensure 2 decimal places
  //     item_tax_amount: parseFloat(taxAmount.toFixed(2)), // Ensure 2 decimal places
  //     item_id: selectedItem?.id ?? null,
  //   });

  //   // Update the state with the modified array
  //   setInvoiceItemData(updatedItems);
  //   setShowDropdownIndex(null); // Hide dropdown
  // };

  return (
    <>
      <input
        type='text'
        autoComplete='off'
        className='input-box'
        name='item_name'
        value={item?.item_name}
        onChange={(e) => handleItemSearch(e, index)}
        disabled={invoiceItemData[index]?.item_id}
        onFocus={() => handleFocus(index)} // Fetch data on focus
        onBlur={(e) => {
          if (!e.relatedTarget || !e.relatedTarget.classList.contains('dropdown-item')) {
            setShowDropdownIndex(null); // Close dropdown only if focus moves outside
          }
        }}
        placeholder='Search or enter item'
      />
      {/* Dropdown for Search Results */}
      {showDropdownIndex === index && itemData?.length > 0 && (
        <ItemSuggestion itemData={itemData} onSelect={handleItemSelect} itemIndex={index} />
      )}
    </>
  );
};

const ItemSuggestion = ({ itemData, onSelect, itemIndex }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp' && selectedIndex > 0) {
        setSelectedIndex((prev) => prev - 1);
      } else if (e.key === 'ArrowDown') {
        setSelectedIndex((prev) => prev + 1);
      }

      if (e.key === 'Enter') {
        onSelect(itemData[selectedIndex], itemIndex);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex]);

  return (
    <ul
      className='menu_popup filter-container p-0'
      onKeyDown={() => {}} // Handle keyboard events in the ul
      tabIndex={0} // Make ul focusable for keyboard navigation
    >
      {itemData?.map((option, i) => (
        <li
          key={option.id}
          onClick={() => onSelect(option, itemIndex)}
          onKeyDown={() => {
            onSelect(option, itemIndex);
            setSelectedIndex((prev) => prev + 1);
          }} // Select on mouse click
          className={`dropdown-item ${selectedIndex === i ? 'item-selected' : ''} `} // Highlight focused item
          tabIndex={0} // Make each li focusable
        >
          {option.name}
        </li>
      ))}
    </ul>
  );
};
