import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Home, Pen, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Modal, PaginationComponent, TableRow } from '../_components';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import NoPermission from '../_components/NoPermission';
import { SearchBar } from '../_components/Search';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { invoiceService } from '../_services/invoice.service';
import '../common.css';
import { currencyType } from '../Settings/InvoiceSettings';
import { GlobalContext, MyTeamIcon } from '../Utils';
import {
  Calendar,
  FileSize,
  LoadingIcon,
  OrderNumberIcon,
  PaymentIcon,
  SINoIcon,
  User,
} from '../Utils/SvgIcons';
import { AddInvoice } from './AddInvoice';
import './invoice.css';
import { TRANSACTION_TYPE } from './InvoiceConstant';
import InvoiceSingle from './InvoiceSingle';
export function EstimateList() {
  // hooks Section
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  //   const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const { globalSettings, roleBasedPermission } = useContext(GlobalContext);

  const invoiceSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'invoice-settings');

  const selectedCurrency = invoiceSettings?.labels?.currency;

  const currencyTextIcon = currencyType.find(
    (currency) => currency.id === selectedCurrency,
  )?.textIcon;

  const TABLE_HEADINGS = [
    { name: <SINoIcon />, minWidth: 50 },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <FileSize /> Estimates
        </div>
      ),
      minWidth: '10vw',
      maxWidth: '20vw',
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <OrderNumberIcon /> Reference Number
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <User color='var(--second-text-color)' /> Customer Name
        </div>
      ),
      minWidth: 50,
    },
    // {
    //   name: (
    //     <div className='d-flex align-items-center gap-1'>
    //       <PaymentIcon primeColor='var(--second-text-color)' /> Amount Due
    //     </div>
    //   ),
    //   minWidth: 50,
    // },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <PaymentIcon primeColor='var(--second-text-color)' /> Amount
        </div>
      ),
      minWidth: 50,
    },
    // {
    //   name: (
    //     <div className='d-flex align-items-center gap-1'>
    //       <StatusIcon /> Status
    //     </div>
    //   ),
    //   minWidth: 50,
    // },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          <Calendar height={14} width={14} /> Created On
        </div>
      ),
      minWidth: 50,
    },
    {
      name: (
        <div className='d-flex align-items-center gap-1'>
          {' '}
          <Calendar height={14} width={14} /> Due Date
        </div>
      ),
      minWidth: 50,
    },
    {
      name: <div></div>,
    },
  ];

  // reference section
  const tableContainerRef = useRef(null);

  // useState section
  const [query, setQuery] = useState({
    select: [
      'id',
      'invoice_number',
      'order_number',
      'createdAt',
      'due_date',
      'invoice_status',
      'bill_date',
      'total',
      'paid_amount',
    ],
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    searchKey: '',
    transaction_type: TRANSACTION_TYPE.ESTIMATE,
  });

  const [estimateId, setEstimateId] = useState();
  const [estimateModal, setEstimateModal] = useState(false);
  const [editEstimateId, setEditEstimateId] = useState(null);

  const debouncedSearch = useDebounce(query?.searchKey, 500);

  // code to redirect page 1 after adding filter
  useEffect(() => {
    if (searchParams.get('pageNo')) {
      const page = parseInt(searchParams.get('pageNo'));
      if (query.pageVo.pageNo !== page) {
        setQuery((prevQuery) => ({
          ...prevQuery,
          pageVo: { ...prevQuery.pageVo, pageNo: page },
        }));
      }
    }
  }, [searchParams, query.pageVo.pageNo]);

  const filterToUrl = {};

  const {
    data: estimateData,
    isLoading,
    isSuccess,
    error,
    refetch,
  } = useQuery({
    queryKey: ['estimate-list', query?.pageVo?.pageNo, debouncedSearch, filterToUrl],
    queryFn: () => invoiceService.invoiceList({ ...query, ...filterToUrl }),
    placeholderData: keepPreviousData,
  });

  if (!roleBasedPermission?.ACTION_MANAGE_INVOICE) {
    return <NoPermission />;
  }

  // get the count of project
  const listItemCount = estimateData?.data?.rows.length;

  const tableData = estimateData?.data?.rows?.map((estimate, index) => {
    const slNo = (estimateData?.data?.page - 1) * estimateData?.data?.limit + index + 1;
    const currencyTextIconForEstimate = estimate?.client?.currency
      ? currencyType.find((currency) => currency.id === estimate?.client?.currency)?.textIcon
      : currencyTextIcon;
    return (
      <Tr
        key={estimate?.id}
        onClick={() => {
          setEstimateModal(true);
          setEstimateId(estimate?.id);
        }}
        className='cursor-pointer'
      >
        <Td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</Td>
        <Td className=' table-responsive-td' style={{ fontWeight: 500 }}>
          {estimate?.invoice_number}
        </Td>
        <Td> {estimate?.order_number}</Td>
        <Td> {estimate?.client?.name}</Td>
        {/* <Td>
          {currencyTextIconForEstimate}
          {parseFloat(estimate?.total) - parseFloat(estimate?.paid_amount)}
        </Td> */}
        <Td>
          {currencyTextIconForEstimate}
          {parseFloat(estimate?.total)}
        </Td>
        {/* <Td> {INVOICE_STATUS_TYPE[estimate?.invoice_status]}</Td> */}

        <Td>
          {new Date(estimate?.createdAt).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })}
        </Td>
        <Td>
          <span>
            {estimate?.due_date
              ? moment(parseInt(estimate?.due_date)).format('MMM DD, YYYY')
              : '--'}
          </span>
        </Td>
        <Td
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className='d-flex gap-2 justify-content-end icon-div invisible'>
            <ToolTip tooltipText={`Edit Estimate`} isModern popOver scrollRef={tableContainerRef}>
              <div
                className='icon-bg'
                onClick={(event) => {
                  event.stopPropagation();
                  setEditEstimateId(estimate?.id);
                  setAddModal(true);
                }}
              >
                <Pen size={15} />
              </div>
            </ToolTip>
          </div>
        </Td>
      </Tr>
    );
  });

  return (
    <div className='fade-entry'>
      <div className=' url-container '>
        <div className='d-flex align-items-center  breadcrumbs url-navigation'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => nav(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Estimate'}
            fontWeight={500}
            color={`#2A2E34`}
            icon={<FileSize color='var(--main-text-color)' />}
            onClick={() => nav(`/${RT.ESTIMATE_LIST}`)}
          />
        </div>
      </div>
      {/* component will active when error occurs   */}
      <div>
        <ErrorComponent error={error?.message} />
        <div className='invoice-table-container'>
          {/* header input section */}
          <section>
            <div className='tab_contain'>
              <SearchBar
                handleSearch={(event) => {
                  searchParams.set('pageNo', 1);
                  setSearchParams(searchParams);
                  setQuery({
                    ...query,
                    searchKey: event.target.value,
                    pageVo: { ...query.pageVo, pageNo: 1 },
                  });
                }}
                searchKey={query.searchKey}
              />
              <div className='d-flex gap-1 align-items-center justify-content-end'>
                {/* {roleBasedPermission?.ACTION_PROJECT_ADD && ( */}
                <ToolTip tooltipText={`Add Estimate`} isModern>
                  <div className='menu-item-outline' onClick={() => setAddModal(true)}>
                    <Plus size={16} color='#87909E' />
                  </div>
                </ToolTip>
                {/* )} */}

                {/* <ToolTip tooltipText={'Filter'} isModern hideTooltip={isFilterVisible}>
                  <div
                    className='menu-item-outline'
                    style={{
                      background: isFilterVisible || Boolean(filterCount) ? '#e9f2ff' : '',
                    }}
                    ref={filterRef}
                    onClick={() => {
                      setIsFilterVisible(true);
                    }}
                  >
                    <ListFilter
                      size={16}
                      color={isFilterVisible || Boolean(filterCount) ? '#3454d1' : '#87909E'}
                    />
                    {isFilterVisible && (
                      <ProjectFilter
                        setSearchParams={setSearchParams}
                        handleClose={(event) => {
                          event.stopPropagation();
                          setIsFilterVisible(false);
                        }}
                        searchParams={searchParams}
                      />
                    )}

                    {Boolean(filterCount) && <div className='filter-count'>{filterCount}</div>}
                  </div>
                </ToolTip> */}
              </div>
            </div>
          </section>

          <section className='mt-2'>
            <div
              className='project-table table-container no-padding'
              ref={tableContainerRef}
              style={{ maxHeight: '65vh' }}
            >
              <Table className='table table-hover tbl mb-0'>
                <Thead>
                  <Tr className='table-head'>
                    {TABLE_HEADINGS.map((heading, index) => {
                      return (
                        <Th
                          key={index}
                          scope='col'
                          style={{
                            minWidth: heading.minWidth,
                            maxWidth: heading.maxWidth,
                            color: 'var(--second-text-color)',
                          }}
                        >
                          {heading.name}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody style={{ verticalAlign: 'middle' }}>
                  {isLoading && (
                    <TableRow noOfCols={9}>
                      <LoadingIcon size={60} />
                    </TableRow>
                  )}
                  {!listItemCount && isSuccess && <TableRow noOfCols={9}>No data found</TableRow>}
                  {listItemCount > 0 && isSuccess && tableData}
                </Tbody>
              </Table>
            </div>
          </section>
          {listItemCount > 0 && (
            <section className='mt-2'>
              <PaginationComponent
                pageOnClick={(data) => {
                  setQuery((prev) => ({
                    ...prev,
                    pageVo: {
                      ...prev.pageVo,
                      pageNo: data,
                    },
                  }));
                  searchParams.set('pageNo', data);
                  setSearchParams(searchParams);
                }}
                demoData={estimateData?.data}
              />
            </section>
          )}
        </div>

        {addModal && (
          <Modal.Container
            handleClose={() => {
              setAddModal(false);
            }}
          >
            <Modal.View className={'w-75'}>
              <Modal.Head
                Heading={`${editEstimateId ? 'Edit' : 'Create'} Estimate`}
                handleClose={() => {
                  setAddModal(false);
                }}
              />
              <AddInvoice
                transactionType={TRANSACTION_TYPE.ESTIMATE}
                handleClose={() => {
                  setAddModal(false);
                }}
                afterAdd={() => refetch()}
                id={editEstimateId}
              />
            </Modal.View>
          </Modal.Container>
        )}

        {estimateModal && (
          <Modal.Container
            handleClose={() => {
              setEstimateModal(false);
            }}
          >
            <Modal.View
              modelViewInnerClass={'payment-inner-modal-view'}
              containerClass='payment_received_view'
              style={{ marginTop: '0px', width: '40%' }}
            >
              <Modal.Head
                Heading={'Estimate'}
                handleClose={() => {
                  setEstimateModal(false);
                }}
              />
              <InvoiceSingle invoice_id={estimateId} />
            </Modal.View>
          </Modal.Container>
        )}
      </div>
    </div>
  );
}
