import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import { useEffect, useState } from 'react';

import { userService } from '../_services';
import { useUser } from './useUser';

export const firebaseConfig = {
  apiKey: 'AIzaSyBTP7RZmOyebPjSgaKGW5xv5FBAV8oYN_8',
  authDomain: 'hidesk-44f2e.firebaseapp.com',
  projectId: 'hidesk-44f2e',
  storageBucket: 'hidesk-44f2e.appspot.com',
  messagingSenderId: '137210691743',
  appId: '1:137210691743:web:2171d85a1fa17224d622aa',
};

function useNotification({ disabled = false, saveDB = true }) {
  const [status, setStatus] = useState(null);
  const [token, setToken] = useState(null);
  const user = useUser();

  useEffect(() => {
    if (disabled) {
      setStatus('disabled');
      return;
    }

    if (!('serviceWorker' in navigator)) {
      console.error('Service workers are not supported in this browser.');
      setStatus('unsupported');
      return;
    }

    if (!isSupported()) {
      console.error('This browser does not support Firebase Messaging');
      setStatus('unsupported');
      return;
    }

    if (!user) {
      console.error('User not found');
      return;
    }

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    const registerToken = async (token) => {
      console.log(token);

      try {
        setToken(token);
        if (user?.id && saveDB) {
          const response = await userService.updateToken({ token });
          if (response?.errorCode === 0) {
            setToken(token);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    const registerAppWithToken = () => {
      getToken(messaging)
        .then((currentToken) => {
          if (currentToken) {
            setStatus('receivedToken');
            if (user?.id) {
              registerToken(currentToken);
            }
          }
        })
        .catch((error) => {
          console.log({ error });
          setStatus('tokenGenerationFailed');
        });
    };

    if (!('Notification' in window)) {
      console.log('Browser does not support notifications');
      setStatus('unsupported');
    } else if (Notification.permission === 'granted') {
      setStatus('granted');
      registerAppWithToken();
    } else if (Notification.permission === 'denied' || Notification.permission === 'default') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          registerAppWithToken();
          setStatus('granted');
        } else {
          setStatus('denied');
        }
      });
    }

    // // Listen for foreground messages
    // const unsubscribeOnMessage = onMessage(messaging, (payload) => {
    //   console.log('Received pp foreground message: ', payload);
    //   // setNotification(payload.notification); // Store the notification data
    // });

    // // Ensure the service worker is ready and then listen for messages from the service worker (background messages)
    // navigator.serviceWorker.ready.then((registration) => {
    //   if (registration.active) {
    //     navigator.serviceWorker.addEventListener('message', (event) => {
    //       console.log('Received mp background message:', event.data);
    //       if (event.data && event.data.type === 'BACKGROUND_NOTIFICATION') {
    //         // setNotification(event.data.payload); // Store the notification data
    //       }
    //     });
    //   }
    // });

    // return () => {
    //   // Clean up foreground message listener
    //   unsubscribeOnMessage();
    // };
  }, [disabled]);

  return { status, token };
}

export default useNotification;
