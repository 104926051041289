import { useQuery } from '@tanstack/react-query';
import { Home } from 'lucide-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import IconLabelSet from '../_components/IconLabelSet';
import { userService } from '../_services';
import { useUser } from '../Hook/useUser';
import { MyTeamIcon } from '../Utils';
import { User } from '../Utils/SvgIcons';
import { ProfileTabs } from './ProfileTabs';

export const ProfileHome = () => {
  let currentUserId = useUser()?.id;
  const query = {
    select: [
      'id',
      'first_name',
      'last_name',
      'address',
      'phone_no',
      'email_id',
      'gender',
      'department_id',
      'designation_id',
      'employee_code',
      'role',
      'reported_users',
      'image_url',
      'username',
      'password',
      'dob',
    ],

    id: currentUserId,
  };
  const {
    data: userData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['user'],
    queryFn: () => userService.userGet(query),
    select: (data) => data?.data,
  });
  console.log(userData, 'pop');

  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [activeTab, setActiveTab] = useState({
    name: '',
  });
  return (
    <div>
      <section>
        <div className=' url-container '>
          <div className='d-flex align-items-center breadcrumbs'>
            <IconLabelSet
              label={'Home'}
              fontWeight={400}
              icon={<Home size={13} />}
              onClick={() => navigate(`/`)}
            />
            <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
            <IconLabelSet
              label={'Profile'}
              fontWeight={500}
              icon={<User color='#2A2E34' height={13} width={12} />}
              //   onClick={() => {
              //     navigate(`/${RT.CLIENT}`);
              //   }}
            />
          </div>
        </div>
      </section>
      <div className='d-flex w-75 h-100' style={{ padding: 20 }}>
        {/* <div className='w-25'> */}
        <ProfileTabs setActiveTab={setActiveTab} />
        {/* </div> */}
        <div className='w-75' style={{ backgroundColor: 'var(--bg-primary)' }}>
          <section className='section'>
            <Outlet
              context={{
                userProfileData: {
                  first_name: userData?.first_name,
                  last_name: userData?.last_name,
                  gender: userData?.gender,
                  // dob: userData?.dob,
                  dob: moment(parseInt(userData?.dob)).format('YYYY-MM-DD'),
                  email_id: userData?.email_id,
                  phone_no: userData?.phone_no,
                  id: userData?.id,
                  compressed_image_url: userData?.compressed_image_url,
                  image_url: userData?.image_url,
                },
                userLoading: isLoading,
                userRefetch: refetch,
                employeeDetails: {
                  employee_code: userData?.employee_code,
                  department: userData?.department?.name,
                  designation: userData?.designation?.name,
                  department_id: userData?.department_id,
                  designation_id: userData?.designation_id,
                  organization: userData?.company_name,
                  reported_users: userData?.reported_users,
                  reportingUsersDetails: userData?.reported_users_details,
                },
              }}
            />
          </section>
        </div>
      </div>
    </div>
  );
};
