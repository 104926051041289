//create search component

import { Search, X } from 'lucide-react';
import React, { useState } from 'react';
export const SearchBar = ({ handleSearch, searchKey, clearSearch = () => {} }) => {
  const [hasValue, setHasValue] = useState(false);

  const isClearSearchProvided =
    typeof clearSearch === 'function' && clearSearch.toString() !== (() => {}).toString();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setHasValue(value !== ''); // Add `has-value` if input is not empty
    handleSearch(e); // Pass the event to the parent handler
  };

  const handleClear = () => {
    if (isClearSearchProvided) {
      clearSearch(); // Call function to clear input (from parent)
    } else {
      handleInputChange({ target: { value: '' } });
    }
    setHasValue(false); // Reset hasValue
  };

  return (
    <div className={`search_contain ${hasValue ? 'has-value' : ''}`}>
      <input
        type='text'
        className='bodr_none'
        onChange={handleInputChange}
        value={searchKey}
        placeholder='Search'
      />
      {hasValue ? (
        <X size={18} color='#87909E' onClick={handleClear} style={{ cursor: 'pointer' }} />
      ) : (
        <Search size={18} color='#87909E' />
      )}
    </div>
  );
};
