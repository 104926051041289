import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Calendar, Hash, Shapes } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { components } from 'react-select';
import { Button, TableRow } from '../_components';
import NoPermission from '../_components/NoPermission';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import UserDropDown from '../_components/UserDropDown';
import { COMMON_USER_SELECT, CommonDropDownStyle, USER_LIST_ACTIONS } from '../_constants';
import { userService } from '../_services';
import { reportServices } from '../_services/reports.service';
import { useUser } from '../Hook/useUser';
import { Heading } from '../Misc';
import {
  GlobalContext,
  hoursToHoursMinutes,
  hoursToHoursMinutesIn100,
  millisecondsToHoursMinutes,
} from '../Utils';
import { LoadingIcon, User } from '../Utils/SvgIcons';
import ReportDatePicker from './ReportDatePicker';

export const TimeHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState(useUser());
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf('month').valueOf(),
    endDate: moment().valueOf(), // Set to current day and time
  });

  const { makeAlert, roleBasedPermission } = useContext(GlobalContext);

  const {
    data: timeHistory,
    isLoading: historyLoading,
    isSuccess,
    isPaused,
  } = useQuery({
    queryKey: ['time-history-list', searchParams.toString()],
    queryFn: () =>
      reportServices.getTimeHistory({
        user_id: selectedUser?.id,
        company_id: 1,
        start_time: dateRange?.startDate,
        end_time: dateRange?.endDate,
      }),
    enabled: roleBasedPermission?.ACTION_TIME_HISTORY,
    placeholderData: keepPreviousData,
  });

  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ['user-list'],
    queryFn: () =>
      userService.getRoleBasedUserList({
        action: USER_LIST_ACTIONS.ACTION_REPORT_USER_LIST,
        select: COMMON_USER_SELECT,
      }),
    select: (data) => data.data.rows,
    placeholderData: keepPreviousData,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'user_id') {
      setSelectedUser(value);
    }
  };

  const handleDateChange = (dates) => {
    const startDate = new Date(dates.startDate);
    const endDate = new Date(dates.endDate);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    setDateRange({
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
    });
  };

  // section trigger when delete option selected
  useEffect(() => {
    if (isPaused) {
      makeAlert('No Internet');
    }
  }, [isPaused]);

  const handleSubmit = () => {
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams.toString());
      updatedParams.set('user_id', selectedUser.id);
      updatedParams.set('fromDate', dateRange.startDate);
      updatedParams.set('toDate', dateRange.endDate);
      return updatedParams;
    });
  };

  if (isUserLoading) {
    return (
      <div className='page-height d-flex justify-content-center align-items-center'>
        <LoadingIcon size={60} />
      </div>
    );
  }

  const { hoursInTime, minutesInTime } = hoursToHoursMinutes(
    timeHistory?.data?.total_hour?.toFixed(3),
  );

  const { hours, minutes } = hoursToHoursMinutesIn100(timeHistory?.data?.total_hour?.toFixed(3));

  const tableData = timeHistory?.data?.history?.map((workData, index) => {
    // let dateHour = convertHoursAndMinutes(workData?.worked_time);
    const { hours, minutes } = millisecondsToHoursMinutes(workData?.worked_time_milli);

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{moment.utc(workData?.date).format('ddd, DD MMM YYYY')}</td>
        <td>
          {workData?.worked_time <= 0 ? (
            <span>--</span>
          ) : (
            ` ${hours.toString().padStart(2, '0')}:
              ${minutes.toString().padStart(2, '0')}`
          )}
        </td>
      </tr>
    );
  });

  if (!roleBasedPermission?.ACTION_TIME_HISTORY) {
    return <NoPermission />;
  }

  return (
    <div className='time-history'>
      {/* <Heading title={'Time History'} /> */}
      <Heading title={'Daily Time Log'} />
      <div className='mt-4 d-flex align-items-center gap-3 time-history-filter-container'>
        <div>
          <SearchableQuerySelect
            isClearable
            queryKey={'document-type-list'}
            queryFn={userService.getRoleBasedUserList}
            getFunctionParams={(searchKey) => ({
              searchKey,
              action: USER_LIST_ACTIONS.ACTION_REPORT_USER_LIST,
              select: COMMON_USER_SELECT,
            })}
            isCustomFunction
            initialValue={userData}
            menuPortalTarget={document.getElementById('MODAL')}
            formatOptionLabel={(data, metaData) => (
              <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
            )}
            getOptionValue={(option) => option.id}
            onChange={(selectedOptions) => {
              handleChange({
                target: {
                  name: 'user_id',
                  value: selectedOptions,
                },
              });
            }}
            styles={CommonDropDownStyle}
            components={{
              SingleValue: (props) => <CustomSingleValue {...props} icon={<User />} title='User' />,
            }}
            placeholder={
              <div className='d-flex align-items-center gap-1'>
                <User /> User
              </div>
            }
            value={selectedUser}
          />
        </div>

        <ReportDatePicker
          onDateChange={handleDateChange}
          initialStartDate={dateRange?.startDate}
          initialEndDate={dateRange?.endDate}
        />
        <div className='d-flex justify-content-end'>
          <Button.Container
            type='Save'
            handleOk={() => {
              handleSubmit();
            }}
            isDisabled={historyLoading}
            className={'btn p-3 blue-button-inline-task'}
          >
            <Button.Title title={'Submit'} />
            <Button.Loading color='#fff' size={20} />
          </Button.Container>
        </div>
      </div>
      <section className='mt-2'>
        <div className='table_outer'>
          <table className='table table-hover tbl mb-0'>
            <thead>
              <tr>
                <th style={{ width: 'fit-content' }}>
                  <div className='head-container'>
                    <Hash size={15} />
                    <span>Sl.No</span>
                  </div>
                </th>
                <th>
                  <div className='head-container'>
                    <Calendar size={15} />
                    <span>Date</span>
                  </div>
                </th>
                <th>
                  <div className='head-container'>
                    <Shapes size={15} />
                    <span> Worked time</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: 'middle' }}>
              {historyLoading && (
                <TableRow noOfCols={8}>
                  <LoadingIcon size={60} />
                </TableRow>
              )}

              {isPaused && <TableRow noOfCols={8}>No Internet</TableRow>}

              {isSuccess && !isPaused && tableData}
            </tbody>
          </table>
          {isSuccess && !isPaused && (
            <>
              <div style={{ textAlign: 'center', marginLeft: '19vw', marginBottom: '10px' }}>
                Total Worked Hours in Time : {hoursInTime.toString().padStart(2, '0')}:
                {minutesInTime.toString().padStart(2, '0')}
              </div>
              <div style={{ textAlign: 'center', marginLeft: '19vw' }}>
                Total Worked Hours <span className='fz-10px'>(base 100)</span> :{' '}
                {hours?.toString()?.padStart(2, '0')}:{minutes?.toString()?.padStart(2, '0')}
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export const CustomSingleValue = ({ children, icon, title = '', ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div className='d-flex align-items-center gap-1' style={{ color: 'var(--icon-light)' }}>
        {icon} {title} {title ? ':' : ''} {children}
      </div>
    </components.SingleValue>
  );
};
