/* eslint-disable no-unused-vars */
import { apiConstants } from '../_constants';
import { authHeader, handleResponse } from '../_helpers';
import { jsonToQueryString } from '../_helpers/utilFunctions';

export const userService = {
  userAdd,
  userList,
  userDelete,
  userGet,
  userDeactivate,
  getPermittedUser,
  userListAdvanced,
  updateToken,
  getPrevEmpCode,
  userListWithTaskList,
  getRoleBasedUserList,
  getNonRoleBasedUserList,
  inviteUser,
  inviteAccept,
  updateUserProfile,
  updateLoginDetails,
  userLoginGet,
  getDirectReportingUserList,
  updateProfile,
};

async function updateToken(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/update-token', requestOptions).then(handleResponse);
}
async function userAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/user_add', requestOptions).then(handleResponse);
}

async function userList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/user_list', requestOptions).then(handleResponse);
}

async function userListAdvanced(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/user_list_advanced', requestOptions).then(handleResponse);
}

async function userDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/user_delete', requestOptions).then(handleResponse);
}

async function userGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_user?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function userDeactivate(data) {
  const apiUrl = `${apiConstants.apiUrl}/user_deactivate`;
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function getPermittedUser(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/users_list_based_on_action', requestOptions).then(
    handleResponse,
  );
}

async function getPrevEmpCode(data) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_last_employee_code', requestOptions).then(
    handleResponse,
  );
}

async function userListWithTaskList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/user_list_with_task_list', requestOptions).then(
    handleResponse,
  );
}

async function inviteUser(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invite-users', requestOptions).then(handleResponse);
}

async function inviteAccept(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invite-accept', requestOptions).then(handleResponse);
}

async function updateUserProfile(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/user-profile-update', requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
        localStorage.setItem('company_id', user.data.company_id);
      }
      return user;
    });
}

// user Login services

async function userLoginGet(data) {
  const params = jsonToQueryString(data);
  const apiUrl = `${apiConstants.apiUrl}/get_user_login_data?${params}`;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(apiUrl, requestOptions).then(handleResponse);
}

async function updateLoginDetails(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/update_login_details', requestOptions).then(handleResponse);
}

async function getRoleBasedUserList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_role_based_user_list', requestOptions).then(
    handleResponse,
  );
}
async function getNonRoleBasedUserList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_non_role_based_user_list', requestOptions).then(
    handleResponse,
  );
}

async function getDirectReportingUserList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/reporting_user_list', requestOptions).then(handleResponse);
}

async function updateProfile(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/update_profile', requestOptions).then(handleResponse);
}
