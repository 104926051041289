/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { Button } from '../../_components';
import SearchableInfiniteQuerySelect from '../../_components/SearchableInfiniteQuerySelect';
import { CustomDropdownIndicator } from '../../_components/SelectionDropDownIcon';
import { CommonDropDownStyle, MENU } from '../../_constants';
import { clientService, projectService } from '../../_services';
import { GlobalContext } from '../../Utils';
import { Cancel, Tick } from '../../Utils/SvgIcons';

// file type filter list

const task_action = [
  { name: 'CREATION', value: 'creation' },
  { name: 'STARTED', value: 'started' },
  { name: 'STOPPED', value: 'stopped' },
  { name: 'STATUS_CHANGE', value: 'status-change' },
  { name: 'VERIFIED', value: 'verified' },
  { name: 'COMPLETED', value: 'completed' },
  { name: 'REPEAT', value: 'repeat' },
  { name: 'EDITED', value: 'edited' },
  { name: 'DELETED', value: 'deleted' },
];
export const TimeLogFilter = ({ handleClose = () => {}, setSearchParams, searchParams }) => {
  const [filter, setFilter] = useState({});

  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newFilterData = {};

    params.forEach((value, key) => {
      if (key === 'project_id' || key === 'client') {
        newFilterData[key] = value;
      } else if (key === 'action') {
        newFilterData[key] = task_action.find((item) => item.value === value);
      } 
    });

    setFilter(newFilterData);
  }, []);

  const handleFilterChange = (selectedOption, filter) => {
    setFilter((prev) => ({
      ...prev,
      [filter]: selectedOption,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Get the current URL parameters
    const params = new URLSearchParams(window.location.search);

    // Remove the unwanted parameters
    params.delete('pageNo');
    params.delete('action');
    params.delete('project_id');
    params.delete('client');

    let project_id = filter?.project_id?.id ?? null;
    let client = filter?.client?.id ?? null;
    let action = filter?.action?.value ?? null;

    // Convert task_start_date and task_end_date to milliseconds if they exist
    const updatedFilterData = {
      ...filter,
      project_id: project_id,
      client: client,
      action: action,
    };

    // Add the filter data to the URL parameters
    Object.keys(updatedFilterData).forEach((key) => {
      if (updatedFilterData[key]) {
        params.set(key, updatedFilterData[key]);
      }
    });
    params.set('pageNo', 1);

    // Update the URL
    setSearchParams(params, { replace: true });
    setIsLoading(false);
    handleClose(event);
  };
  // const handleSubmit = () => {}
  const handleClear = (event) => {
    const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();

    for (const [key, value] of params) {
      // Retain the parameters that should not be removed
      if (key === 'user_id' || key === 'fromDate' || key === 'toDate' || key === 'from') {
        newParams.append(key, value);
      }
    }

    // Update the URL with the new parameters
    setSearchParams(newParams);
    // Clear the filter data
    setFilter({});
    handleClose(event);
  };

  const fetchClient = async () => {
    const data = await clientService.clientGet({ id: filter?.client, select: ['id', 'name'] });
    setFilter((prev) => ({
      ...prev,
      client: data?.data?.[0],
    }));

    return data;
  };

//   // Client list
//   const { data: clientData } = useQuery({
//     queryKey: ['clientList'],
//     queryFn: () =>
//       clientService.clientList({ searchKey: '', select: ['id', 'name'], forFile: true }),
//     select: (data) => {
//       return data?.data?.rows;
//     },
//   });

  const fetchProject = async () => {
    const data = await projectService.projectGet({
      id: filter?.project_id,
      select: ['id', 'name'],
    });
    setFilter((prev) => ({
      ...prev,
      project_id: data?.data,
    }));

    return data;
  };

  // fetch client details
  const { data: project } = useQuery({
    queryKey: ['project-data', filter?.project_id],
    queryFn: () => fetchProject(),
    enabled: typeof filter?.project_id !== 'object' && !!filter?.project_id,
    //   &&
    //   !projectData?.some((item) => parseInt(item.id) === parseInt(filter.project.id)), // Only run the query if the id is set
  });

   // fetch client details
   const { data: client } = useQuery({
    queryKey: ['client-data', filter?.client],
    queryFn: () => fetchClient(),
    enabled: typeof filter?.client !== 'object' && !!filter?.client,
    //   &&
    //   !projectData?.some((item) => parseInt(item.id) === parseInt(filter.project.id)), // Only run the query if the id is set
  });

  return (
    <div className={`menu_popup filter-container `} style={{ padding: 20, right: 0 }}>
      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Filter</h6>
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <div className='filter-body'>
          {hasExist(MENU.contact.default_name) && (
            <div className='row'>
              <div className='filter-item-name text-uppercase'>{LABEL_OF_CLIENT}</div>
              <SearchableInfiniteQuerySelect
                queryKey='client-list-task'
                // queryFn={clientService.clientList}
                queryFn={(params, page) =>
                  clientService.clientList({
                    ...params,
                    select: ['name', 'id'],
                    pageVo: {
                      pageNo: page,
                      noOfItems: 10,
                    },
                  })
                }
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  select: ['id', 'name'],
                })}
                // initialValue={clientData}
                className={` client-select-box `}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                isCustomFunction
                isClearable
                styles={CommonDropDownStyle}
                // isLoading={isClientLoading}
                placeholder={`Select ${LABEL_OF_CLIENT}`}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option.id}
                value={filter?.client ?? null}
                onChange={(selectedOptions) => {
                  handleFilterChange(selectedOptions, 'client');
                }}
              />
            </div>
          )}

          {hasExist(MENU.project.default_name) && (
            <div className='row'>
              <div className=' filter-item-name text-uppercase'>{LABEL_OF_PROJECT}</div>

              <SearchableInfiniteQuerySelect
                name='project_id'
                getOptionLabel={(option) => {
                  return option?.name;
                }}
                getOptionValue={(option) => {
                  return option?.id;
                }}
                queryFn={(params, page) =>
                  projectService.projectListAdvanced({
                    ...params,
                    select: ['name', 'id'],
                    listFor: 'active-inactive',
                    pageVo: {
                      pageNo: page,
                      noOfItems: 10,
                    },
                  })
                }
                // isMulti
                queryKey={['project-list-filter']}
                enableCondition={true}
                isCustomFunction
                getFunctionParams={(searchKey) => {
                  return {
                    searchKey: searchKey,
                    select: ['id', 'name'],
                  };
                }}
                onChange={(selectedOptions) => {
                  handleFilterChange(selectedOptions, 'project_id');
                }}
                styles={CommonDropDownStyle}
                value={filter?.project_id}
                placeholder={`Choose ${LABEL_OF_PROJECT}`}
                isClearable
              />
            </div>
          )}

          <div className='row'>
            <div className='filter-item-name text-uppercase'>ACTION</div>
            <ReactSelect
              options={task_action}
              components={{ DropdownIndicator: CustomDropdownIndicator }}
              placeholder={`Choose Action`}
              styles={CommonDropDownStyle}
              isClearable
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => parseInt(option?.value)}
              //   onInputChange={(inputString) => setProjectSearch(inputString)}
              //   inputValue={projectSearch}
              value={filter?.action ?? null}
              onChange={(selectedOptions) => {
                handleFilterChange(selectedOptions, 'action');
              }}
              //   isLoading={isProjectLoading}
            />
          </div>
        </div>

        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container
              className={'outline-cancel-button'}
              type='button'
              handleOk={handleClose}
            >
              <Cancel />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' className='blue-btn' isDisabled={false}>
              <Tick />
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
