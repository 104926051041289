import {
  BarChartBig,
  Calendar,
  CalendarClock,
  FileText,
  GanttChartSquare,
  Layers,
  LineChart,
  MessageSquareDot,
  PieChart,
} from 'lucide-react';
import React from 'react';
import { ChatIcon, InvoiceIcon, ProjectIconSidebar, SalesReportIcon } from '../../Utils/SvgIcons';
import { RT } from '../../_constants';

export const widgetIcons = {
  calendar: <Calendar color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  upcoming_deadlines: <CalendarClock color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  workload_statics: <BarChartBig color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  graphical_report: <LineChart color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  my_projects: (
    <ProjectIconSidebar
      mainColor='var(--brand-color)'
      strokeWidth={1.8}
      secondaryColor='var(--brand-color)'
      size={18}
    />
  ),
  chats: <ChatIcon color='var(--brand-color)' strokeWidth={1.3} height={18} width={18} />,
  updates: <MessageSquareDot color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  task_statics: <PieChart color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  status_counts: <GanttChartSquare color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  task_report: <FileText color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  template_report: <Layers color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  performance_report: <LineChart color='var(--brand-color)' strokeWidth={1.8} size={18} />,
  sales_report: <SalesReportIcon primeColor='var(--brand-color)' strokeWidth={1.3} size={18} />,
  customers: <InvoiceIcon primeColor='var(--brand-color)' strokeWidth={1.8} size={18} />,
};

export const layoutDropdownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('field-error');
    return {
      ...styles,

      // height: '34px',
      minHeight: '25px',
      height: '25px',
      backgroundColor: 'var(--bg-primary)',
      borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : 'transparent',
      boxShadow: 'var(--input-border-shadow)',
      padding: '0px 1px 0px 4px',
      borderRadius: '8px',
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      // height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
      color: '#7e7e7e',
      backgroundColor: 'var(--bg-primary)',
      // width:'fit-content'
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      width: '100%',
      color: '#7e7e7e',
    };
  },
  placeholder: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '600',
      color: '#7e7e7e',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
      color: '#7e7e7e',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: '25px',
      padding: '0px 8px',
    };
  },
  input: (styles) => {
    return {
      ...styles,
      padding: '0px',
    };
  },
};

export const getLink = (status, props, user) => {
  if (props?.userType === 'team') {
    return `${RT.TASK_LIST}?status=${
      status?.status_default_name ?? status?.default_name
    }&list_type=team-task&&${props?.template_id ? 'template_id=' + props?.template_id : ''}
    &&${props?.project_id ? 'project_id=' + props?.project_id : ''}`;
  }
  return `${RT.TASK_LIST}?status=${status?.status_default_name ?? status?.default_name}&&user_id=${
    user?.id
  }&list_type=my-task&&${props?.template_id ? 'template_id=' + props?.template_id : ''}
    &&${props?.project_id ? 'project_id=' + props?.project_id : ''}`;
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getLastFiveYears = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 5 }, (_, i) => currentYear - 4 + i);
};
export const customersFilterOptions = [
  { value: 1, label: 'Last Week', color: '#4786FF' },
  { value: 2, label: 'This Month', color: '#FF7E47' },
  { value: 3, label: 'All', color: '#3EC595' },
];

export const salesFilterOptions = [
  { value: 'month', label: 'Month', color: '#4786FF' },
  { value: 'year', label: 'Year', color: '#FF7E47' },
];
