/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../_components';
import { DOC, FROM_VALUE, INVOICE_MENUS, LEDGER_MENUS } from '../Invoice/InvoiceConstant';
import { filename, GlobalContext, Images } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';

export function Export({
  handleClose,
  from,
  handleOk,
  isPending,
  support = [DOC.PDF, DOC.EXCEL],
  duration,
  durationEnabled = false,
  defaultDuration = 3, //in month
  maxDuration = 3, //in month
  setDefaultDuration = false, //automatically set duration
  paginationActive = false,
  setPaginationActivate = () => {},
  responseData,
}) {
  const { makeAlert } = useContext(GlobalContext);

  let doc_name =
    from === FROM_VALUE.LEDGER ? 'ledger' : from === FROM_VALUE.INVOICE ? 'invoice' : 'document';

  const [selectedFormat, setSelectedFormat] = useState(support[0]);
  const [fileName, setFileName] = useState(`${doc_name}_${filename}`);
  const [menuItems, setMenuItems] = useState([]);

  // Default date range: Last 3 months
  const defaultStartDate = moment().subtract(defaultDuration, 'months').format('YYYY-MM-DD');
  const defaultEndDate = moment().format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(
    setDefaultDuration ? duration?.start_date || defaultStartDate : '',
  );
  const [endDate, setEndDate] = useState(
    setDefaultDuration ? duration?.end_date || defaultEndDate : '',
  );

  const [pageNumber, setPageNumber] = useState();
  const bottomRef = useRef(null);

  useEffect(() => {
    if (paginationActive && bottomRef.current) {
      setPageNumber(1);
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [paginationActive]); // Runs when paginationActive changes

  useEffect(() => {
    if (setDefaultDuration) {
      setStartDate(duration?.start_date || defaultStartDate);
      setEndDate(duration?.end_date || defaultEndDate);
    }
  }, [duration, setDefaultDuration]);

  useEffect(() => {
    return () => {
      setPaginationActivate(false); // Reset when component unmounts
    };
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date') setStartDate(value);
    if (name === 'end_date') setEndDate(value);
  };

  const returnMenus = (value) => {
    if (value === FROM_VALUE.LEDGER) {
      return LEDGER_MENUS;
    } else if (value === FROM_VALUE.INVOICE) {
      return INVOICE_MENUS;
    } else if (value === FROM_VALUE.CLIENT) {
      return INVOICE_MENUS;
    }
  };

  useEffect(() => {
    let menus = returnMenus(from);
    setMenuItems(menus);
  }, [from]);

  // Function to update custom_name or checkbox value
  const handleChange = (index, field, newValue) => {
    setMenuItems((prevItems) =>
      prevItems.map((item, i) => (i === index ? { ...item, [field]: newValue } : item)),
    );
  };

  const handleFormatChange = (format) => {
    setSelectedFormat(format);
  };

  const handleExport = (e) => {
    e.preventDefault();

    // Check if the date range is valid (not more than 3 months)
    // Convert dates to moment for validation
    const StartDate = moment(startDate);
    const EndDate = moment(endDate);
    if (durationEnabled && (!startDate || !endDate)) {
      makeAlert(`The duration cannot be empty`, 'err');
      return;
    }

    const MaxDuration = moment(StartDate).add(maxDuration, 'months');
    if (durationEnabled && EndDate.isAfter(MaxDuration)) {
      makeAlert(`The duration cannot be more than ${maxDuration} months.`, 'err');
      return;
    }

    if (paginationActive) {
      const pageNum = parseInt(pageNumber, 10);
      const totalPages = parseInt(responseData?.pages, 10);

      if (!pageNum || pageNum < 1 || pageNum > totalPages) {
        makeAlert(`Please enter a valid number`, 'err');
        return;
      }
    }

    // eslint-disable-next-line no-unused-vars
    const filteredLedgerMenus = menuItems?.filter((item) => item.value === true);

    let returnObj = {
      selectedFormat,
      menuItems: filteredLedgerMenus,
      fileName,
      pageNumber,
    };
    if (durationEnabled) {
      returnObj.startDate = startDate;
      returnObj.endDate = endDate;
    }

    handleOk(returnObj);
  };

  console.log('na');
  return (
    <div className='scroll-container'>
      <section style={{ marginTop: '8px', padding: '8px', overflow: 'auto' }}>
        {/* pdf and excel */}
        <div style={{ display: 'flex', gap: 8, marginBottom: 10 }}>
          <div
            className={`sml-crd ${selectedFormat === 'pdf' ? 'selected' : ''}`}
            onClick={() => handleFormatChange('pdf')}
            style={{
              pointerEvents: support.includes(DOC.PDF) ? 'auto' : 'none', // Disable click if not supported
              opacity: support.includes(DOC.PDF) ? 1 : 0.5, // Dim if not supported
              border: `1px solid var(--border-color)`,
              borderColor: selectedFormat === DOC.PDF ? 'red' : 'var(--border-color)',
            }}
          >
            <img
              src={Images.pdf}
              alt='PDF'
              style={{ height: 18, width: 18, objectFit: 'contain' }}
            />
            <span style={{ fontSize: 13, fontWeight: '500', marginLeft: 5 }}>PDF</span>
          </div>

          <div
            className={`sml-crd ${selectedFormat === 'excel' ? 'selected' : ''}`}
            onClick={() => handleFormatChange('excel')}
            style={{
              pointerEvents: support.includes(DOC.EXCEL) ? 'auto' : 'none', // Disable click if not supported
              opacity: support.includes(DOC.EXCEL) ? 1 : 0.5, // Dim if not supported
              border: `1px solid var(--border-color)`,
              borderColor: selectedFormat === 'excel' ? 'green' : 'var(--border-color)',
            }}
          >
            <img
              src={Images.excel}
              alt='Excel'
              style={{ height: 18, width: 18, objectFit: 'contain' }}
            />
            <span style={{ fontSize: 13, fontWeight: '500', marginLeft: 5 }}>Excel</span>
          </div>
        </div>

        {/* date */}

        {durationEnabled && (
          <div className='row'>
            <label style={{ marginBottom: 10, marginTop: 10, fontWeight: '500', fontSize: 13 }}>
              Duration
            </label>
            <div className='col-md-6'>
              <div>
                <label className='mb-1 menu-item-clear'>From</label>
                <input
                  type='date'
                  className='input-box'
                  style={{ height: 34, fontSize: 13 }}
                  value={startDate}
                  name='start_date'
                  onChange={handleDateChange}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div>
                <label className='mb-1 menu-item-clear'>To</label>
                <div>
                  <input
                    type='date'
                    className='input-box'
                    style={{ height: 34, fontSize: 13 }}
                    value={endDate}
                    name='end_date'
                    onChange={handleDateChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* date */}
        {/* pdf and excel */}

        <label style={{ marginBottom: 10, marginTop: 10, fontWeight: '500', fontSize: 13 }}>
          File name
        </label>
        <div>
          <input
            type='text'
            className='inp-head'
            value={fileName}
            style={{ fontSize: 13 }}
            onChange={(e) => setFileName(e.target.value)}
          />
        </div>
        {/* <div className='inp-head'>Report_15-5-2010</div> */}
        <label style={{ marginBottom: 20, fontWeight: '500', fontSize: 13 }}>Select fields</label>
        {menuItems?.map((menu, index) => (
          <section className='export-inp-container' key={index}>
            <div style={{ width: '100%', position: 'relative' }}>
              <div className='label-input'>{menu?.label_name}</div>
              <input
                type='text'
                className='export_text'
                value={menu.custom_name}
                style={{ fontSize: 13 }}
                onChange={(e) => handleChange(index, 'custom_name', e.target.value)}
              />
            </div>
            <input
              className='menu_check_box'
              type='checkbox'
              checked={menu.value}
              onChange={(e) => handleChange(index, 'value', e.target.checked)}
            />
          </section>
        ))}

        {paginationActive && (
          <>
            <div style={{ color: 'red' }}>
              More than 1000 records detected. Pagination has been enabled
            </div>
            <div
              style={{ marginTop: 5, marginBottom: 5 }}
            >{`You are on page ${responseData?.page} of ${responseData?.pages}.`}</div>
            <input
              type='text'
              onChange={(e) => setPageNumber(e.target.value)}
              className='pagination_text'
              style={{ fontSize: 13 }}
              value={pageNumber}
            />

            <div ref={bottomRef}></div>
          </>
        )}
      </section>
      <div className='modal_footer'>
        <div className=' d-flex justify-content-end gap-2'>
          <Button.Container className={'cancel-btn'} type='reset' handleOk={() => handleClose()}>
            <Cancel />
            <Button.Title title={'Cancel'} />
          </Button.Container>

          <Button.Container handleOk={(e) => handleExport(e)} type='submit' isDisabled={isPending}>
            <Tick /> <Button.Title title={'Export'} />
            <Button.Loading isLoading={isPending} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
}
