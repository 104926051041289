import { useMutation } from '@tanstack/react-query';
import React, { useContext } from 'react';
import useDebounceAction from '../Hook/useDebounceAction';
import { getEmojiListFromObject, GlobalContext } from '../Utils';
import { CHAT_UPDATE_TYPE, MESSAGE_TYPES } from '../_constants/chat.constants';
import { chatService } from '../_services/chat.service';
import { BoxMessageCards } from './ChatAttachments/BoxMessageCards';
import { ChatMessageCards } from './ChatAttachments/ChatMessageCards';
import { ChatContext } from './ChatProvider';
import { ForwardOption } from './ChatUpdate/ForwardOption';
import { MessageViewedUserList } from './ChatUpdate/MessageViewedUserList';

function MessageItem({ data, handlers, state, refs, isInTask }) {
  const { item, index, showDate, messageDate, isConsecutiveUserMsg = false } = data;
  const {
    onRetry,
    setPreviewIndex,
    setSelectedPosition,
    setIsContextVisible,
    onReactionOutsideClick,
    onReactionUpdate,
    onInfoOutsideClick,
    onForwardOutsideClick,
  } = handlers;
  const { reactionIndex, selectedPosition, infoVisibleIndex, forwardVisibleIndex } = state;
  const { replyMessageRef, messageListRef } = refs;
  const { type: chatType } = useContext(ChatContext);

  const emojiList = getEmojiListFromObject(item.reactions_count);

  const { globalSettings } = useContext(GlobalContext);

  const chatSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'chat-settings')?.labels;

  const hasExist = (name) => {
    const setting = chatSettings?.find((label) => label.label === name);

    return setting ? setting.value : undefined;
  };

  const { mutate } = useMutation({
    mutationKey: ['reaction-update'],
    mutationFn: ({ emoji, id }) => chatService.updateMessageReaction({ payload: { emoji, id } }),
    onSuccess: (response) => {
      if (response.data.length > 0) {
        const id = response.data[0].id;
        const reaction_count = { reactions_count: response.data[0].reactions_count };
        onReactionUpdate(id, reaction_count);
      }
    },
  });

  const { runDebounceFunction } = useDebounceAction((clickCount, param) => {
    //Only run if click is odd
    if (clickCount % 2 !== 0) {
      mutate(param);
    }
  }, 500);

  const handleReplyClick = (id) => {
    if (replyMessageRef.current[id]) {
      replyMessageRef.current[id].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      // Will highlight the reply selected message
      replyMessageRef.current[id].classList.add('msg-highlight');
      setTimeout(() => {
        replyMessageRef.current[id].classList.remove('msg-highlight');
      }, 1500);
    }
  };

  const isSlackLook = false;
  const showProfile = chatType !== CHAT_UPDATE_TYPE.PERSONAL;
  const isMsgSeparated = true;

  return (
    <React.Fragment>
      {/* If upload/sending failed */}
      {item.isFailed && (
        <div role='button' onClick={() => onRetry(item)} className='send-error btn-wrapper'>
          Sending Failed. Retry
        </div>
      )}
      <div className={`reaction-msg-card-wrapper ${index === 0 ? 'mb-1' : ''} position-relative`}>
        {/* Box styled view */}
        {!isSlackLook && (
          <BoxMessageCards.MessageCardWrapper
            hide={
              item?.type === MESSAGE_TYPES.CHAT_CREATION ||
              item?.type === MESSAGE_TYPES.REMINDER ||
              item.type === MESSAGE_TYPES.STATUS_CHANGE ||
              item.type === MESSAGE_TYPES.START_STOP_CHANGE
            }
            hasEmoji={emojiList?.length > 0 || reactionIndex === index}
          >
            <BoxMessageCards.MessageCardContainer
              selectedPosition={selectedPosition}
              isOtherUser={!item.is_you}
              separatedMsgs={isMsgSeparated}
              showProfile={showProfile}
              replyRef={(el) => (replyMessageRef.current[item.id] = el)}
              isConsecutiveUserMsg={isConsecutiveUserMsg}
              data={item}
              onMoreOptionClick={(x, y) => {
                setSelectedPosition({ x, y, item, index });
                setIsContextVisible(true);
              }}
            >
              {item.reply_chat_id > 0 && (
                <BoxMessageCards.ReplyCard
                  onClick={() => handleReplyClick(item.reply_chat_id)}
                  separatedMsgs={isMsgSeparated}
                  isOtherUser={!item.is_you}
                >
                  <BoxMessageCards.ReplyItem data={item.replyDetails} />
                </BoxMessageCards.ReplyCard>
              )}

              {false && <ChatMessageCards.Reminder data={item} />}
              {false && <ChatMessageCards.Request file={item} />}
              <div className='chat-attachment-container mt-1'>
                {/* Attachments */}
                {item?.files?.length > 0 &&
                  item.files.map((file, fileIndex) => {
                    return (
                      <>
                        <ChatMessageCards.Image
                          onClick={() =>
                            setPreviewIndex({
                              item,
                              fileIndex,
                              isVisible: true,
                            })
                          }
                          file={file}
                          isLocal={Boolean(item.tempId)}
                        />
                        <ChatMessageCards.Video file={file} isLocal={Boolean(item.tempId)} />
                        <ChatMessageCards.Document file={file} isLocal={Boolean(item.tempId)} />
                        <ChatMessageCards.Audio file={file} isLocal={Boolean(item.tempId)} />
                        <BoxMessageCards.RecordedAudio file={file} isLocal={Boolean(item.tempId)} />
                      </>
                    );
                  })}
              </div>
              <ChatMessageCards.Text data={item} />
              {infoVisibleIndex === item?.id && (
                <MessageViewedUserList
                  id={infoVisibleIndex}
                  handleClose={() => onInfoOutsideClick()}
                  messageListRef={messageListRef}
                />
              )}
              {forwardVisibleIndex === item?.id && (
                <ForwardOption
                  id={forwardVisibleIndex}
                  handleClose={() => onForwardOutsideClick()}
                  messageListRef={messageListRef}
                  isInTask={isInTask}
                />
              )}
              <div style={{ position: 'absolute', left: 10, top: 'calc(100% - 15px)' }}>
                <BoxMessageCards.SelectedReactions
                  emojiList={emojiList}
                  onClick={(emoji) => runDebounceFunction({ emoji, id: item.id })}
                />
                <ChatMessageCards.DefaultReactions
                  isVisible={reactionIndex === index}
                  onClose={onReactionOutsideClick}
                  onSelect={(emoji) => {
                    runDebounceFunction({ emoji, id: item.id });
                    onReactionOutsideClick();
                  }}
                />
              </div>
            </BoxMessageCards.MessageCardContainer>
          </BoxMessageCards.MessageCardWrapper>
        )}

        {/* Slack styled view */}

        {isSlackLook && (
          <ChatMessageCards.Container
            hideContainer={item.type === MESSAGE_TYPES.CHAT_CREATION}
            // key={index}
            replyRef={(el) => (replyMessageRef.current[item.id] = el)}
          >
            <ChatMessageCards.MessageCard
              animDelay={index * 40}
              containerClass={`${item?.isUploading ? 'blink' : ''} ${index <= 20 ? 'fade-in' : ''}`}
              isSender={item.creator_id != 1}
            >
              <ChatMessageCards.SenderDetailsSection
                data={item}
                isSending={item?.isUploading}
                onMoreOptionClick={(x, y) => {
                  setSelectedPosition({ x, y, item, index });
                  setIsContextVisible(true);
                }}
              >
                {item.reply_chat_id > 0 && (
                  <BoxMessageCards.ReplyCard onClick={() => handleReplyClick(item.reply_chat_id)}>
                    <BoxMessageCards.ReplyItem
                      isUserSame={
                        parseInt(item.creator_details.id) ===
                        parseInt(item.replyDetails.creator_details.id)
                      }
                      data={item.replyDetails}
                    />
                  </BoxMessageCards.ReplyCard>
                )}

                <ChatMessageCards.Text data={item} />
                {<ChatMessageCards.Reminder data={item} />}
                {false && <ChatMessageCards.Request file={item} />}
                <div className='chat-attachment-container mt-1'>
                  {/* Attachments */}
                  {item?.files?.length > 0 &&
                    item.files.map((file, fileIndex) => {
                      return (
                        <>
                          <ChatMessageCards.Image
                            onClick={() =>
                              setPreviewIndex({
                                item,
                                fileIndex,
                                isVisible: true,
                              })
                            }
                            file={file}
                            isLocal={Boolean(item.tempId)}
                          />
                          <ChatMessageCards.Video file={file} isLocal={Boolean(item.tempId)} />
                          <ChatMessageCards.Document file={file} isLocal={Boolean(item.tempId)} />
                          <ChatMessageCards.Audio file={file} isLocal={Boolean(item.tempId)} />
                          <ChatMessageCards.RecordedAudio
                            file={file}
                            isLocal={Boolean(item.tempId)}
                          />
                        </>
                      );
                    })}
                </div>
              </ChatMessageCards.SenderDetailsSection>
            </ChatMessageCards.MessageCard>
          </ChatMessageCards.Container>
        )}

        {/* Message card ends here */}

        {isSlackLook && (
          <>
            <ChatMessageCards.SelectedReactions
              countObj={item.reactions_count}
              onClick={(emoji) => runDebounceFunction({ emoji, id: item.id })}
            />

            <ChatMessageCards.DefaultReactions
              isVisible={reactionIndex === index}
              onClose={onReactionOutsideClick}
              onSelect={(emoji) => {
                runDebounceFunction({ emoji, id: item.id });
                onReactionOutsideClick();
              }}
            />
          </>
        )}
      </div>
      <ChatMessageCards.StatusChangeInfo info={item} showMessage={hasExist} />

      <ChatMessageCards.InfoCard data={item} />
      <ChatMessageCards.DateCard zIndex={0} showDate={showDate} date={messageDate} />
    </React.Fragment>
  );
}

export default MessageItem;
