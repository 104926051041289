/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../Utils/Contexts';
import { LoadingIcon, Tick } from '../Utils/SvgIcons';
import { Button, ToggleButton } from '../_components';
import NoPermission from '../_components/NoPermission';
import { ViewImageModal } from '../_components/ViewImageModal';
import { LIST_PERMISSION_VALUE } from '../_constants';
import { settingsService } from '../_services';
import '../common.css';
import { GENERAL_SETTINGS } from './SettingsLabels';
import {
  ColorPicker,
  Container,
  CroppableFileUpload,
  Description,
  FileUpload,
  Heading,
  ToggleWithHeading,
} from './UtilsInSettings';

export function GeneralSettings() {
  //-- hooks
  const { makeAlert, roleBasedPermission, theme, toggleTheme } = useContext(GlobalContext);

  //-- state
  const [query] = useState({ select: ['name', 'labels'], name: 'general-settings' });

  const {
    data: generalSettingsData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['general-settings'],
    queryFn: () => settingsService.settingsGet(query),
    select: (data) => {
      return data?.data[0];
    },
  });

  useEffect(() => {
    if (error?.message) {
      makeAlert(error?.message);
    }
  }, [error?.message]);

  // NO ACCESS MANAGE
  if (!roleBasedPermission?.ACTION_ORG_MANAGE) {
    return <NoPermission />;
  }

  return (
    <section className='common_contain '>
      {isLoading && (
        <div className='loading-wrapper'>
          <LoadingIcon size={70} />
        </div>
      )}
      {isSuccess && <GeneralForm refetch={refetch} initialData={generalSettingsData?.labels} />}
    </section>
  );
}

// ==============================================
// form component
const GeneralForm = ({ initialData, refetch }) => {
  //-- hooks
  const { makeAlert, globalSettingsRefetch, roleBasedPermission, theme, toggleTheme } =
    useContext(GlobalContext);
  const isMutating = useIsMutating();

  // state
  const [generalSettings, setGeneralSettings] = useState(initialData || GENERAL_SETTINGS);
  const [hasChanged, setHasChanged] = useState(false);

  //-- effect
  useEffect(() => {
    // Check for changes in generalSettings whenever it updates
    setHasChanged(
      JSON.stringify(generalSettings) !== JSON.stringify(initialData || GENERAL_SETTINGS),
    );
  }, [generalSettings]);

  // handle change for color and slogan
  const handleChange = (id, value) => {
    setGeneralSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value } : label)),
    );
  };

  const handleToggle = (id, value) => {
    setGeneralSettings((prevLabels) =>
      prevLabels.map((label) => (label.id === id ? { ...label, value: !value } : label)),
    );
  };

  // general settings update
  const handleSettingUpdate = async (data) => {
    let newQuery = {};
    newQuery.labels = data;
    newQuery.name = 'general-settings';
    await settingsService.settingsAdd(newQuery);
    makeAlert('Success');
    setHasChanged(false);

    globalSettingsRefetch();
    refetch();
    return newQuery;
  };

  // --react query fot mutation function
  const { mutate: handleMutate, isError } = useMutation({
    mutationFn: handleSettingUpdate,
  });

  // --manage file upload
  const getFiles = (files, name) => {
    setGeneralSettings((prevLabels) => {
      let changedData = prevLabels.map((label) =>
        label.id === name ? { ...label, value: files[0] } : label,
      );
      return changedData;
    });
  };

  // NO ACCESS MANAGE
  if (roleBasedPermission?.ACTION_ORG_MANAGE === LIST_PERMISSION_VALUE.NO_ACCESS) {
    return <NoPermission />;
  }

  return (
    <>
      <Container>
        <section className='flex-1'>
          <Heading heading={'Switch to dark theme'} />
          {/* {description && <Description description={description} />} */}
        </section>
        <ToggleButton
          // updateOnBlur={toggleTheme}
          isChecked={theme === 'dark'}
          handleToggle={toggleTheme}
          size={35}
        />
      </Container>

      {generalSettings?.map((GENERAL, index) => {
        console.log({ GENERAL });

        if (GENERAL.type === 'color-picker')
          return (
            <Container key={index}>
              <ColorPicker
                value={GENERAL?.value}
                han
                handleChange={(e) => handleChange(GENERAL.id, e.target.value)}
                heading={GENERAL.label}
                id={GENERAL.id}
                description={GENERAL?.description}
              />
            </Container>
          );
        if (GENERAL.type === 'file-upload')
          return (
            <Container key={index}>
              <FileUpload
                handleOk={getFiles}
                value={GENERAL.value}
                id={GENERAL.id}
                heading={GENERAL.label}
                description={GENERAL?.description}
              />
              {GENERAL.value && <ViewImageModal imageData={GENERAL} />}
            </Container>
          );
        if (GENERAL.type === 'croppable-file-upload')
          return (
            <Container key={index}>
              <CroppableFileUpload
                handleOk={getFiles}
                value={GENERAL.value}
                id={GENERAL.id}
                heading={GENERAL.label}
                description={GENERAL?.description}
              />

              {GENERAL.value && <ViewImageModal imageData={GENERAL} />}
            </Container>
          );
        if (GENERAL.type === 'text-input')
          return (
            <Container key={GENERAL.id}>
              <section className='flex-1'>
                <Heading heading={GENERAL?.label} />
                {GENERAL?.description && <Description description={GENERAL?.description} />}
              </section>
              <div>
                <input
                  onChange={(e) => handleChange(GENERAL.id, e.target.value)}
                  type='text'
                  className=' form-control settings-text-input'
                  placeholder={GENERAL?.placeholder}
                  defaultValue={GENERAL?.value}
                />
              </div>
            </Container>
          );
        if (GENERAL.type === 'toggle')
          return (
            <ToggleWithHeading
              className='sub_container'
              key={GENERAL?.id}
              id={GENERAL?.id}
              handleToggle={() => handleToggle(GENERAL?.id, GENERAL.value)}
              isChecked={GENERAL.value}
              heading={GENERAL.label}
            />
          );
      })}

      {hasChanged && (
        <Button.Container
          className={'sett-save-btn'}
          handleOk={() => handleMutate(generalSettings)}
        >
          <Tick />
          <Button.Title title={'Save & Proceed'} />
          <Button.Loading isLoading={isMutating} />
        </Button.Container>
      )}
    </>
  );
};
