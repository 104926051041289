// import { useQuery } from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { Popover } from '../../_components/Popover';
import { UserGroupSelectionPopup } from '../../_components/UserGroupSelectionPopup';
import { COMMON_USER_SELECT, USER_LIST_ACTIONS } from '../../_constants';
import { CHAT_UPDATE_TYPE } from '../../_constants/chat.constants';
import useDebounce from '../../_helpers/useDebounce';
import { userService } from '../../_services';
import { chatService } from '../../_services/chat.service';
import useObserver from '../../Hook/useObserver';
import { useUser } from '../../Hook/useUser';
import { getChatUpdateRowData, GlobalContext } from '../../Utils';

export const ForwardOption = ({ id, handleClose = () => {}, messageListRef, isInTask }) => {
  const queryClient = useQueryClient();
  const user_id = useUser().id;
  const [searchKey, setSearchKey] = useState('');

  const debouncedSearchKey = useDebounce(searchKey, 500);
  const { makeAlert } = useContext(GlobalContext);

  const {
    data: groupData,
    // isError,
    // error,
    isLoading: groupLoading,
    // isSuccess,
    hasNextPage: groupHasNextPage,
    fetchNextPage: groupFetchNextPage,
    refetch: groupRefetch,
  } = useInfiniteQuery({
    queryKey: ['chat-update-list', debouncedSearchKey],
    queryFn: ({ pageParam = 1 }) =>
      chatService.getChatUpdateListAdvanced({
        payload: {
          //   ...(userId ? { user_id: userId } : {}),
          searchKey: debouncedSearchKey,
          //   unread: isUnread,
          select: [
            'id',
            // 'last_message',
            // 'last_message_time',
            'chat_update_id',
            'project_id',
            'group_id',
            'team_id',
            'task_id',
            'update_type',
            'group_name',
            'project_name',
            'task_name',
            'user_ids',
          ],
          update_type: CHAT_UPDATE_TYPE.GROUP,
          //   ...chatUpdateAdditionalPayloads,
          //   ...(searchUpdateId ? { id: searchUpdateId } : {}),
          pageVo: {
            sort: 'last_message_time',
            sort_order: 'DESC',
            noOfItems: 10,
            pageNo: pageParam,
          },
        },
      }),
    select: (res) => res.pages,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
  });

  const { observerRef: groupObserverRef } = useObserver({
    onIntersection: () => groupHasNextPage && groupFetchNextPage(),
    dependency: [groupHasNextPage],
  });

  const flattedGroupUpdateData = React.useMemo(
    () => groupData?.flatMap((page) => page.data.rows),
    [groupData],
  );

  const groupList = flattedGroupUpdateData ? [...flattedGroupUpdateData] : [];
  const finalGroupList = groupList?.map((group) => {
    const { title, image, gender = 1 } = getChatUpdateRowData(group);
    return {
      first_name: title,
      last_name: '',
      type: CHAT_UPDATE_TYPE.GROUP,
      image_url: image,
      gender: gender,
      ...group,
    };
  });

  const {
    data: chatUserList,
    hasNextPage: userHasNextPage,
    fetchNextPage: userFetchNextPage,
    refetch: userRefetch,
    isLoading: userLoading,
  } = useInfiniteQuery({
    queryKey: ['update-user-list', debouncedSearchKey],
    queryFn: ({ pageParam = 1 }) =>
      userService.getNonRoleBasedUserList({
        action: USER_LIST_ACTIONS.ACTION_EVERY_USER,
        select: COMMON_USER_SELECT,
        searchKey: debouncedSearchKey,
        remove_id: user_id,
        pageVo: {
          noOfItems: 10,
          pageNo: pageParam,
        },
        // ...(userId ? { id: userId } : {}),
      }),
    // select: (res) => res.data.rows,
    select: (res) => res.pages,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,

    // enabled: Boolean(debouncedSearchKey || hasUserId),
  });
  const { observerRef: userObserverRef } = useObserver({
    onIntersection: () => userHasNextPage && userFetchNextPage(),
    dependency: [userHasNextPage],
  });

  const flattedUserUpdateData = React.useMemo(
    () => chatUserList?.flatMap((page) => page.data.rows),
    [chatUserList],
  );

  const userList = flattedUserUpdateData ? [...flattedUserUpdateData] : [];

  // save api call
  const handleSave = async (forwardToList) => {
    await chatService.forwardChatMessage({ id: id, forwardTo: forwardToList });
  };

  const {
    mutate: handleMutate,
    // isPending,
    // isError,
    // error,
    // isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleSave,
    mutationKey: ['forward-message'],
    onSuccess: async () => {
      handleClose();
      makeAlert('Message forward successful');
      if (isInTask) {
        await queryClient.invalidateQueries('chat-update-list');
      }
    },
    onError: (err) => {
      // setIsLoading(false);
      makeAlert(err?.message);
    },
  });

  // handle submit button
  const handleSubmit = async (event, forwardToList) => {
    console.log(forwardToList, 'aaa');

    event.preventDefault();
    // setIsLoading(true);

    try {
      handleMutate(forwardToList);
    } catch (error) {
      handleClose();
      makeAlert('forward failed');
    } finally {
      // setIsLoading(false);
      // setSubmitted(false);
    }
  };

  return (
    // <Popover.PopoverItem>
    <Popover.PopoverItem
      scrollRef={messageListRef}
      positionLeft={(parentWidth, childWidth) => -childWidth - 20}
      positionTop={(height) => -height}
    >
      <UserGroupSelectionPopup
        id={id}
        handleClose={handleClose}
        groupRefetch={groupRefetch}
        userRefetch={userRefetch}
        groupObserverRef={groupObserverRef}
        userObserverRef={userObserverRef}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        userList={userList}
        userLoading={userLoading}
        groupLoading={groupLoading}
        groupList={finalGroupList}
        handleSave={handleSubmit}
      />
    </Popover.PopoverItem>
  );
};
