import { Buffer } from 'buffer';
import { X } from 'lucide-react';
import React, { useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { miscService } from '../_services';
import { compressImageOnly, getFileType, sanitizeFileName } from '../Utils';
import { Cancel, Tick } from '../Utils/SvgIcons';
import { Button } from './CustomButton';
// import 'cropperjs/dist/cropper.css';

export const ImageCrop = ({
  handleClose = () => {},
  setImage,
  image,
  setIsPreviewOpen,
  uploadCroppedImage = () => {},
  file,
}) => {
  const imageRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);

  //use to crop image choose
  const onCrop = () => {
    const imageElement = imageRef?.current;
    const cropper = imageElement?.cropper;
    setImage((prev) => ({
      ...prev,
      imageUrl: cropper.getCroppedCanvas().toDataURL(),
    }));
  };
  //function to change url to file
  function dataUrlToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
  }
  const handleFileUpload = async (data) => {
    if (!data.length) {
      return;
    }
    const uploadedData = [];
    const compressedData = { compressedImageBlobUrl: '', fileNameCompressed: '' };

    for (let fileObj of data) {
      try {
        if (fileObj.fileType === 'image') {
          let { compressedFile, compressedImageBlobUrl } = await compressImageOnly(fileObj.file);

          let signedDataCompressed = await miscService.createSignedUploadUrl({
            type: fileObj.fileType, //--video,image,audio
            ext: fileObj.extension, //--jpg or mp4
            name: `${fileObj.sanitizeName}_compressed`,
          });

          if (signedDataCompressed?.data?.signedUrl) {
            let signedUrlCompressed = signedDataCompressed.data.signedUrl;
            let fileNameCompressed = signedDataCompressed.data.filename;

            // Upload the compressed file
            await fetch(signedUrlCompressed, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: compressedFile,
            }).then((response) => response.text());

            compressedData.fileNameCompressed = fileNameCompressed;
            compressedData.compressedImageBlobUrl = compressedImageBlobUrl;

            // compressedBlobData.push(compressedImageBlobUrl);
          }
        }

        let signedData = await miscService.createSignedUploadUrl({
          type: fileObj.fileType, //--video,image,audio
          ext: fileObj.extension, //--jpg or mp4
          name: fileObj.sanitizeName,
        });

        if (signedData?.data?.signedUrl) {
          let signedUrl = signedData.data.signedUrl;
          let fileName = signedData.data.filename;

          await fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: fileObj.file,
          }).then((response) => response.text());

          uploadedData.push(fileName);
        }
      } catch (error) {
        console.log({ error });
      }
    }
    return { uploadedData, compressedData };
  };

  const onFileSelect = async (fileData) => {
    let cloneSelectedFiles = [];
    cloneSelectedFiles.push({
      name: fileData.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(fileData.name),
      fileType: getFileType(fileData.name.split('.').pop()),
      size: fileData.size,
      extension: fileData.name.split('.').pop(),
      file: fileData,
    });
    const response = await handleFileUpload(cloneSelectedFiles);
    return response;
  };

  const editProfileImage = async () => {
    setIsUploading(true);
    const data = dataUrlToFile(image?.imageUrl, image?.imageFile?.name);
    const { compressedData, uploadedData } = await onFileSelect(data);
    const img = uploadedData.pop();
    uploadCroppedImage(img, compressedData);

    // makeAlert(error?.message);
    setIsUploading(false);
    setIsPreviewOpen(false);
  };
  return (
    <div className='image-preview-container'>
      <div className='image-preview-card' style={{ borderRadius: 'var(--border-radius)' }}>
        <div className='modal_header' style={{ borderRadius: 'var(--border-radius)' }}>
          <div className='header-modal'></div>
          <X
            size={20}
            color='#9197a3'
            onClick={() => {
              handleClose();
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className=' modal_view_content'>
          <Cropper
            crop={onCrop}
            src={file}
            initialAspectRatio={1}
            aspectRatio={1}
            ref={imageRef}
            style={{ maxHeight: '50vh' }}
          />
        </div>

        <div className='modal_footer' style={{ borderRadius: 'var(--border-radius)' }}>
          <div className=' d-flex gap-2'>
            <Button.Container
              className={'cancel-btn'}
              type='reset'
              handleOk={() => {
                handleClose();
              }}
            >
              <Cancel />
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container handleOk={editProfileImage} type='submit' isDisabled={isUploading}>
              <Tick />
              <Button.Title title={isUploading ? 'Saving...' : 'Save'} />
              <Button.Loading isLoading={isUploading} />
            </Button.Container>
          </div>
        </div>
      </div>
    </div>
  );
};
