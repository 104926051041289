/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Bell } from 'lucide-react';
import React from 'react';
import { notificationService } from '../_services/notification.service';
import { useUser } from '../Hook/useUser';
import ToolTip from './Tooltip/ToolTip';

export default function NotificationBell({
  isNotificationVisible,
  setIsNotificationVisible,
  children,
}) {
  const user = useUser();
  // eslint-disable-next-line no-unused-vars
  const { data: notificationCountData, refetch } = useQuery({
    queryKey: ['notificationCount'],
    refetchInterval: 20000,
    queryFn: () => notificationService.getNotificationCount(),
    enabled: user?.id ? true : false,
  });

  let NotificationCount =
    notificationCountData?.data?.latestNotificationCount > 999
      ? '999+'
      : notificationCountData?.data?.latestNotificationCount?.toString();

  NotificationCount = NotificationCount?.toString();

  const marginRightValue =
    NotificationCount?.length === 4
      ? 13
      : NotificationCount?.length === 3
      ? 10
      : NotificationCount?.length === 2
      ? 0
      : NotificationCount?.length === 1
      ? 0
      : 0;

  const handleClickUpdate = async () => {
    setIsNotificationVisible((prev) => !prev);
    await notificationService.notificationClick();
    refetch();
  };

  // --react query for mutation function
  const {
    mutate: handleMutate,
    isPending,
    isError,
    error,
    isPaused, //-- true when network offline mode
  } = useMutation({
    mutationFn: handleClickUpdate,
    mutationKey: ['notification-click'],
  });

  return (
    <div
      className='notification-header-wrap'
      style={{
        marginRight: marginRightValue,
      }}
    >
      {Boolean(parseInt(NotificationCount)) && (
        <div
          className='total_notification_count'
          style={{ position: 'absolute', marginLeft: '9px', marginTop: '-6px' }}
        >
          {NotificationCount}
        </div>
      )}

      <div className='d-flex pointer bell-clickable  header-menu-icon'>
        {/* <img className='tab-img' src={Images.Dashboard} /> */}
        <ToolTip isModern tooltipText={'Notifications'} hideTooltip={isNotificationVisible}>
          <Bell
            size={19}
            onClick={() => {
              handleMutate();
              // setIsNotificationVisible((prev) => !prev);
            }}
          />
        </ToolTip>
        {isNotificationVisible && children}
      </div>
    </div>
  );
}
