export const PRIMARY_CONTACT = [
  {
    name: 'Mr.',
    id: 1,
  },
  {
    name: 'Mrs.',
    id: 2,
  },
  {
    name: 'Ms.',
    id: 3,
  },
  {
    name: 'Miss.',
    id: 4,
  },
  {
    name: 'Mx.',
    id: 5,
  },
];

export const CLIENT_TYPE_LIST = [
  {
    name: 'Business',
    id: 1,
  },
  {
    name: 'Individual',
    id: 2,
  },
  {
    name: 'Firm',
    id: 3,
  },
  {
    name: 'Society - Trust - AOP',
    id: 4,
  },
  {
    name: 'Company',
    id: 5,
  },
  {
    name: 'Firm-LLP',
    id: 6,
  },
];

export const CLIENT_TYPE = {
  BUSINESS: 1,
  INDIVIDUAL: 2,
};

export const REGION_LIST = [
  { name: 'United States of America ', id: 1, value: 'USA' },
  { name: 'Canada', id: 2, value: 'CND' },
  { name: 'Australia', id: 3, value: 'AST' },
  { name: 'India', id: 4, value: 'IND' },
  { name: 'United Kingdom', id: 7, value: 'UK' },
  { name: 'EUROPE', id: 6, value: 'EU' },
  { name: 'Other/Generic', id: 7, value: 'OTHER' },
];

export const REGION_BASED_TAX_TREATMENT_LIST = {
  US: [
    {
      id: 1,
      name: 'In-State Sale',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      unique_fields: {
        nexus_requirement: true,
        reseller_permit_number: false,
      },
      other_info:
        'When the seller and buyer are in the same state (and the seller has nexus). Sales tax rate depends on the specific state/county/city.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 2,
      name: 'Out-of-State Sale',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      unique_fields: {
        nexus_requirement: true,
        reseller_permit_number: false,
      },
      other_info:
        "If the seller has economic or physical nexus in the buyer’s state, charge that state's tax. Otherwise, no sales tax.",
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 3,
      name: 'Tax-Exempt or Resale',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: 'Resale/Exempt Certificate',
      unique_fields: {
        nexus_requirement: true,
        reseller_permit_number: true,
      },
      is_export: true,
      tax_not_applicable: true,
      other_info:
        'Buyer provides a valid resale or tax-exempt certificate. No sales tax is charged.',
    },
  ],
  CA: [
    {
      id: 1,
      name: 'GST/HST-Registered Business',
      is_tax_number_required: true,
      place_of_supply_required: true,
      tax_number_label: 'GST/HST Number',
      unique_fields: {
        province_specific_rules: true,
      },
      other_info:
        'Collect GST or HST based on the province of supply. Some provinces also have PST or QST.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 2,
      name: 'Non-Registered Business or Consumer (B2C)',
      is_tax_number_required: false,
      place_of_supply_required: true,
      tax_number_label: '',
      unique_fields: {
        province_specific_rules: true,
      },
      other_info:
        'Charge GST/HST/PST/QST based on province. No tax number is required from the customer.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 3,
      name: 'Outside Canada (Export)',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      unique_fields: {
        province_specific_rules: false,
      },
      other_info: 'Zero-rated if shipped outside Canada. Proof of export may be required.',
      is_export: true,
      tax_not_applicable: true,
    },
  ],
  AU: [
    {
      id: 1,
      name: 'GST-Registered Business',
      is_tax_number_required: true,
      place_of_supply_required: true,
      tax_number_label: 'ABN (GST Registered)',
      other_info:
        '10% GST applies unless zero-rated or exempt (e.g., certain food, health, education).',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 2,
      name: 'Non-Registered Business or Consumer',
      is_tax_number_required: false,
      place_of_supply_required: true,
      tax_number_label: '',
      other_info:
        'For domestic B2C, typically charge 10% GST. If the seller is not registered for GST, no GST is collected.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 3,
      name: 'Export (Outside Australia)',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      other_info: 'Usually zero-rated exports. Must keep export documentation for compliance.',
      is_export: true,
      tax_not_applicable: true,
    },
  ],
  IN: [
    {
      id: 1,
      name: 'Registered Business - Regular',
      is_tax_number_required: true,
      place_of_supply_required: true,
      tax_number_label: 'GSTIN',
      other_info: 'Charges CGST+SGST (intra-state) or IGST (inter-state).',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 2,
      name: 'Registered Business - Composition',
      is_tax_number_required: true,
      place_of_supply_required: true,
      tax_number_label: 'GSTIN',
      other_info: 'Composition scheme; invoices typically do not show tax separately.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 3,
      name: 'Unregistered',
      is_tax_number_required: false,
      place_of_supply_required: true,
      tax_number_label: '',
      other_info:
        'No GSTIN. May still charge GST if the supplier is registered, depending on B2C transaction rules.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 4,
      name: 'Overseas (Export)',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      other_info: 'Zero-rated supply if goods/services are exported outside India.',
      is_export: true,
      tax_not_applicable: true,
    },
  ],
  GB: [
    {
      id: 1,
      name: 'VAT-Registered Business',
      is_tax_number_required: true,
      place_of_supply_required: true,
      tax_number_label: 'VAT Number',
      other_info: 'Standard rate is 20%, reduced rates apply to specific goods/services.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 2,
      name: 'Non-VAT Registered Business',
      is_tax_number_required: false,
      place_of_supply_required: true,
      tax_number_label: '',
      other_info:
        'No VAT number. Supplier charges VAT if they are registered, typically 20% or reduced rate.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 3,
      name: 'Consumer (B2C)',
      is_tax_number_required: false,
      place_of_supply_required: true,
      tax_number_label: '',
      other_info: 'End consumer. Standard or reduced rates depending on items.',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 4,
      name: 'Outside UK (Export)',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      other_info: 'Exports generally zero-rated if proof of export is maintained.',
      is_export: true,
      tax_not_applicable: true,
    },
  ],
  // EU: [
  //   {
  //     id: 1,
  //     name: 'VAT-Registered (Intra-EU B2B)',
  //     is_tax_number_required: true,
  //     place_of_supply_required: true,
  //     tax_number_label: 'EU VAT Number',
  //     other_info:
  //       'Reverse charge applies if valid VAT number is provided for cross-border B2B in the EU.',
  //   },
  //   {
  //     id: 2,
  //     name: 'Local VAT-Registered (Same Country)',
  //     is_tax_number_required: true,
  //     place_of_supply_required: true,
  //     tax_number_label: 'VAT Number',
  //     other_info:
  //       'For domestic transactions within the same EU country. Standard or reduced VAT rate.',
  //   },
  //   {
  //     id: 3,
  //     name: 'Non-VAT Registered Consumer (B2C)',
  //     is_tax_number_required: false,
  //     place_of_supply_required: true,
  //     tax_number_label: '',
  //     other_info:
  //       'Private consumer in the EU. VAT typically charged at the seller’s or buyer’s location, depending on OSS rules.',
  //   },
  //   {
  //     id: 4,
  //     name: 'Outside EU (Export)',
  //     is_tax_number_required: false,
  //     place_of_supply_required: false,
  //     tax_number_label: '',
  //     other_info: 'Exports outside the EU generally zero-rated (no EU VAT).',
  //   },
  // ],
  OTHER: [
    {
      id: 1,
      name: 'Standard VAT/GST',
      is_tax_number_required: true,
      place_of_supply_required: true,
      tax_number_label: 'Tax ID',
      other_info: 'For countries with a standard consumption tax regime (VAT or GST).',
      is_export: false,
      tax_not_applicable: false,
    },
    {
      id: 2,
      name: 'Exempt/Non-Registered',
      is_tax_number_required: false,
      place_of_supply_required: false,
      tax_number_label: '',
      other_info:
        'Scenario where no local tax is due, or the business is below registration threshold.',
      is_export: true,
      tax_not_applicable: true,
    },
  ],
};
