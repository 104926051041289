// import { useQuery } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import { Popover } from '../../_components/Popover';
import { apiConstants } from '../../_constants';
import { chatService } from '../../_services/chat.service';
import useOutsideClickHandler from '../../Hook/useOutsideClickHandler';
import { fullNameUser } from '../../Utils';
import { LoadingIcon } from '../../Utils/SvgIcons';
// import { userService } from '../_services';

export const MessageViewedUserList = ({ handleClose = () => {}, messageListRef, id }) => {
  const { data: message, isLoading } = useQuery({
    queryKey: ['message-list'],
    queryFn: () => chatService.getChatMessages({ id, select: ['message_read_info'] }),
    select: (data) => data?.data[0],
  });
  const userList = message?.message_viewed_users;

  const { containerRef } = useOutsideClickHandler({
    onClose: () => handleClose(),
  });

  return (
    <Popover.PopoverItem
      scrollRef={messageListRef}
      positionLeft={(parentWidth, childWidth) => -childWidth - 20}
      positionTop={(height) => -height}
    >
      <div className='viewed-list viewed-list-overflow' ref={containerRef}>
        <div className='viewed-user-head'>Viewed User List</div>

        <div className='list-section pt-2'>
          {isLoading && (
            <div className='text-center'>
              <LoadingIcon size={20} />
            </div>
          )}
          {userList?.length === 0 && (
            <div className='text-center' style={{ color: 'var(--second-text-color)' }}>
              No Viewed Users
            </div>
          )}
          {userList?.length > 0 &&
            userList?.map((user) => {
              const isMale = user?.user_details?.gender === 1;
              const isNeutral = user?.user_details?.gender === 3;
              const url = user?.user_details?.compressed_image_url
                ? user?.user_details?.compressed_image_url
                : user?.user_details?.image_url;
              const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
              const fullName = fullNameUser(
                user?.user_details?.first_name,
                user?.user_details?.last_name,
              );
              return (
                <div className='d-flex align-items-center my-2' key={user?.user_details?.id}>
                  <span className='flex-grow-1'>
                    <div className='d-flex align-items-center'>
                      <CircularAvatar
                        size={20}
                        className='fz-none zoom-in employee-img-with-low-border'
                        source={finalUrl}
                        isMale={isMale}
                        isNeutral={isNeutral}
                      />
                      <span className='name-overflow'>{fullName}</span>
                    </div>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </Popover.PopoverItem>
  );
};
