import { Bell, Home } from 'lucide-react';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { ToggleButton } from '../_components';
import IconLabelSet from '../_components/IconLabelSet';
import { notificationService } from '../_services/notification.service';
import { useUser } from '../Hook/useUser';
import NotificationHead from '../Notification/NotificationHead';
import { MyTeamIcon } from '../Utils';

export default function NotificationLayout() {
  const navigate = useNavigate();
  const user = useUser();

  const [isUnRead, setIsUnRead] = useState(false);

  const { data: notificationCount } = useQuery({
    queryKey: ['notificationCount'],
    refetchInterval: 600000,
    queryFn: () => notificationService.getNotificationCount(),
    enabled: user?.id ? true : false,
  });

  console.log('notificationCount', notificationCount);
  // const count = {
  //   NotificationCount: 0,
  //   UpdateCount: 0,
  //   chatNotificationCount: 0,
  //   reminderUnreadCount: 0,
  // };

  return (
    <>
      <div className=' url-container cursor-pointer'>
        <div className='breadcrumbs d-flex' style={{ zIndex: 9 }}>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={16} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Notification'}
            color={'#2A2E34'}
            fontWeight={500}
            icon={<Bell size={16} />}
          />
        </div>
      </div>

      <NotificationHead id={'all'} counts={notificationCount?.data} />
      <div className='ntifn-read-unread-container   d-flex align-items-center justify-content-end gap-1 mb-2'>
        <div className='un-read-text'>Only show unread</div>
        <ToggleButton
          size={28}
          id={'is_unlisted'}
          isChecked={isUnRead}
          handleToggle={() => setIsUnRead((prev) => !prev)}
        />
      </div>
      <Outlet context={{ isUnRead, setIsUnRead }} />
    </>
  );
}
